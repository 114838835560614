import React from 'react'
import { useParams } from 'react-router'
import LeadDistTodayStatisticReportAgent from './LeadDistTodayStatisticReportAgent'
import LeadDistTodayStatisticReportCampaign from './LeadDistTodayStatisticReportCampaign'

const LeadDistTodayStatisticReport = (props) => {
    const params = useParams()
    const isAgentView = params.creFrom && params.creTo

    return <React.Fragment>
        {isAgentView && <LeadDistTodayStatisticReportAgent></LeadDistTodayStatisticReportAgent>}
        {!isAgentView && <LeadDistTodayStatisticReportCampaign></LeadDistTodayStatisticReportCampaign>}
    </React.Fragment>
}

export default LeadDistTodayStatisticReport