import React from 'react'
import { callPostReportPubPerformanceMarginReload } from '../../../services/reports/PubPerformanceReportService'

export default (props) => {
    const marginRules = props.marginRules

    const getMarginRuleArray = () => {
        const rules = []
        let _rule, _rules

        for (const idx in marginRules) {
            const marginRule = marginRules[idx]

            if (marginRule) {
                _rules = rules.filter(ele => ele.sourceId === marginRule.sourceId &&
                    ele.prodId === marginRule.prodId &&
                    ele.terms === marginRule.terms)

                if (_rules.length === 0) {
                    _rule = { ...marginRule, "rules": [] }
                    rules.push(_rule)
                } else {
                    _rule = _rules[0]
                }

                _rule.rules.push(marginRule)
            }
        }

        return rules
    }

    const btnMarginRuleDescClick = (e) => {
        const marginRuleDesc = document.getElementById("marginRuleDesc")
        if (marginRuleDesc.style.display === "none") {
            marginRuleDesc.style.display = "block"
        } else {
            marginRuleDesc.style.display = "none"
        }
        e.preventDefault();
    }

    const btnMarginRuleReloadClick = (e) => {
        if (window.confirm("Confirm to reload Google margin configuration?")) {
            Promise.all([callPostReportPubPerformanceMarginReload()]).then(resp => {
                window.location.reload()
            })
        }
    }

    return (<div className="row mt-3 text-right wallboard-desc">
        <div className="col-12">
            <p>
                <a id="btnMarginRuleDesc" href="#marginRuleDesc" className="small" onClick={btnMarginRuleDescClick}>Margin Rules</a>&nbsp;
                <a id="btnMarginRuleDesc" href="#marginRuleDesc" className="small" onClick={btnMarginRuleReloadClick}>[Reload]</a>
            </p>
            <div id="marginRuleDesc" className="mt-3 d" style={{ 'display': 'none' }}>
                {getMarginRuleArray().map(rules => {
                    const sourceId = rules.sourceId
                    const prodId = rules.prodId
                    const terms = rules.terms

                    return (<dir key={[sourceId, prodId, terms]} className="row" data-source-id={sourceId} data-prod-id={prodId} data-terms={terms}>
                        <div className="col-12">
                            <div className="d-inline small py-2 px-3">{rules.description}</div>
                            {rules.rules.map(rule => (<div key={[rule.affiliateId, rule.subid1, rule.min, rule.max]} className={'d-inline small py-2 px-3 ' + rule.rule}>Range {rule.min} - {rule.max}</div>))}
                        </div>
                    </dir>)
                })}
            </div>
        </div>
    </div>)
}
