import React from 'react'
import './TableComponent.css'

const TableComponent = (props) => {
    const { dataSourceTotal } = props
    const onSortHandler = (e, field) => {
        e.preventDefault()

        const isToggle = props.sort.field === field
        const direction = isToggle ? (props.sort.direction === "desc" ? "asc" : "desc") : "desc"
        const rslt = { "field": field, "direction": direction }

        props.onSortHandler(rslt)
    }

    const renderTh = (col, sort) => {
        return <th key={col.column} className={col.thClassName + (col.weight ? ' col-' + col.weight : '')}>
            {!col.sort && col.title}
            {col.sort && (<a href={"#Sort" + col.column} onClick={e => onSortHandler(e, col.column)}>
                {col.title}

                {sort && sort.field === col.column && (<React.Fragment>
                    {sort.direction === "desc" && <i className="fas fa-angle-down ml-2 mt-2"></i>}
                    {sort.direction === "asc" && <i className="fas fa-angle-up ml-2 mt-2"></i>}
                </React.Fragment>)}
            </a>)}
        </th>
    }

    return (<React.Fragment>
        <table {...props.tableProps} className={'table-compoment ' + props.className}>
            <colgroup>
                {props.dataset.columns.map(col => (<col key={col.column} width={col.width}></col>))}
            </colgroup>
            <thead className={props.theadClassName}>
                {props.datasetGrp && (<tr>
                    {props.datasetGrp.map(col => <th colSpan={col.colSpan} className={col.thClassName}>{col.title}</th>)}
                </tr>)}
                <tr>
                    {props.dataset.columns.map(col => renderTh(col, props.sort))}
                </tr>
            </thead>
            {props.dataSource !== undefined && <tbody className={props.tbodyClassName}>
                {props.dataSource.length === 0 && <tr><td colSpan={props.dataset.columns.length}>No Data</td></tr>}
                {props.dataSource.length > 0 && [...props.dataSource.filter(row => {
                    let rslt = props.isTrRender ? props.isTrRender(row) : true

                    return rslt
                }), ...(dataSourceTotal ? dataSourceTotal : [])].map((row, idx) => {
                    let trClassName = row.trClassName ? row.trClassName : ""

                    trClassName = props.getTrClassName ? props.getTrClassName(row) : trClassName

                    return (<React.Fragment key={idx}>
                        <tr className={trClassName} onClick={() => { if (props.onTrClick) props.onTrClick(row) }}>
                            {props.dataset.columns.map(col => {

                                return (<React.Fragment key={col.column}>
                                    {(!props.getTdContent &&
                                        row[col.column] &&
                                        row[col.column]["__html"] !== undefined) &&
                                        <td className={props.getTdClass ? props.getTdClass(col, row, idx) : col.tdClassName}
                                            colSpan={row.calSpan && row.calSpan[col.column] ? row.calSpan[col.column] : 1}
                                            dangerouslySetInnerHTML={row[col.column]}
                                            onClick={(e) => { if (props.onTdClick) props.onTdClick(col, row, e) }}>
                                        </td>}
                                    {!props.getTdContent &&
                                        row[col.column] &&
                                        row[col.column].content && <td className={props.getTdClass ? props.getTdClass(col, row, idx) : col.tdClassName}
                                            colSpan={row.calSpan && row.calSpan[col.column] ? row.calSpan[col.column] : 1}>
                                            {row[col.column].content}
                                        </td>}
                                    {props.getTdContent &&
                                        row[col.column] && <td className={col.tdClassName}
                                            colSpan={row.calSpan && row.calSpan[col.column] ? row.calSpan[col.column] : 1}>
                                            {props.getTdContent(row, col)}
                                        </td>}
                                </React.Fragment>)
                            })}
                        </tr>
                        {row.expand && <tr>
                            <td colSpan={props.dataset.columns.length}
                                className={props.getTdClass ? props.getTdClass(null, row, idx) : ""}>
                                {row.expandContent}
                            </td>
                        </tr>}
                    </React.Fragment>)
                })}
            </tbody>}

            {props.dataSources !== undefined && props.dataSources.map((dataSource, ibody) => (<tbody className={props.tbodyClassNames[ibody]} key={ibody}>
                {dataSource.length > 0 && [...dataSource.filter(row => {
                    let rslt = props.isTrRender ? props.isTrRender(row) : true

                    return rslt
                }), ...(dataSourceTotal ? dataSourceTotal : [])].map((row, idx) => {
                    let trClassName = row.trClassName ? row.trClassName : ""

                    trClassName = props.getTrClassName ? props.getTrClassName(row) : trClassName

                    return (<React.Fragment key={row.trKey ? row.trKey : idx}>
                        <tr className={trClassName + " " + (row.trClassName)} onClick={() => { if (props.onTrClick) props.onTrClick(row) }}>
                            {props.dataset.columns.map(col => {

                                return (<React.Fragment key={col.column}>
                                    {(!props.getTdContent &&
                                        row[col.column]["__html"] !== undefined) &&
                                        <td className={props.getTdClass ? props.getTdClass(col, row, idx) : col.tdClassName}
                                            dangerouslySetInnerHTML={row[col.column]}
                                            onClick={() => { if (props.onTdClick) props.onTdClick(col, row) }}>
                                        </td>}
                                    {!props.getTdContent && row[col.column].content && <td className={props.getTdClass ? props.getTdClass(col, row, idx) : col.tdClassName}>
                                        {row[col.column].content}
                                    </td>}
                                    {props.getTdContent && <td className={col.tdClassName}>
                                        {props.getTdContent(row, col)}
                                    </td>}
                                </React.Fragment>)
                            })}
                        </tr>
                        {row.expand && <tr>
                            <td colSpan={props.dataset.columns.length}
                                className={props.getTdClass ? props.getTdClass(null, row, idx) : ""}>
                                {row.expandContent}
                            </td>
                        </tr>}
                    </React.Fragment>)
                })}
            </tbody>))}
        </table>
    </React.Fragment>)
}

export default TableComponent