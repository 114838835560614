import React, { useEffect } from 'react'
import { resetWindow } from '../../../services/UtilService'
import DailyStatisticReport from './DailyStatisticReport'

const ByCreateDateStatisticReport = (props) => {

    useEffect(() => {
        resetWindow()
    }, [])

    return <DailyStatisticReport
        dateType="createdate">
    </DailyStatisticReport>
}

export default ByCreateDateStatisticReport