import Axios from "axios"
import { URL_POST_REPORT_AGENT_MTD, URL_POST_REPORT_AGENT_MTD_BY_CREATEDATE } from "../../config/DomainConfig"
import { getNumberFixed, getNumberWithCommas, getValWithPercentHtml } from "../UtilService"
import { getNorm, NORM_PROPERTIES } from "./PropertyConvertion"

const callAgentMtdReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_REPORT_AGENT_MTD(), payload)

    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data

        data.reportInsgAgentResponse.campaigns.forEach(_cp => {
            const tot = {
                totalMthLead: 0,
                mthNew: 0,
                mthApproved: 0,
                mthRejected: 0,
                mthTrash: 0,
                mthUrgent: 0,
                mthCallback: 0,
                mthUncall: 0,
                mthDelivered: 0,
                mthDelivAmount: 0,
                mthTotalSoAmount: 0
            }

            _cp.mtdList.forEach(_en => {
                const _totalMthLead = _en.totalMthLead
                const _mthNew = _en.mthNew
                const _mthApproved = _en.mthApproved
                const _mthRejected = _en.mthRejected
                const _mthTrash = _en.mthTrash
                const _mthUrgent = _en.mthUrgent
                const _mthCallback = _en.mthCallback
                const _mthUncall = _en.mthUncall
                const _mthDelivered = _en.mthDelivered
                const _mthDelivAmount = _en.mthDelivAmount
                const _mthTotalSoAmount = _en.mthTotalSoAmount
                const _aov = _mthTotalSoAmount / _mthApproved
                const _daov = _mthDelivAmount / _mthDelivered
                const _usr = _en.userName.split("@")
                const _teamName = _en.teamName ? _en.teamName : "No team"

                tot.totalMthLead += _totalMthLead ? _totalMthLead : 0
                tot.mthNew += _mthNew ? _mthNew : 0
                tot.mthApproved += _mthApproved ? _mthApproved : 0
                tot.mthRejected += _mthRejected ? _mthRejected : 0
                tot.mthTrash += _mthTrash ? _mthTrash : 0
                tot.mthUrgent += _mthUrgent ? _mthUrgent : 0
                tot.mthCallback += _mthCallback ? _mthCallback : 0
                tot.mthUncall += _mthUncall ? _mthUncall : 0
                tot.mthDelivered += _mthDelivered ? _mthDelivered : 0
                tot.mthDelivAmount += _mthDelivAmount ? _mthDelivAmount : 0
                tot.mthTotalSoAmount += _mthTotalSoAmount ? _mthTotalSoAmount : 0

                _en["_name"] = { "__html": _usr[0] }
                _en["_team"] = { "__html": _teamName }
                _en["_leads"] = { "__html": getNumberFixed(_totalMthLead, 0) + "" }
                _en["_sale"] = { "__html": getNumberWithCommas(_mthTotalSoAmount, 0) }
                _en["_new"] = { "__html": getValWithPercentHtml(_mthNew, _totalMthLead) }
                _en["_approved"] = { "__html": getValWithPercentHtml(_mthApproved, _totalMthLead) }
                _en["_ear"] = { "__html": getNumberFixed(_mthApproved * 100 / (_mthApproved + _mthRejected + _mthCallback), 0) + "%" }
                _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
                _en["_rejected"] = { "__html": getValWithPercentHtml(_mthRejected, _totalMthLead) }
                _en["_callback"] = { "__html": getValWithPercentHtml(_mthCallback, _totalMthLead) }
                _en["_uncall"] = { "__html": getValWithPercentHtml(_mthUncall, _totalMthLead) }
                _en["_trash"] = { "__html": getValWithPercentHtml(_mthTrash, _totalMthLead) }
                _en["_urgent"] = { "__html": getValWithPercentHtml(_mthUrgent, _totalMthLead) }
                _en["_deliver"] = { "__html": getValWithPercentHtml(_mthDelivered, _totalMthLead) }
                _en["_dsale"] = { "__html": getNumberFixed(_mthDelivAmount, 0) }
                _en["_dr"] = { "__html": getValWithPercentHtml(_mthDelivered, _mthApproved) }
                _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
            })

            _cp.mtdList.push({
                "_name": { "__html": "TOTAL" },
                "_team": { "__html": "" },
                "_leads": { "__html": getNumberFixed(tot.totalMthLead) + "" },
                "_sale": { "__html": getNumberWithCommas(tot.mthTotalSoAmount, 0) },
                "_new": { "__html": getValWithPercentHtml(tot.mthNew, tot.totalMthLead) },
                "_approved": { "__html": getValWithPercentHtml(tot.mthApproved, tot.totalMthLead) },
                "_ear": { "__html": getNumberFixed(tot.mthApproved * 100 / (tot.mthApproved + tot.mthRejected + tot.mthCallback), 0) + "%" },
                "_aov": { "__html": getNumberFixed(tot.mthTotalSoAmount / tot.mthApproved, 0) },
                "_rejected": { "__html": getValWithPercentHtml(tot.mthRejected, tot.totalMthLead) },
                "_callback": { "__html": getValWithPercentHtml(tot.mthCallback, tot.totalMthLead) },
                "_uncall": { "__html": getValWithPercentHtml(tot.mthUncall, tot.totalMthLead) },
                "_trash": { "__html": getValWithPercentHtml(tot.mthTrash, tot.totalMthLead) },
                "_urgent": { "__html": getValWithPercentHtml(tot.mthUrgent, tot.totalMthLead) },
                "_deliver": { "__html": getValWithPercentHtml(tot.mthDelivered, tot.totalMthLead) },
                "_dsale": { "__html": getNumberFixed(tot.mthDelivAmount, 0) },
                "_dr": { "__html": getValWithPercentHtml(tot.mthDelivered, tot.mthApproved) },
                "_daov": { "__html": getNumberFixed(tot.mthDelivAmount / tot.mthDelivered, 0) },
                "trClassName": "total",
            })
        })
    }

    return data
}

const callAgentMtdReportNorm = async (payload) => {
    const resp = await callAgentMtdReport(payload)
    let data = resp.reportInsgAgentResponse.campaigns.map(cp => {

        return cp.mtdList.filter(row => row.cpId)
    }).reduce((cp1, cp2) => cp1.concat(cp2), [])

    if (data && data.length > 0) {
        data = getNorm(data, NORM_PROPERTIES)
    }

    return { data: data, created: resp.created }
}

const callAgentMtdByCreatedateReport = async (payload) => {
    let data = {}
    const resp = await Axios.post(URL_POST_REPORT_AGENT_MTD_BY_CREATEDATE(), payload)
    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data

        data.reportInsgAgentResponse.campaigns.forEach(_cp => {
            const tot = {
                totalMthLead: 0,
                mthNew: 0,
                mthApproved: 0,
                mthRejected: 0,
                mthTrash: 0,
                mthUrgent: 0,
                mthCallback: 0,
                mthUncall: 0,
                mthDelivered: 0,
                mthDelivAmount: 0,
                mthTotalSoAmount: 0
            }

            _cp.mtdByCreatedateList.forEach(_en => {
                const _totalMthLead = _en.totalMthLead
                const _mthNew = _en.mthNew
                const _mthApproved = _en.mthApproved
                const _mthRejected = _en.mthRejected
                const _mthTrash = _en.mthTrash
                const _mthUrgent = _en.mthUrgent
                const _mthCallback = _en.mthCallback
                const _mthUncall = _en.mthUncall
                const _mthDelivered = _en.mthDelivered
                const _mthDelivAmount = _en.mthDelivAmount
                const _mthTotalSoAmount = _en.mthTotalSoAmount
                const _aov = _mthTotalSoAmount / _mthApproved
                const _daov = _mthDelivAmount / _mthDelivered
                const _usr = _en.userName.split("@")
                const _teamName = _en.teamName ? _en.teamName : "No team"

                tot.totalMthLead += _totalMthLead ? _totalMthLead : 0
                tot.mthNew += _mthNew ? _mthNew : 0
                tot.mthApproved += _mthApproved ? _mthApproved : 0
                tot.mthRejected += _mthRejected ? _mthRejected : 0
                tot.mthTrash += _mthTrash ? _mthTrash : 0
                tot.mthUrgent += _mthUrgent ? _mthUrgent : 0
                tot.mthCallback += _mthCallback ? _mthCallback : 0
                tot.mthUncall += _mthUncall ? _mthUncall : 0
                tot.mthDelivered += _mthDelivered ? _mthDelivered : 0
                tot.mthDelivAmount += _mthDelivAmount ? _mthDelivAmount : 0
                tot.mthTotalSoAmount += _mthTotalSoAmount ? _mthTotalSoAmount : 0

                _en["_name"] = { "__html": _usr[0] }
                _en["_team"] = { "__html": _teamName }
                _en["_leads"] = { "__html": getNumberWithCommas(_totalMthLead) + "" }
                _en["_sale"] = { "__html": getNumberWithCommas(_mthTotalSoAmount) }
                _en["_new"] = { "__html": getValWithPercentHtml(_mthNew, _totalMthLead) }
                _en["_approved"] = { "__html": getValWithPercentHtml(_mthApproved, _totalMthLead) }
                _en["_ear"] = { "__html": getNumberFixed(_mthApproved * 100 / (_mthApproved + _mthRejected + _mthCallback), 0) + "%" }
                _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
                _en["_rejected"] = { "__html": getValWithPercentHtml(_mthRejected, _totalMthLead) }
                _en["_callback"] = { "__html": getValWithPercentHtml(_mthCallback, _totalMthLead) }
                _en["_uncall"] = { "__html": getValWithPercentHtml(_mthUncall, _totalMthLead) }
                _en["_trash"] = { "__html": getValWithPercentHtml(_mthTrash, _totalMthLead) }
                _en["_urgent"] = { "__html": getValWithPercentHtml(_mthUrgent, _totalMthLead) }
                _en["_deliver"] = { "__html": getValWithPercentHtml(_mthDelivered, _totalMthLead) }
                _en["_dsale"] = { "__html": getNumberFixed(_mthDelivAmount, 0) }
                _en["_dr"] = { "__html": getValWithPercentHtml(_mthDelivered, _mthApproved) }
                _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
            })

            _cp.mtdByCreatedateList.push({
                "_name": { "__html": "TOTAL" },
                "_team": { "__html": "" },
                "_leads": { "__html": getNumberWithCommas(tot.totalMthLead) + "" },
                "_sale": { "__html": getNumberWithCommas(tot.mthTotalSoAmount) },
                "_new": { "__html": getValWithPercentHtml(tot.mthNew, tot.totalMthLead) },
                "_approved": { "__html": getValWithPercentHtml(tot.mthApproved, tot.totalMthLead) },
                "_ear": { "__html": getNumberFixed(tot.mthApproved * 100 / (tot.mthApproved + tot.mthRejected + tot.mthCallback), 0) + "%" },
                "_aov": { "__html": getNumberFixed(tot.mthTotalSoAmount / tot.mthApproved, 0) },
                "_rejected": { "__html": getValWithPercentHtml(tot.mthRejected, tot.totalMthLead) },
                "_callback": { "__html": getValWithPercentHtml(tot.mthCallback, tot.totalMthLead) },
                "_uncall": { "__html": getValWithPercentHtml(tot.mthUncall, tot.totalMthLead) },
                "_trash": { "__html": getValWithPercentHtml(tot.mthTrash, tot.totalMthLead) },
                "_urgent": { "__html": getValWithPercentHtml(tot.mthUrgent, tot.totalMthLead) },
                "_deliver": { "__html": getValWithPercentHtml(tot.mthDelivered, tot.totalMthLead) },
                "_dsale": { "__html": getNumberFixed(tot.mthDelivAmount, 0) },
                "_dr": { "__html": getValWithPercentHtml(tot.mthDelivered, tot.mthApproved) },
                "_daov": { "__html": getNumberFixed(tot.mthDelivAmount / tot.mthDelivered, 0) },
                "trClassName": "total",
            })
        })
    }

    return data
}

const buildAgentData = (rawCampaigns) => {
    const campaigns = rawCampaigns ? JSON.parse(JSON.stringify(rawCampaigns)) : []
    const teams = { "a": { "key": 1, "label": "Fresh Campaigns" }, "m": { "key": 2, "label": "Resell Campaign" } }
    return Object.keys(campaigns).map(idx => {
        let arrMtd = []

        if (campaigns[idx].mtdList) {
            arrMtd = campaigns[idx].mtdList
        } else if (campaigns[idx].mtdByCreatedateList) {
            arrMtd = campaigns[idx].mtdByCreatedateList
        }

        arrMtd = arrMtd.filter(mtd => mtd.id !== undefined)

        arrMtd.forEach(row => {
            // const prefix = row.userName ? row.userName.substr(0, 1).toLocaleLowerCase() : "a"
            const grp = !row.cpCat || row.cpCat === 1 ? "a" : "m"

            row.teamId = teams[grp].key
            row.teamName = teams[grp].label
        })

        return arrMtd
    }).reduce((a, b) => [...a, ...b], [])
}

const buildHierarchy = (entities) => {
    let data = {}
    let teamId, teamName, cpCat = 0, userId

    entities.forEach(row => {
        teamId = row.teamId ? row.teamId : 0
        teamName = row.teamName ? row.teamName : "No team"
        userId = row.userId

        if (!data[teamId]) {
            data[teamId] = {
                key: [teamId],
                name: teamName,
                agent: {}
            }
        }

        if (!data[teamId].agent[userId]) {
            data[teamId].agent[userId] = {
                key: [teamId, cpCat, userId],
                name: row.userName,
                campaign: []
            }
        }

        row.key = [teamId, cpCat, userId, row.cpId]
        data[teamId].agent[userId].campaign.push(row)
    })

    return data
}

const sumHierarchy = (data) => {
    const sumFields = ["totalMthLead", "mthNew", "mthApproved", "mthRejected"
        , "mthTrash", "mthUrgent", "mthDelivered"
        , "mthCallback", "mthUncall", "mthTotalSoAmount"
        , "mthDelivSaleOrder", "mthDelivAmount"]

    Object.keys(data).forEach(teamId => {
        sumFields.forEach(field => {
            data[teamId][field] = 0
        })

        Object.keys(data[teamId].agent).forEach(userId => {

            sumFields.forEach(field => {
                data[teamId].agent[userId][field] = 0
            })

            data[teamId].agent[userId].campaign.forEach(cp => {

                sumFields.forEach(field => {
                    data[teamId][field] += cp[field] ? cp[field] : 0
                    data[teamId].agent[userId][field] += cp[field] ? cp[field] : 0
                })
            })
        })
    })
}

const calcHierarchy = (data) => {
    Object.keys(data).forEach(teamId => {

        calcHierarchyEle(data[teamId])

        Object.keys(data[teamId].agent).forEach(userId => {

            calcHierarchyEle(data[teamId].agent[userId])

            data[teamId].agent[userId].campaign.forEach(cp => {
                calcHierarchyEle(cp)
            })
        })
    })
}

const calcHierarchyEle = (_en) => {
    const _totalMthLead = _en.totalMthLead
    const _mthNew = _en.mthNew
    const _mthApproved = _en.mthApproved
    const _mthRejected = _en.mthRejected
    const _mthTrash = _en.mthTrash
    const _mthUrgent = _en.mthUrgent
    const _mthDelivered = _en.mthDelivered
    const _mthCallback = _en.mthCallback
    const _mthUncall = _en.mthUncall
    const _mthTotalSoAmount = _en.mthTotalSoAmount
    const _mthDelivSaleOrder = _en.mthDelivSaleOrder
    const _mthDelivAmount = _en.mthDelivAmount
    const _usr = _en.cpName ? _en.cpName : (_en.name ? _en.name.split("@")[0] : "-")
    let _ear = _mthApproved * 100 / (_mthApproved + _mthRejected + _mthCallback)
    let _aov = _mthTotalSoAmount / _mthApproved
    let _daov = _mthDelivAmount / _mthDelivSaleOrder

    _ear = isNaN(_ear) ? 0 : _ear
    _aov = isNaN(_aov) ? 0 : _aov
    _daov = isNaN(_daov) ? 0 : _daov

    _en["_name"] = { "__html": _usr }
    _en["_leads"] = { "__html": getNumberWithCommas(_totalMthLead) + "" }
    _en["_sale"] = { "__html": getNumberWithCommas(_mthTotalSoAmount) }
    _en["_new"] = { "__html": getValWithPercentHtml(_mthNew, _totalMthLead) }
    _en["_approved"] = { "__html": getValWithPercentHtml(_mthApproved, _totalMthLead) }
    _en["_ear"] = { "__html": getNumberFixed(_ear, 0) + "%" }
    _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
    _en["_rejected"] = { "__html": getValWithPercentHtml(_mthRejected, _totalMthLead) }
    _en["_callback"] = { "__html": getValWithPercentHtml(_mthCallback, _totalMthLead) }
    _en["_uncall"] = { "__html": getValWithPercentHtml(_mthUncall, _totalMthLead) }
    _en["_trash"] = { "__html": getValWithPercentHtml(_mthTrash, _totalMthLead) }
    _en["_urgent"] = { "__html": getValWithPercentHtml(_mthUrgent, _totalMthLead) }
    _en["_deliver"] = { "__html": getValWithPercentHtml(_mthDelivered, _totalMthLead) }
    _en["_dsale"] = { "__html": getNumberFixed(_mthDelivAmount, 0) }
    _en["_dr"] = { "__html": getValWithPercentHtml(_mthDelivSaleOrder, _mthApproved) }
    _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
    _en["ear"] = _ear
    _en["aov"] = _aov
    _en["daov"] = _daov
}

const extractTeamAgent = (ds, t0, sort, sortIdx) => {
    return Object.keys(ds[t0].agent)
        .sort((ag1, ag2) => {
            const sortField = sortIdx[sort.field]
            const val1 = ds[t0].agent[ag1][sortField]
            const val2 = ds[t0].agent[ag2][sortField]
            let diff

            if (isNaN(val1) || isNaN(val2)) {
                diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
            } else {
                diff = val1 - val2
            }

            return sort.direction === "asc" ? diff : diff * -1
        }).map(ag0 => {
            const agent = ds[t0].agent[ag0]
            const campaigns = Object.keys(agent.campaign).sort((cp1, cp2) => {
                return agent.campaign[cp2].totalMthLead - agent.campaign[cp1].totalMthLead
            }).map(cp0 => {
                const campaign = agent.campaign[cp0]

                return campaign
            })

            return [agent, ...campaigns]
            // return agent
        }).reduce((prAg, nxAg) => prAg.concat(nxAg))
}

export {
    callAgentMtdReport,
    callAgentMtdReportNorm,
    callAgentMtdByCreatedateReport,
    buildAgentData, buildHierarchy, sumHierarchy, calcHierarchy, calcHierarchyEle, extractTeamAgent
}