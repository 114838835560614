//const DOMAIN_API = 'http://27.71.226.237:18082'
//const DOMAIN_API = 'http://localhost:18082'

// import { getStorage } from "../services/StorageService";
// import { STORAGE_DOMAIN_IDX } from "./StorageConfig";

//const DOMAIN_API = 'http://192.168.43.165:18082'
// const DOMAIN_API_LIST = [
//     process.env.REACT_APP_DOMAIN_API, // 0
//     process.env.REACT_APP_DOMAIN_API_ID, // 1
//     process.env.REACT_APP_DOMAIN_API_VN, // 2
//     process.env.REACT_APP_DOMAIN_API_MY, // 3
//     process.env.REACT_APP_DOMAIN_API_TH2, // 4
//     process.env.REACT_APP_DOMAIN_API_VN2, // 5
//     process.env.REACT_APP_DOMAIN_API_PH, // 6
//     process.env.REACT_APP_DOMAIN_API_VN3, // 7
//     process.env.REACT_APP_DOMAIN_API_ID2, // 8
//     process.env.REACT_APP_DOMAIN_API_VN4, // 9
//     process.env.REACT_APP_DOMAIN_API_VNID, // 10
// ]

const LOGIN_DOMAIN_MAPPING = {
    'kiwi-eco.com': 0,
    'neyu.co': 0,
    'enforte.asia': 0,
    'salespikes.com': 1,
    'globizsup.com': 1,
    'nutralife.asia': 2,
    'ekiwiid.com': 3,
    'gms-medika.com': 4,
    'ekiwi.com': 5,
    'ultrafresh.asia': 6,
    'healthpost.asia': 7,
    'lovehealth.asia': 8,
    'carelines.asia': 9,
    'ekwmy.com': 10,
    'ekwph.com': 11,
    'idcms.com': 12,
    'nutral-herbal.com': 12,
    'inpsw.com': 13
}
const DOMAIN_API = (path) => {
    // let idx = 0;
    // try {
    //     idx = getStorage(STORAGE_DOMAIN_IDX)
    // } catch (err) {
    //     console.log(err)
    // }

    return process.env.REACT_APP_DOMAIN_API + path
}

const URL_POST_AUTHENTICATE = () => { return DOMAIN_API('/authenticate') }
const URL_GET_CUSTOM_CONFIG = () => { return '/customConfig.json' }
const URL_POST_REPORT_CAMPAIGN_SUMMARY = () => { return DOMAIN_API('/api/report/campaign/summary') }
const URL_POST_REPORT_CAMPAIGN_SUMMARY_BY_DATE = () => { return DOMAIN_API('/api/report/campaign/summaryByDate') }
const URL_POST_REPORT_CAMPAIGN_OVERVIEW = () => { return DOMAIN_API('/api/report/campaign/overview') }
const URL_POST_REPORT_CAMPAIGN_TOP_AGENT = () => { return DOMAIN_API('/api/report/campaign/topAgent') }
const URL_GET_REPORT_CAMPAIGN_OVERVIEW_V2 = () => { return DOMAIN_API('/api/report/campaign/overview/v2?campaignCategory=') }
const URL_GET_REPORT_CAMPAIGN_OVERVIEW_V3 = () => { return DOMAIN_API('/api/report/campaign/overview/v3?campaignCategory=') }
const URL_POST_REPORT_PUB_PERFORMANCE = () => { return DOMAIN_API('/api/report/pub/performance') }
const URL_POST_REPORT_PUB_PERFORMANCE_MARGIN = () => { return DOMAIN_API('/api/report/pub/performance/margin') }
const URL_POST_REPORT_PUB_PERFORMANCE_MARGIN_RELOAD = () => { return DOMAIN_API('/api/report/pub/performance/margin/reload') }
const URL_POST_REPORT_PUB_PERFORMANCE_TOTAL = () => { return DOMAIN_API('/api/report/pub/performance/total') }
const URL_POST_REPORT_NETWORK_PERFORMANCE = () => { return DOMAIN_API('/api/report/network/performance') }
const URL_POST_REPORT_AGENT_TODAY = () => { return DOMAIN_API('/api/report/agentToday') }
const URL_POST_REPORT_AGENT_MTD = () => { return DOMAIN_API('/api/report/agentMtd') }
const URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE = () => { return DOMAIN_API('/api/report/agentToday/byCreatedate') }
const URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE_SHIFT = () => { return DOMAIN_API('/api/report/agentToday/byCreatedateShift') }
const URL_POST_REPORT_AGENT_MTD_BY_CREATEDATE = () => { return DOMAIN_API('/api/report/agentMtd/byCreatedate') }
const URL_GET_FULFILLMENT_STOCK = () => { return DOMAIN_API('/api/fulfillment/stock') }
const URL_POST_REPORT_TEAM_TODAY = () => { return DOMAIN_API('/api/report/teamToday') }
const URL_POST_REPORT_TEAM_TODAY_BY_CREATEDATE = () => { return DOMAIN_API('/api/report/teamToday/byCreatedate') }
const URL_POST_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE = () => { return DOMAIN_API('/api/report/teamProdToday/byCreatedate') }

const URL_GET_REPORT_RESCUE_FIND_DD_STATUS_SUMMARY = () => { return DOMAIN_API('/api/report/rescue/findDoStatusSummary') }
const URL_GET_REPORT_RESCUE_FIND_DD_STATUS_DETAIL = () => { return DOMAIN_API('/api/report/rescue/findDoStatusDetail') }
const URL_GET_REPORT_RESCUE_FIND_DD_POSTBACK = () => { return DOMAIN_API('/api/report/rescue/findDoPostback') }
const URL_GET_REPORT_RESCUE_FIND_RC_CALL = () => { return DOMAIN_API('/api/report/rescue/findRcCall') }

const URL_GET_DAILY_PUBS_PRIORITY = () => { return DOMAIN_API('/api/daily-priorities') }

const URL_GET_STATISTIC_REPORT_TEAM_TODAY_BY_CREATEDATE = () => { return DOMAIN_API('/api/statisticReport/teamToday/byCreatedate') }
const URL_GET_STATISTIC_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE = () => { return DOMAIN_API('/api/statisticReport/teamProdToday/byCreatedate') }
const URL_GET_STATISTIC_REPORT_PUB_PERFORMANCE = () => { return DOMAIN_API('/api/statisticReport/pub/performance') }
const URL_POST_STATISTIC_REPORT_AGENT_TODAY = () => { return DOMAIN_API('/api/statisticReport/agentToday') }
const URL_POST_STATISTIC_REPORT_AGENT_TODAY_SUM = () => { return DOMAIN_API('/api/statisticReport/agentSum') }
const URL_GET_STATISTIC_REPORT_BY_MODIFIEDDATE = () => { return DOMAIN_API('/api/statisticReport/byModifiedDate') }
const URL_GET_STATISTIC_REPORT_BY_CREATEDATE = () => { return DOMAIN_API('/api/statisticReport/byCreateDate') }
const URL_GET_STATISTIC_REPORT_NETWORK_PERFORMANCE = () => { return DOMAIN_API('/api/statisticReport/network/performance') }
const URL_GET_STATISTIC_REPORT_FIRST_ATTEMPT = () => { return DOMAIN_API('/api/statisticReport/firstAttempt') }
const URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V2 = () => { return DOMAIN_API('/api/report/campaign/statistic/overview/v2?campaignCategory=') }
const URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V3 = () => { return DOMAIN_API('/api/report/campaign/statistic/overview/v3?campaignCategory=') }

const URL_GET_SYNONYM_FIND = () => { return DOMAIN_API('/api/synonym/find') }

const URL_GET_AFFILIATE = () => { return DOMAIN_API('/datasource/affiliate.json') }
const URL_POST_AFFILIATE_UPLOAD = () => { return DOMAIN_API('/api/affiliate/upload') }

const URL_HS_POST_REPORT_CAMPAIGN_OVERVIEW = () => { return DOMAIN_API('/api/hs/report/campaign/overview') }
const URL_HS_POST_REPORT_CAMPAIGN_BATCH_OVERVIEW = () => { return DOMAIN_API('/api/hs/report/campaignBatch/overview') }
const URL_HS_POST_REPORT_DEAL_HISTORY = () => { return DOMAIN_API('/api/hs/report/dealHistory') }


const URL_POST_CMDC_CAMPAIGN_OVERVIEW = () => { return DOMAIN_API('/api/cmdc/campaign/overview') }
const URL_POST_CMDC_AGENT_SKILL_OVERVIEW = () => { return DOMAIN_API('/api/cmdc/agentSkill/overview') }
const URL_POST_CMDC_AGENT_STATUS = () => { return DOMAIN_API('/api/cmdc/agentStatus') }
const URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_ATTEMPT_AVG = () => { return DOMAIN_API('/api/cmdc/statistic/callAnalysis/attemptAvg') }
const URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_ATTEMPT = () => { return DOMAIN_API('/api/cmdc/statistic/callAnalysis/attempt') }
const URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_UNCALL = () => { return DOMAIN_API('/api/cmdc/statistic/callAnalysis/uncall') }

const URL_GET_STATISTIC_CALL_DO_STATUS_MAPPING = () => { return DOMAIN_API('/api/statisticReport/doStatusMapping/xlsx') }
const URL_GET_STATISTIC_CALL_LOD_DO = () => { return DOMAIN_API('/api/statisticReport/logDO/doID/xlsx') }

export {
    LOGIN_DOMAIN_MAPPING,
    DOMAIN_API,
    URL_POST_AUTHENTICATE,
    URL_GET_CUSTOM_CONFIG,
    URL_POST_REPORT_CAMPAIGN_SUMMARY,
    URL_POST_REPORT_CAMPAIGN_SUMMARY_BY_DATE,
    URL_POST_REPORT_CAMPAIGN_OVERVIEW,
    URL_POST_REPORT_CAMPAIGN_TOP_AGENT,
    URL_GET_REPORT_CAMPAIGN_OVERVIEW_V2,
    URL_GET_REPORT_CAMPAIGN_OVERVIEW_V3,
    URL_POST_REPORT_PUB_PERFORMANCE,
    URL_POST_REPORT_PUB_PERFORMANCE_MARGIN,
    URL_POST_REPORT_PUB_PERFORMANCE_MARGIN_RELOAD,
    URL_POST_REPORT_PUB_PERFORMANCE_TOTAL,
    URL_POST_REPORT_NETWORK_PERFORMANCE,
    URL_POST_REPORT_AGENT_TODAY,
    URL_POST_REPORT_AGENT_MTD,
    URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE,
    URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE_SHIFT,
    URL_POST_REPORT_AGENT_MTD_BY_CREATEDATE,
    URL_GET_FULFILLMENT_STOCK,
    URL_POST_REPORT_TEAM_TODAY,
    URL_POST_REPORT_TEAM_TODAY_BY_CREATEDATE,
    URL_POST_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE,

    URL_GET_REPORT_RESCUE_FIND_DD_STATUS_SUMMARY,
    URL_GET_REPORT_RESCUE_FIND_DD_STATUS_DETAIL,
    URL_GET_REPORT_RESCUE_FIND_DD_POSTBACK,
    URL_GET_REPORT_RESCUE_FIND_RC_CALL,
    URL_GET_DAILY_PUBS_PRIORITY,

    URL_GET_STATISTIC_REPORT_TEAM_TODAY_BY_CREATEDATE,
    URL_GET_STATISTIC_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE,
    URL_GET_STATISTIC_REPORT_PUB_PERFORMANCE,
    URL_POST_STATISTIC_REPORT_AGENT_TODAY,
    URL_POST_STATISTIC_REPORT_AGENT_TODAY_SUM,
    URL_GET_STATISTIC_REPORT_BY_MODIFIEDDATE,
    URL_GET_STATISTIC_REPORT_BY_CREATEDATE,
    URL_GET_STATISTIC_REPORT_NETWORK_PERFORMANCE,
    URL_GET_STATISTIC_REPORT_FIRST_ATTEMPT,
    URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V2,
    URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V3,
    URL_GET_AFFILIATE,
    URL_GET_SYNONYM_FIND,
    URL_POST_AFFILIATE_UPLOAD,

    URL_HS_POST_REPORT_CAMPAIGN_OVERVIEW,
    URL_HS_POST_REPORT_CAMPAIGN_BATCH_OVERVIEW,
    URL_HS_POST_REPORT_DEAL_HISTORY,

    URL_POST_CMDC_CAMPAIGN_OVERVIEW,
    URL_POST_CMDC_AGENT_SKILL_OVERVIEW,
    URL_POST_CMDC_AGENT_STATUS,
    URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_ATTEMPT_AVG,
    URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_ATTEMPT,
    URL_POST_CMDC_STATISTIC_CALL_ANALYSIS_UNCALL,
    URL_GET_STATISTIC_CALL_DO_STATUS_MAPPING,
    URL_GET_STATISTIC_CALL_LOD_DO
}