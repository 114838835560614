import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { STORAGE_THRESHOLD_LAST_FRESH } from '../../config/StorageConfig'
import { getJwtToken } from '../../services/AuthenticateService'
import { callPostReportPubPerformanceTotal } from '../../services/reports/PubPerformanceReportService'
import { getStorage } from '../../services/StorageService'
import { getNumberFixed, getNumberWithCommas } from '../../services/UtilService'
import './TopTicker.css'

export default (props) => {
    const DELAY_TOTAL = 1000 * 60
    const [ds, setds] = useState({})
    const [attributes] = useState([
    { 'field': 'approved', 'text': 'AR' },
    { 'field': 'validated', 'text': 'VAL' },
    { 'field': 'rejected', 'text': 'RJ' },
    { 'field': 'trash', 'text': 'TRASH' },
    { 'field': 'lastImpFresh', 'text': 'LAST FRESH' },
    { 'field': 'lastImpLeadFromMkt', 'text': 'LAST FRESH FROM MARKETING' }
    ])

    const getValue = (data, field) => {
        if (field === 'leads') {
            return <span className="white">{data ? getNumberWithCommas(data[field]) : 0}</span>
        } else if (field === 'lastImpFresh' || field === 'lastImpLeadFromMkt') {
            const min = data ? parseInt(data[field] / (1000 * 60)) : 0
            const thresholdLastFresh = parseInt(getStorage(STORAGE_THRESHOLD_LAST_FRESH))
            let impTime = min
            let impUnit = 'min'

            if (min > 59) {
                impTime = parseInt(min / 60)
                impUnit = 'hr'
            }

            if (min > thresholdLastFresh) {
                return <React.Fragment>
                    <span className="dangerous">{impTime} {impUnit}</span>
                </React.Fragment>
            } else {
                return <React.Fragment>
                    <span className="white">{getNumberWithCommas(impTime)}</span>
                    <span className="green">{impUnit}</span>
                </React.Fragment>
            }
        } else {
            return <React.Fragment>
                <span className="white">{data ? getNumberWithCommas(data[field]) : 0}</span>
                <span className="green"> ({data ? getNumberFixed(data[field] * 100 / data.leads, 0) : 0}%)</span>
            </React.Fragment>
        }
    }

    const loadTotal = () => {
        const jwt = getJwtToken()
        const topTicker = document.getElementById("topTicker")
        const isLoaded = topTicker.className.indexOf("loaded") !== -1

        if (jwt) {
            const calls = [callPostReportPubPerformanceTotal()]

            if (!isLoaded) {
                topTicker.className += ' loaded'
            }
            Promise.all(calls).then(resp => {
                if (resp[0]) {
                    setds(resp[0].tickerResponse)
                }
            })
        }
    }

    useEffect(() => {
        const timerAjax = setInterval(loadTotal, DELAY_TOTAL)
        loadTotal()

        return () => clearInterval(timerAjax)
    }, [DELAY_TOTAL, setds])

    return (<div id="topTicker" className="top-ticker">
        <div className="top-ticker content">
            {attributes.map(attr => (<div key={attr.field} className="box white">
                <span className="title yellow">{attr.text}: </span>
                <span className="value">{getValue(ds, attr.field)}</span>
            </div>))}
        </div>
    </div>)
}
