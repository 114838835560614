import React, { useState } from 'react'
import { useEffect } from 'react'
import { getDateFormat, getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import { NORM_PROPERTIES, getNorm } from '../../../services/reports/PropertyConvertion'
import { callTeamProdTodayByCreatedateStatisticReport } from '../../../services/statisticReports/TeamTodayStatisticReportService'
import RelPerformanceComponent from '../../Report/RelPerformanceReport/RelPerformanceComponent'
import './RelProdPerformanceStatisticReport.css'
import TeamReportComponent from '../../Report/RelPerformanceReport/TeamReportComponent'
import ReactDatePicker from 'react-datepicker'

const calcNode = (teamId, cpId, raw) => {
    const rslt = {}
    let ear
    const myFields = raw && raw.length > 0 ? Object.keys(raw[0]) : []
    const sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld)).map(fld => NORM_PROPERTIES[fld])

    sumFields.forEach(field => {
        rslt[field] = 0

        raw.forEach(row => {
            if (
                (teamId === row.teamId && cpId === row.cpId) ||
                (teamId === row.teamId && cpId === undefined) ||
                (teamId === undefined && cpId === row.cpId)
            ) {
                rslt[field] += row[field]
            }
        })
    })

    ear = rslt.approved * 100 / (rslt.approved + rslt.rejected + rslt.callback)

    rslt.ear = isNaN(ear) ? 0 : ear

    return rslt
}

const RelProdPerformanceStatisticReport = (params) => {
    const [query, setquery] = useState({ valid: false, dateFrom: undefined, dateTo: undefined })
    const [ds, setds] = useState({})
    const [zoom, setZoom] = useState()//TODO
    const maxDate = new Date()
    const minDate = new Date()

    minDate.setDate(minDate.getDate() - 31)

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    const onSearch = () => {
        const dateFrom = getDateFormat(query.dateFrom)
        const dateTo = getDateFormat(query.dateTo)

        Promise.all([callTeamProdTodayByCreatedateStatisticReport(dateFrom, dateTo)]).then(httpResp => {
            const resp = [httpResp[0]]
            let arr = getNorm(resp[0].data, NORM_PROPERTIES)
            let rslt

            arr = arr.filter(row => !row.cpCat || row.cpCat === 1)

            rslt = {
                rows: arr,
                teams: arr.map(ele => { return { teamId: ele.teamId, teamName: ele.teamName } })
                    .reduce((ob1, ob2) => {
                        return ob1.map(ob => ob.teamId).indexOf(ob2.teamId) === -1 ? [...ob1, ob2] : [...ob1]
                    }, []),
                campaigns: arr.map(ele => { return { cpId: ele.cpId, cpName: ele.cpName } })
                    .reduce((ob1, ob2) => {
                        return ob1.map(ob => ob.cpId).indexOf(ob2.cpId) === -1 ? [...ob1, ob2] : [...ob1]
                    }, []),
                total: {
                    teams: {},
                    campaigns: {}
                }
            }

            rslt.teams.forEach(tm => {
                rslt.total.teams[tm.teamId] = calcNode(tm.teamId, undefined, arr)
            })
            rslt.campaigns.forEach(cp => {
                rslt.total.campaigns[cp.cpId] = calcNode(undefined, cp.cpId, arr)
            })

            rslt.teams.forEach(tm => {
                rslt.total[tm.teamId] = {}
                rslt.campaigns.forEach(cp => {
                    rslt.total[tm.teamId][cp.cpId] = calcNode(tm.teamId, cp.cpId, arr)
                })
            })

            rslt.teams.sort((tm1, tm2) => rslt.total.teams[tm2.teamId].totalSoAmount - rslt.total.teams[tm1.teamId].totalSoAmount)
            rslt.campaigns.sort((cp1, cp2) => rslt.total.campaigns[cp2.cpId].totalSoAmount - rslt.total.campaigns[cp1.cpId].totalSoAmount)

            setds({ ...rslt })
            document.getElementById("redisDateChart").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
        })
    }

    useEffect(() => {
        resetWindow()
    }, [])

    return (<React.Fragment>

        <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-6">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
        </div>
        {query.valid && <div className="form-row justify-content-end mb-3">
            <button className="btn btn-success" onClick={e => onSearch()}>Search</button>
        </div>}

        {ds.teams && <React.Fragment>
            {!zoom && <RelPerformanceComponent ds={ds}
                onZoomClick={setZoom}>
            </RelPerformanceComponent>}

            {zoom && <TeamReportComponent
                hierarchy={zoom.hierarchy}
                dsOrigin={
                    ds.rows.filter(row => {
                        let isValid = zoom.query.teamId === undefined || zoom.query.teamId === row.teamId

                        if (isValid) {
                            isValid = zoom.query.cpId === undefined || zoom.query.cpId === row.cpId
                        }

                        return isValid
                    })
                }
                onClose={() => setZoom(undefined)}>
            </TeamReportComponent>}
        </React.Fragment>}

        <div className="row"><div id="redisDateChart" className="col-12 small text-right"></div></div>
    </React.Fragment>)
}

export default RelProdPerformanceStatisticReport