import React, { useEffect, useState } from 'react'
import { LOGIN_DOMAIN_MAPPING } from '../../config/DomainConfig'
import { ORG_ALL } from '../../config/CommonConfig'
import { STORAGE_DOMAIN_IDX, STORAGE_MENU_ORG } from '../../config/StorageConfig'
import { callPostAuthenticate, callGetCustomConfig, setJwtToken, resetJwtToken } from '../../services/AuthenticateService'
import { setStorage } from '../../services/StorageService'

export default (props) => {
    const iniPaylaod = { "username": "", "password": "" }
    const [payload, setpayload] = useState(iniPaylaod)
    const [errMessage, setErrMessage] = useState("")

    const onChange = e => {
        const id = e.target.id
        const value = e.target.value

        payload[id] = value
        setpayload({ ...payload })
    }
    const getDomainIdx = (email) => {
        const mailToken = email ? email.split('@') : []
        const mailDomain = mailToken.length === 2 ? mailToken[1].toLocaleLowerCase() : ''

        return LOGIN_DOMAIN_MAPPING[mailDomain] !== undefined ? LOGIN_DOMAIN_MAPPING[mailDomain] : -1
    }
    const onSubmit = e => {
        e.preventDefault()

        const domainIdx = getDomainIdx(payload.username)
        let mnuOrg

        if (domainIdx > -1) {
            setStorage(STORAGE_DOMAIN_IDX, domainIdx)

            mnuOrg = {}
            mnuOrg[ORG_ALL[domainIdx]] = true
            setStorage(STORAGE_MENU_ORG, { ...mnuOrg })

            Promise.all([callPostAuthenticate(payload), callGetCustomConfig()]).then(resp => {
                const body = document.getElementsByTagName('body')[0]
                const className = body.className.replaceAll(' bg-primary', '')
                const permissions = resp[0].permissions

                body.className = className
                setJwtToken(resp[0].token, resp[0].username, permissions, resp[1], resp[0]?.role)

                window.location = '/'
            }).catch(err => {
                setErrMessage("Login failed.")
            })
        } else {
            setErrMessage("Invalid email domain.")
            console.log(payload.username, LOGIN_DOMAIN_MAPPING)
        }
    }

    useEffect(() => {
        document.getElementsByTagName('body')[0].className += ' bg-primary'
        // localStorage.clear() //disable this to save some nescessary stage.
        resetJwtToken()
    }, [])

    useEffect(() => {
    }, [errMessage])

    return (<React.Fragment>
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                    <div className="card-body">
                                        <form onSubmit={onSubmit}>
                                            <div className="form-group">
                                                <label className="small mb-1" htmlFor="username">Username</label>
                                                <input className="form-control py-4" id="username" type="text" placeholder="Username" value={payload.username} onChange={onChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="small mb-1" htmlFor="password">Password</label>
                                                <input className="form-control py-4" id="password" type="password" placeholder="Enter password" value={payload.password} onChange={onChange} />
                                            </div>
                                            {/* <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input className="custom-control-input" id="rememberPasswordCheck" type="checkbox" />
                                                    <label className="custom-control-label" htmlFor="rememberPasswordCheck">Remember password</label>
                                                </div>
                                            </div> */}
                                            {errMessage && <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {errMessage}
                                                </div>
                                            </div>}
                                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                                <a className="small" href="#forgotten">&nbsp;</a>
                                                <button className="btn btn-primary">Login</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-center">
                                        <div className="small"><a href="#signup">Need an account? Sign up!</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div id="layoutAuthentication_footer">
                <footer className="py-4 bg-light mt-auto">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between small">
                            <div className="text-muted">Copyright &copy; Your Website 2020</div>
                            <div>
                                <a href="#policy">Privacy Policy</a>
                                &middot;
                                <a href="#term-condition">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </React.Fragment>)
}
