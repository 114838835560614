const DS_AGENT_TODAY_BY_CREATEDATE = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_team", "title": "Team", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sl", "title": "SL", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_fcr", "title": "FCR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_uncallAvgAtt", "title": "UC_Attm", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_uncallAvgRing", "title": "UC_Ring", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_avgtalktime", "title": "ATT", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_totalcalls", "title": "Calls", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_odcb", "title": "ODCB", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_deliver", "title": "Deliver", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_dsale", "title": "D-Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_dr", "title": "DR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_daov", "title": "D-AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true }
        // { "column": "_leadCreatedDate", "title": "Lastest Lead", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}
const DS_AGENT_TODAY_BY_CREATEDATE_AGENTALL = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_team", "title": "Team", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_deliver", "title": "Deliver", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dsale", "title": "D-Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dr", "title": "DR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_daov", "title": "D-AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncallAvgAtt", "title": "UC_Attm", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncallAvgRing", "title": "UC_Ring", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_avgtalktime", "title": "ATT", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_totalcalls", "title": "Calls", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_odcb", "title": "ODCB", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
  
        // { "column": "_leadCreatedDate", "title": "Lastest Lead", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}
const DS_AGENT_TODAY_BY_CREATEDATE_STATISTIC = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_team", "title": "Team", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_deliver", "title": "Deliver", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dsale", "title": "D-Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dr", "title": "DR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_daov", "title": "D-AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncallAvgAtt", "title": "UC_Attm", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncallAvgRing", "title": "UC_Ring", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_avgtalktime", "title": "ATT", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_totalcalls", "title": "Calls", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_odcb", "title": "ODCB", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_leadCreatedDate", "title": "Lastest Lead", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}
const DS_AGENT_TODAY_BY_CREATEDATE_SHIFT = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "15%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_team", "title": "Team", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_shift", "title": "Shift", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false },
        { "column": "_sale", "title": "Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_deliver", "title": "Deliver", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_dsale", "title": "D-Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_dr", "title": "DR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        // { "column": "_daov", "title": "D-AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leadCreatedDate", "title": "Lastest Lead", "width": "15%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}

const DS_AGENT_TODAY_BY_CREATEDATE_AGENT = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "15%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_deliver", "title": "Deliver", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dsale", "title": "D-Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dr", "title": "DR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_daov", "title": "D-AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}

export {
    DS_AGENT_TODAY_BY_CREATEDATE,
    DS_AGENT_TODAY_BY_CREATEDATE_SHIFT,
    DS_AGENT_TODAY_BY_CREATEDATE_AGENT,
    DS_AGENT_TODAY_BY_CREATEDATE_STATISTIC,
    DS_AGENT_TODAY_BY_CREATEDATE_AGENTALL
}