import React, { useEffect } from 'react'
import { callTeamTodayReport, extractTeamAgent, calcHierarchyEle, buildHierarchy, sumHierarchy, calcHierarchy } from '../../../services/reports/TeamTodayReportService'
import { resetWindow } from '../../../services/UtilService'
import { DS_TEAM_TODAY } from './TeamTodayConfig'


import './TeamTodayReport.css'
import TeamTodayReportComponent from './TeamTodayReportComponent'

export default (props) => {
    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>
        <TeamTodayReportComponent
            DS_TEAM={DS_TEAM_TODAY}
            buildHierarchy={buildHierarchy}
            sumHierarchy={sumHierarchy}
            calcHierarchy={calcHierarchy}
            extractTeam={extractTeamAgent}
            calcHierarchyEle={calcHierarchyEle}
            callTeamReport={callTeamTodayReport}>
        </TeamTodayReportComponent>
    </React.Fragment>
}
