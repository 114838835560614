import { URL_HS_POST_REPORT_DEAL_HISTORY } from "../../config/DomainConfig"
// import { getNumberFixed, getNumberWithCommas, getSpan } from "../UtilService"

const { default: Axios } = require("axios")

const callPostHSReportDealHistory = async (dateFrom, dateTo) => {
    const httpResp = await Axios.get(URL_HS_POST_REPORT_DEAL_HISTORY() + "/" + dateFrom + "/" + dateTo)
    const resp = httpResp.data
    const dates = Object.keys(resp)

    if (dates.length > 0) {
        
        const stages = Object.keys(resp).map(dt => {
            return Object.keys(resp[dt]).map(st => resp[dt][st]).reduce((acc, cur) => {
                return acc.concat(cur);
            }, [])
        }).reduce((acc, cur) => {
            return acc.concat(cur);
        }, []).reduce((acc, cur) => {

            for (let i = 0; i < acc.length; i++) {
                if (acc[i].pipelineId === cur.pipelineId &&
                    acc[i].stageId === cur.stageId) {
                    return acc
                }
            }

            acc.push(cur)
            return acc
        }, []).sort((a, b) => {
            if (a.displayOrder === b.displayOrder) {
                return a.stageDisplayOrder - b.stageDisplayOrder;
            } else {
                return a.displayOrder - b.displayOrder;
            }
        })

        const data = dates.map(date => {
            let row = {
                "_name": { "__html": date }
            }

            stages.map(st => {
                const totalIn = resp[date] && resp[date][st.stageId] ? resp[date][st.stageId].totalIn : "0"
                return row["_" + st.stageId] = { "__html": totalIn }
            })

            return row
        })

        return {
            "columns": {
                "columns": [{ "column": "_name", "title": "Date", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center" }].concat(
                    stages.map(st => { return { "column": "_" + st.stageId, "title": st.pipelineId + "/" + st.stageName, "weight": 1, "thClassName": "text-center", "tdClassName": "text-center" } })
                )
            },
            data: data
        }
    } else {
        return {
            "columns": { "columns": [] },
            data: []
        }
    }

}

export {
    callPostHSReportDealHistory
}