const DS_AGENT_MTD_BY_CREATEDATE = {
    "columns": [
        { "column": "_name", "title": "Name", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_deliver", "title": "Deliver", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dsale", "title": "D-Sale", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_dr", "title": "DR", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_daov", "title": "D-AOV", "width": "6%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true }
    ]
}

export { DS_AGENT_MTD_BY_CREATEDATE }