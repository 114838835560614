import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { DS_DAILY_STATISTIC } from './DailyStatisticReportConfig'
import { getDateFormat, getNumberFixed, getNumberWithCommas, getValWithPercentHtml, resetWindow } from '../../../services/UtilService'
import { callDailyStatisticReport } from '../../../services/statisticReports/DailyStatisticReportService'

const getTotalDs = (ds) => {
    const copy = JSON.parse(JSON.stringify(ds))
    const rslt = copy.map((cp, idx) => {
        let tot = cp.list.filter(row => row["trClassName"] === "total")[0]

        if (tot) {
            tot["trClassName"] = ""
            tot["_name"]["__html"] = (idx + 1) + ". " + cp.name
        }

        return tot
    })
    const tot = rslt.reduce((acc, cur) => {
        Object.keys(cur).forEach(fld => {
            if (fld.indexOf("_") !== 0) {
                if (["trClassName"].indexOf(fld) === -1) {
                    acc[fld] = (isNaN(acc[fld]) ? 0 : acc[fld]) +
                        (isNaN(cur[fld]) ? 0 : cur[fld])
                }
            }
        })

        return acc
    }, {})
    const trTot = {
        "_name": { "__html": "TOTAL" },
        "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) + "" },
        "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
        "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
        "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
        "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
        "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
        "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
        "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
        "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
        "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
        "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
        "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
        "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
        "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
        "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
        "trClassName": "total"
    }

    rslt.push(trTot)

    return rslt
}

const btnCls = {
    btnFresh: {
        fresh: "btn btn-primary mr-1",
        resell: "btn btn-secondary mr-1"
    },
    btnResell: {
        fresh: "btn btn-secondary mr-5",
        resell: "btn btn-primary mr-5"
    }
}

const cpCatVal = {
    fresh: 1,
    resell: 2
}

const DailyStatisticReport = (props) => {
    const { dateType } = props
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({
        valid: false,
        cpCat: "fresh",
        dateFrom: undefined,
        dateTo: undefined
    })

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        query.valid = false;

        query[name] = value
        if(query.dateFrom && query.dateTo) {
            query.valid = query.dateFrom.getTime() <= query.dateTo.getTime()
        }
        setquery({ ...query })
    }

    const onSearch = (e) => {
        const btnId = e.target.id
        let dateFrom
        let dateTo

        if (btnId === "btnSearch") {
            dateFrom = getDateFormat(query.dateFrom)
            dateTo = getDateFormat(query.dateTo)
            Promise.all([callDailyStatisticReport(dateType, dateFrom, dateTo)]).then(resp => {
                const reportInsgDailyEntities = resp[0]

                setDs(reportInsgDailyEntities ? reportInsgDailyEntities.campaigns : [])
            })
        } else {
            query.cpCat = btnId.replaceAll("btn", "").toLowerCase()
            setquery({ ...query })
        }
    }

    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {
        a = a.summary
        b = b.summary
        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }

        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    const sortDate = (a, b) => {
        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }

        let val1 = a["_name"]["__html"] ? a["_name"]["__html"] : a["_name"],
            val2 = b["_name"]["__html"] ? b["_name"]["__html"] : b["_name"]

        val1 = val1 ? val1.split("-").reduce((acc, cur) => { return cur + acc}) : ""
        val2 = val2 ? val2.split("-").reduce((acc, cur) => { return cur + acc}) : ""

        let diff = val1.localeCompare(val2)

        return diff
    }

    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>
        <div className='panelDefault'>
            <div className='panelHeader'>{ dateType === 'createdate' ? 'The leads in this report will be counted by created date' : 'The leads in this report will be counted by modified date'}</div>
            <div style={{ padding: '15px'}}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="fltDateFrom" className='ml-2'>{ dateType === 'createdate' ? 'Created Date From' : 'Modified Date From'}</label>
                        <div className="form-control border-0">
                            <ReactDatePicker id="fltDateFrom"
                                name="dateFrom"
                                selected={query.dateFrom}
                                maxDate={query.dateTo ? query.dateTo : new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                                className="form-control"></ReactDatePicker>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="fltDateTo" className='ml-2'>{ dateType === 'createdate' ? 'Created Date To' : 'Modified Date To'}</label>
                        <div className="form-control border-0">
                            <ReactDatePicker id="fltDateTo"
                                name="dateTo"
                                selected={query.dateTo}
                                maxDate={new Date()}
                                minDate={query.dateFrom ? query.dateFrom : null}
                                dateFormat="dd/MM/yyyy"
                                onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                                className="form-control"></ReactDatePicker>
                        </div>
                    </div>
                </div>
                {query.valid && <div className="form-row justify-content-end mb-2">
                    <button id="btnFresh" className={btnCls.btnFresh[query.cpCat]} onClick={e => onSearch(e)}>Fresh</button>
                    <button id="btnResell" className={btnCls.btnResell[query.cpCat]} onClick={e => onSearch(e)}>Resell</button>
                    <button id="btnSearch" className="btn btn-success" onClick={e => onSearch(e)}>Search</button>
                </div>}
            </div>
        </div>

        {ds && ds.length > 0 && <div className="mb-3">
            <TableComponent
                tableProps={{ id: "tableSummary" }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_DAILY_STATISTIC}
                dataSource={getTotalDs(ds.filter(cp => cp.cpCat === cpCatVal[query.cpCat]).sort(sortList))}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>}

        {ds && ds.length > 0 && ds.filter(cp => cp.cpCat === cpCatVal[query.cpCat]).sort(sortList).map((cp, idx) => (<div key={cp.id}>
            <h4 className="mt-2">
                {idx + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <TableComponent
                tableProps={{ id: "tableCp" + cp.id }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_DAILY_STATISTIC}
                dataSource={cp.list.sort(sortDate)}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>))}

    </React.Fragment>
}

export default DailyStatisticReport