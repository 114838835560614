import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import TableComponent from '../../../component/TableComponent/TableComponent';
import {
    ORG_INDONESIA,
    ORG_THAILAND,
    ORG_THAILAND2,
    ORG_VIETNAM,
    ORG_VIETNAM2,
    ORG_VIETNAM3,
    ORG_MALAYSIA,
    ORG_PHILIPPINES,
    ORG_INDONESIA2,
    ORG_INDONESIA3,
    ORG_VIETNAM4,
    ORG_VIETNAMID,
    ORG_THAILAND1,
    ORG_INDIA1
} from '../../../config/CommonConfig';
import { URL_GET_AFFILIATE, URL_POST_AFFILIATE_UPLOAD } from '../../../config/DomainConfig';
import { getNumberFixed, getPageCustomConfig, resetWindow } from '../../../services/UtilService';
import "./AffiliateReport.css";
import { DS_SOURCE } from './AffiliateReportConfig';

const getDetails = (ds, selected) => {
    let i = 1;
    let rslt = ds.filter(row => row.id === selected.id)[0].custom_parameters;

    rslt = rslt.map(src => {
        return {
            _no: { "__html": i++ },
            _aff_id: { "__html": src.aff_id },
            _cpa_payout: { "__html": getNumberFixed(parseFloat(src.cpa_payout), 0) },
            _cpa_revenue: { "__html": getNumberFixed(parseFloat(src.cpa_revenue), 0) },
            _cpl_payout: { "__html": getNumberFixed(parseFloat(src.cpl_payout), 0) },
            _cpl_revenue: { "__html": getNumberFixed(parseFloat(src.cpl_revenue), 0) }
        };
    });
    console.log(rslt);
    return rslt;
};
export default (props) => {
    const [ds, setDs] = useState([]);
    const SOURCE_LIMIT = 10;
    const customConfig = getPageCustomConfig();
    const _filter = { geo: undefined, affiliate: "" };
    const [filter, setfilter] = useState(_filter);
    const [selected, setselected] = useState({ id: undefined, name: undefined });

    const setOffer = (e, id, name) => {
        e.preventDefault();
        setselected({ id: id, name: name });
    };

    const DS_GEO = [
        { id: ORG_THAILAND, name: "Thailand" },
        { id: ORG_THAILAND1, name: "Thailand 1" },
        { id: ORG_THAILAND2, name: "Thailand 2" },
        { id: ORG_INDONESIA, name: "Indonesia" },
        { id: ORG_INDONESIA2, name: "Indonesia 2" },
        { id: ORG_INDONESIA3, name: "Indonesia 3" },
        { id: ORG_VIETNAM, name: "Vietnam" },
        { id: ORG_VIETNAM2, name: "Vietnam 2" },
        { id: ORG_VIETNAM3, name: "Vietnam 3" },
        { id: ORG_VIETNAM4, name: "Vietnam 4"},
        { id: ORG_VIETNAMID, name: "Vietnam ID"},
        { id: ORG_MALAYSIA, name: "Malaysia" },
        { id: ORG_PHILIPPINES, name: "Philippines" },
        { id: ORG_INDIA1, name: "India 1" }
    ];

    const onFilterChange = (field, value) => {
        filter[field] = value;
        setfilter({ ...filter });
    };

    const getFilteredDs = () => {
        const targetGeo = filter.geo ? DS_GEO.filter(ele => ele.id === filter.geo)[0].name : undefined;
        const rslt = ds.filter(row => {
            let isValid = true;

            if (filter.geo) {
                isValid = row.targeting === targetGeo;
            }

            if (isValid) {
                if (filter.affiliate) {
                    isValid = row.title.toLocaleLowerCase().indexOf(filter.affiliate.toLocaleLowerCase()) > -1;
                }
            }

            return isValid;
        });

        return rslt;
    };

    const loadAffiliate = () => {
        Promise.all([axios.get(URL_GET_AFFILIATE())]).then(output => {
            const resp = output ? output[0] : undefined;
            let data;
            if (resp && resp.status === 200) {
                data = resp.data;
                data.sort((rw1, rw2) => {
                    return parseFloat(rw2.cv) - parseFloat(rw1.cv);
                });

                data.forEach(row => {
                    if (row.custom_parameters) {
                        row.custom_parameters.sort((e1, e2) => {
                            return parseFloat(e2.cpa_payout) - parseFloat(e1.cpa_payout);
                        });
                    }
                });
                setDs(data);
            }
        });
    };

    const uploadDatasource = (e) => {
        const id = e.target.id;
        const flDatasource = "flDatasource";

        if (id === flDatasource) {
            let frmData = new FormData();
            frmData.append("file", document.getElementById(flDatasource).files[0]);
            fetch(URL_POST_AFFILIATE_UPLOAD(), {
                method: "POST",
                headers: new Headers({
                    "Authorization": axios.defaults.headers.common['Authorization']
                    // , "Content-Type": "application/json"
                }),
                body: frmData
            });
            document.getElementById("flDatasource").value = "";
            loadAffiliate();
        } else {
            e.preventDefault();
            document.getElementById(flDatasource).click();
        }
    };

    useEffect(() => {
        loadAffiliate();
        resetWindow();
    }, []);

    return (<React.Fragment>
        {selected.id !== undefined && <div className="static-modal">
            <div className="static-modal-content">
                <div className="container">
                    <button className="btn btn-danger float-right"
                        onClick={e => setselected({ id: undefined, name: undefined })}>
                        <i className="fa fa-window-close"></i>
                    </button>
                    <h4>{selected.name}</h4>
                    <div className="mt-5 pre-scrollable">
                        <TableComponent
                            className="col-12"
                            dataset={DS_SOURCE}
                            dataSource={getDetails(ds, selected)}>
                        </TableComponent>
                    </div>
                </div>
            </div>
        </div>}

        <div className="card my-4">
            <div className="card-body">

                <div className="form-row mb-3">
                    <div className="form-group col-md-3">
                        <label htmlFor="fltGeo">Geo</label>
                        <div className="form-control border-0">
                            <select id="fltGeo" className="form-control" value={filter.geo}
                                onChange={e => onFilterChange("geo", e.target.value)}>
                                <option value="">All</option>
                                {DS_GEO.map(ele => <option key={ele.id} value={ele.id}>{ele.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="fltAffiliate">Affiliate</label>
                        <div className="form-control border-0">
                            <input id="fltAffiliate" type="text" className="form-control" value={filter.affiliate}
                                onChange={e => { onFilterChange("affiliate", e.target.value); }}></input>
                        </div>
                    </div>
                </div>

                <div className="scroll-x wallboard">

                    {ds && getFilteredDs().map(row => {
                        return (<div key={[row.id]} className="wb-row" data-id={row.id}>
                            <div className="wb-col">
                                <div className={"card w230 border-0 text-center" + (customConfig.more ? " more" : "")}>
                                    <div className="card-body">
                                        <p>
                                            <a href="/#" className="mr-1" onClick={e => setOffer(e, row.id, row.title)}>{row.title} ({row.aff_count})</a>
                                        </p>
                                        <p className="">
                                            <span className="text-success mr-1">CPA:</span>
                                            <span className="text-success mr-3">{getNumberFixed(parseFloat(row.cpa_payout), 0)}/{getNumberFixed(parseFloat(row.cpa_revenue), 0)}</span>
                                            <span className="text-info mr-1">CPL:</span>
                                            <span className="text-info mr-1">{getNumberFixed(parseFloat(row.cpl_payout), 0)}/{getNumberFixed(parseFloat(row.cpl_revenue), 0)}</span>
                                        </p>
                                        <p>
                                            <span className="text mr-1">AR:</span>
                                            <span className="text mr-3">{row.ar}%</span>
                                            <span className="text-danger mr-1">CV:</span>
                                            <span className="text-danger mr-1">{getNumberFixed(row.cv, 0)}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {row.custom_parameters.map((src, idx) => (<React.Fragment key={[src, idx]}>
                                {idx < SOURCE_LIMIT && <div className="wb-col ml-1" data-custom-param-id={src.custom_param_id}>
                                    <div className="card w180 text-center">
                                        <div className="card-body">
                                            <p>
                                                <span className="mr-1">{src.aff_id}</span>
                                            </p>
                                            <p>
                                                <span className="text-success mr-1">CPA:</span>
                                                <span className="text-success mr-1">{getNumberFixed(parseFloat(src.cpa_payout), 0)}/{getNumberFixed(parseFloat(src.cpa_revenue), 0)}</span>
                                            </p>
                                            <p>
                                                <span className="text-info mr-1">CPL:</span>
                                                <span className="text-info mr-1">{getNumberFixed(parseFloat(src.cpl_payout), 0)}/{getNumberFixed(parseFloat(src.cpl_revenue), 0)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>}
                            </React.Fragment>))}
                        </div>);
                    })}
                </div>
            </div>

            <input type="file" id="flDatasource" className="invisible" onChange={(e) => uploadDatasource(e)}></input>
            <div className="row mr-3"><div className="col-12 small text-right"> <a href="/#" id="lnDatasource" onClick={(e) => uploadDatasource(e)}>Upload datasource</a></div></div>
            {ds && ds.length > 0 && <div className="row mb-3 mr-3"><div className="col-12 small text-right">{ds[0].eventtime}</div></div>}
        </div>
    </React.Fragment>);
};
