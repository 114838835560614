const STORAGE_DOMAIN_IDX = 'domain_idx'
const STORAGE_JWT = 'jwt'
const STORAGE_USERNAME = 'username'
const STORAGE_PERMISSION = 'perm'
const STORAGE_CUSTOM_CONFIG = 'cuscon'
const STORAGE_ROLE = 'role'
const STORAGE_THRESHOLD_LAST_FRESH = 'threshold_last_fresh'
const STORAGE_MENU_ORG = "mnu_org"
const STORAGE_SIZE = "size"
const STORAGE_LEAD_STATUSES = 'leadStatuses'

export {
    STORAGE_DOMAIN_IDX,
    STORAGE_JWT,
    STORAGE_USERNAME,
    STORAGE_PERMISSION,
    STORAGE_CUSTOM_CONFIG,
    STORAGE_ROLE,
    STORAGE_THRESHOLD_LAST_FRESH,
    STORAGE_MENU_ORG,
    STORAGE_SIZE,
    STORAGE_LEAD_STATUSES
}