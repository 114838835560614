import React, { useEffect, useState } from 'react'
import { URL_GET_STATISTIC_CALL_DO_STATUS_MAPPING, URL_GET_STATISTIC_CALL_LOD_DO } from '../../../config/DomainConfig'
import { getJwtToken } from '../../../services/AuthenticateService'
import { getOrg, resetWindow } from '../../../services/UtilService'

const LogDO = (props) => {
    const [query, setquery] = useState({ doID: undefined })

    const callGetDOStatusMappingXlsx = (e) => {
        e.preventDefault()
        var xhr = new XMLHttpRequest();
        const fileName = "DOStatusMapping"

        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                var downloadUrl = URL.createObjectURL(xhr.response)
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = downloadUrl
                a.download = fileName + ".xlsx"
                a.click()
            }
        }

        xhr.open("GET", URL_GET_STATISTIC_CALL_DO_STATUS_MAPPING(), true);
        xhr.responseType = "blob";
        xhr.setRequestHeader('Authorization', 'Bearer ' + getJwtToken());
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Org', getOrg());
        xhr.send(JSON.stringify({}));
    }

    const callGetLogDOXlsx = () => {
        var xhr = new XMLHttpRequest();
        const fileName = "LogDO" + query.doID

        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                var downloadUrl = URL.createObjectURL(xhr.response)
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = downloadUrl
                a.download = fileName + ".xlsx"
                a.click()
            }
        }

        xhr.open("GET", URL_GET_STATISTIC_CALL_LOD_DO().replaceAll("doID", query.doID), true);
        xhr.responseType = "blob";
        xhr.setRequestHeader('Authorization', 'Bearer ' + getJwtToken());
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Org', getOrg());
        xhr.send(JSON.stringify({}));
    }

    useEffect(() => {
        resetWindow()
    }, [])

    return (<React.Fragment>
        <div className="form-row">
            <div className="form-group col-md-3">
                <div className="form-control border-0">
                    <input type="text"
                        className="form-control"
                        value={query.doID}
                        onChange={(e) => { setquery({ ...query, doID: e.target.value }) }}></input>
                </div>
            </div>
            <div className="form-group col-md-1 mt-2">
                <button id="btnSearch" className="btn btn-success" onClick={(e) => { callGetLogDOXlsx() }}>Search</button>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <a href="/#" className="nav-link" onClick={(e) => { callGetDOStatusMappingXlsx(e) }}>status mapping</a>
            </div>
        </div>
    </React.Fragment>)
}

export default LogDO