import Axios from "axios"
import { URL_GET_STATISTIC_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE, URL_GET_STATISTIC_REPORT_TEAM_TODAY_BY_CREATEDATE } from "../../config/DomainConfig"
import { getNorm, NORM_PROPERTIES } from "../reports/PropertyConvertion"

const callTeamTodayByCreatedateStatisticReport = async (dateFrom, dateTo) => {
    let data = []
    const resp = await Axios.get(URL_GET_STATISTIC_REPORT_TEAM_TODAY_BY_CREATEDATE() + "/" + dateFrom + "/" + dateTo)

    if (resp && resp.data && resp.data.teamTodayByCreatedateEntities) {
        data = resp.data.teamTodayByCreatedateEntities
        data = getNorm(data, NORM_PROPERTIES)
    }

    return { data: data, created: resp.data.created }
}

const callTeamProdTodayByCreatedateStatisticReport = async (dateFrom, dateTo) => {
    let data = []
    const resp = await Axios.get(URL_GET_STATISTIC_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE() + "/" + dateFrom + "/" + dateTo)

    if (resp && resp.data && resp.data.teamProdTodayByCreatedateEntities) {
        data = resp.data.teamProdTodayByCreatedateEntities
        data.forEach(row => {
            row.cpName = row.prodName
            row.cpId = row.prodId
        })
        data = getNorm(data, NORM_PROPERTIES)
    }

    return { data: data, created: resp.data.created }
}

export {
    callTeamTodayByCreatedateStatisticReport,
    callTeamProdTodayByCreatedateStatisticReport
}