import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayV2Statistic } from '../../../services/statisticReports/AgentTodayStatisticReportService'
import { getRedisDateFormat, resetWindow, getDateFormat } from '../../../services/UtilService'
import { DS_AGENT_TODAY_V2_BY_CREATEDATE } from './LeadDistTodayConfig'

// var formatYmd = date => date.toISOString().slice(0, 10);
// var today = formatYmd(new Date());

export default (props) => {
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({
        valid: false,
        date: undefined
    })
    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }

        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field] 
        let diff
        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else if (sort.field === "_leadCreatedDate") {
            val1 = val1.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            val2 = val2.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        query.valid = false;
    
        query[name] = value
        // if(query.dateFrom && query.dateTo) {
        //   query.valid = query.dateFrom.getTime() <= query.dateTo.getTime()
        // }
        setquery({ ...query })
        onSearch()
    }

    const onSearch = (e) => {
        var obj = {
            creFrom: `${getDateFormat(query.date, 'yyyy-MM-dd')} 00:00:00`,
            creTo: `${getDateFormat(query.date, 'yyyy-MM-dd')} 23:59:59`,
            isShift: true
        }
        // obj.dateFrom = getDateFormat(query.dateFrom, 'yyyy-MM-dd') + ' 00:00:00'
        // obj.dateTo = getDateFormat(query.dateTo, 'yyyy-MM-dd') + ' 23:59:59'
        // let callFns = []
        // callFns = [callAgentTodayV2Statistic(obj)]
        // Promise.all(callFns).then(rest => {
        //     setDs(rest[0][0])
        //     document.getElementById("redisDateTab").innerHTML = "Cache Table " + getRedisDateFormat(rest[0][1])
        // })

        const calls = [callAgentTodayV2Statistic(obj)]

        Promise.all(calls).then(resp => {
            setDs(resp[0][0])
            document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(resp[0][1])
            // document.getElementById("redisDateTab").innerHTML = "Cache Table " + getRedisDateFormat(resp[0].created)
        })
    }

    useEffect(() => {
        // var obj = {
        //     isShift: true,
        //     creFrom: `${today} 00:00:00`,
        //     creTo: `${today} 23:59:59`
        // }
        // Promise.all([callAgentTodayV2Statistic(obj)]).then(resp => {
        //     const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
        //     const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
        //     setDs(campaigns)
        //     document.getElementById("redisDateTab").innerHTML = "Cache Table " + getRedisDateFormat(resp[0].created)
        // })
        resetWindow()
    }, [])

    return <React.Fragment>
        <div className="w-100 mt-2">
            <div className='panelDefault'>
                <div className='panelHeader'>{ 'The leads in this report will be counted by created date'}</div>
                <div style={{ padding: '10px'}}>
                    {/* <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="fltDateFrom">{'Created Date From'}</label>
                            <div className="form-control border-0 p-0">
                                <ReactDatePicker id="fltDateFrom"
                                    name="dateFrom"
                                    selected={query.dateFrom}
                                    maxDate={query.dateTo ? query.dateTo : new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                                    className="form-control"></ReactDatePicker>
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="fltDateTo">{'Created Date To'}</label>
                            <div className="form-control border-0 p-0">
                                <ReactDatePicker id="fltDateTo"
                                    name="dateTo"
                                    selected={query.dateTo}
                                    maxDate={new Date()}
                                    minDate={query.dateFrom ? query.dateFrom : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                                    className="form-control"></ReactDatePicker>
                            </div>
                        </div>
                        <div className="form-group col-md-3 mt-4">
                        <button id="btnSearch" disabled={!query.valid} className="form-control w-50 border-0 btn btn-success" onClick={e => onSearch(e)}>Search</button>
                        </div>
                    </div> */}
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="fltDate">Date</label>
                            <div className="form-control border-0">
                                <ReactDatePicker id="fltDate"
                                    name="date"
                                    selected={query.date}
                                    maxDate={new Date()}
                                    minDate={query.dateFrom ? query.dateFrom : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => onQueryChange({ target: { name: "date", value: date } })}
                                    className="form-control"></ReactDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {ds && ds.map((cp, idx) => (<div key={cp.id} className={"cp_cat_" + cp.cpCat}>
            <h4 className="mt-2">
                {ds.indexOf(cp) + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <TableComponent
                tableProps={{ id: "tableCp" + cp.id }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_AGENT_TODAY_V2_BY_CREATEDATE}
                dataSource={cp.todayByCreatedateList.sort(sortList)}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>))}

        <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </React.Fragment>
}
