import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callGetFindDoStatusSummary } from '../../../services/reports/RescueSummaryReportService'
import { resetWindow } from '../../../services/UtilService'
import { DS_RESCUE_SUMMARY } from './RescueSummaryReportConfig'
import RescueSummaryReportPopup from './RescueSummaryReportPopup'

const RescueSummaryReport = (props) => {
    const _selected = { days: undefined, statusCase: undefined, count: undefined }
    const [ds, setDs] = useState([])
    const [selected, setselected] = useState(_selected)

    const onTdClick = (col, row) => {
        const field = col.column.substr(1)
        // const value = row[col.column]["__html"]
        const days = row["_day"]["__html"]

        if (field !== "day") {
            window.open("/report/rescueSummary/th/" + field + "/" + days)
            // setselected({ days: days, statusCase: field, count: value })
        }
    }

    const clearSelected = () => {
        setselected({ ..._selected })
    }

    useEffect(() => {
        Promise.all([callGetFindDoStatusSummary()]).then(resp => {
            const rslt = resp[0]
            setDs(rslt.map(row => {

                return {
                    "_day": { __html: row.day },
                    "_9-pending": { __html: row["9-pending"] },
                    "_1-new": { __html: row["1-new"] },
                    "_2-intransit": { __html: row["2-intransit"] },
                    "_3-delivering": { __html: row["3-delivering"] },
                    "_4-returning": { __html: row["4-returning"] },
                    "_0-unknown": { __html: row["0-unknown"] }
                }
            }))
        })
        resetWindow()
    }, [])

    return <React.Fragment>
        {selected.statusCase && <RescueSummaryReportPopup
            query={selected}
            clear={clearSelected}>
        </RescueSummaryReportPopup>}

        {ds && (<TableComponent
            className="mt-2 w-100"
            theadClassName="jumbo"
            tbodyClassName="big"
            onTdClick={onTdClick}
            dataset={DS_RESCUE_SUMMARY}
            dataSource={ds}>
        </TableComponent>)}

    </React.Fragment>
}

export default RescueSummaryReport