import Axios from "axios"
import { getJwtToken } from '../../services/AuthenticateService'
import { URL_GET_REPORT_RESCUE_FIND_DD_POSTBACK, URL_GET_REPORT_RESCUE_FIND_DD_STATUS_DETAIL, URL_GET_REPORT_RESCUE_FIND_DD_STATUS_SUMMARY, URL_GET_REPORT_RESCUE_FIND_RC_CALL } from "../../config/DomainConfig"
import { getOrg } from "../UtilService"

const callGetFindDoStatusSummary = async () => {
    let rslt = []
    const resp = await Axios.get(URL_GET_REPORT_RESCUE_FIND_DD_STATUS_SUMMARY())
    const days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 15, 22, 30]
    const cases = ['9-pending', '1-new', '2-intransit', '3-delivering', '4-returning', '0-unknown']
    const idx = {}

    if (resp && resp.status === 200) {
        if (resp.data) {
            resp.data.forEach(row => {
                const days = row.days
                const statusCase = row.statusCase
                const count = row.count

                if (!idx[statusCase]) {
                    idx[statusCase] = {}
                }
                idx[statusCase][days] = count
            })

            rslt = days.map(day => {
                const rowData = { day: day }

                cases.forEach(sts => {
                    rowData[sts] = idx[sts] && idx[sts][day] ? idx[sts][day] : 0
                })

                return rowData
            })
        }
    }

    return rslt
}
const callGetFindDoStatusDetail = async (statusCase, days) => {
    let rslt = []
    const resp = await Axios.get(URL_GET_REPORT_RESCUE_FIND_DD_STATUS_DETAIL() + "/" +
        statusCase + "/" +
        days)

    if (resp && resp.status === 200) {
        if (resp.data) {
            rslt = resp.data
        }
    }

    return rslt
}
const callGetFindDoStatusDetailXlsx = (statusCase, days) => {
    var xhr = new XMLHttpRequest();
    const fileName = statusCase + "-" + days

    xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            var downloadUrl = URL.createObjectURL(xhr.response)
            var a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.href = downloadUrl
            a.download = fileName + ".xlsx"
            a.click()
        }
    }

    xhr.open("GET", URL_GET_REPORT_RESCUE_FIND_DD_STATUS_DETAIL() + "/" +
        statusCase + "/" +
        days + "/xlsx", true);
    xhr.responseType = "blob";
    xhr.setRequestHeader('Authorization', 'Bearer ' + getJwtToken());
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Org', getOrg());
    xhr.send(JSON.stringify({}));
}
const callGetFindRcCall = async (dateFrom, dateTo) => {
    let rslt = []
    const resp = await Axios.get(URL_GET_REPORT_RESCUE_FIND_RC_CALL() + "/" +
        dateFrom + "/" +
        dateTo)

    if (resp && resp.status === 200) {
        if (resp.data) {
            rslt = resp.data
        }
    }

    return rslt
}
const callGetFindRcCallXlsx = (dateFrom, dateTo) => {
    var xhr = new XMLHttpRequest();
    const fileName = dateFrom + "-" + dateTo

    xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            var downloadUrl = URL.createObjectURL(xhr.response)
            var a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.href = downloadUrl
            a.download = fileName + ".xlsx"
            a.click()
        }
    }

    xhr.open("GET", URL_GET_REPORT_RESCUE_FIND_RC_CALL() + "/" +
        dateFrom + "/" +
        dateTo + "/xlsx", true);
    xhr.responseType = "blob";
    xhr.setRequestHeader('Authorization', 'Bearer ' + getJwtToken());
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Org', getOrg());
    xhr.send(JSON.stringify({}));
}
const callGetFindDoPostback = async (trackingCode) => {
    let rslt = []
    const resp = await Axios.get(URL_GET_REPORT_RESCUE_FIND_DD_POSTBACK() + "/" +
        trackingCode)

    if (resp && resp.status === 200) {
        if (resp.data) {
            rslt = resp.data
        }
    }

    return rslt
}

export {
    callGetFindDoStatusSummary,
    callGetFindDoStatusDetail,
    callGetFindDoStatusDetailXlsx,
    callGetFindRcCall,
    callGetFindRcCallXlsx,
    callGetFindDoPostback
}