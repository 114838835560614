import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayByCreatedateStatisticReport } from '../../../services/statisticReports/AgentTodayStatisticReportService'
import { callAgentTodayByCreatedateShiftReport } from '../../../services/reports/AgentTodayReportService'
import { getDateFormat, getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import { DS_AGENT_TODAY_BY_CREATEDATE_SHIFT } from './LeadDistTodayConfig'
import "./LeadDistToday.css"
import ReactDatePicker from 'react-datepicker'

export default (props) => {
    const { isDatePicker } = props
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({
        valid: false,
        date: undefined
    })
    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return 1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return -1
        }


        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else if (sort.field === "_leadCreatedDate") {
            val1 = val1.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            val2 = val2.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.date !== undefined
        setquery({ ...query })
        onSearch()
    }

    const getDataSources = (ds) => {
        const shifts = ds.map(row => row.shift).reduce((acc, cur) => {
            if (acc.indexOf(cur) === -1) {
                acc.push(cur)
            }
            return acc
        }, []).sort((a, b) => {
            return (a ? a + 1 : 9) - (b ? b + 1 : 0)
        })
        
        return shifts.reverse().map(shift => {
            let rows = ds.filter(row => row.shift === shift)

            return rows.sort(sortList)
        })
    }

    const getTbodyClassNames = (ds) => {
        const shifts = ds.map(row => row.shift).reduce((acc, cur) => {
            if (acc.indexOf(cur) === -1) {
                acc.push(cur)
            }
            return acc
        }, []).sort((a, b) => {
            return (a ? a + 1 : 9) - (b ? b + 1 : 0)
        })

        let clss = shifts.reverse().map((v, i) => "big tbody" + v)
        return clss
    }

    const onSearch = () => {
        const payload = {
            creFrom: getDateFormat(query.date),
            creTo: getDateFormat(query.date),
            isShift: true
        }
        const calls = [callAgentTodayByCreatedateStatisticReport(payload)]

        Promise.all(calls).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
            setDs(campaigns)
            // document.getElementById("redisDateTab").innerHTML = "Cache Table " + getRedisDateFormat(resp[0].created)
        })
    }

    useEffect(() => {
        let calls = []

        if (!isDatePicker) {
            calls = [callAgentTodayByCreatedateShiftReport({})]

            Promise.all(calls).then(resp => {
                const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
                const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
                setDs(campaigns)
                document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
            })
        }
        resetWindow()
    }, [isDatePicker])

    return <React.Fragment>
        {isDatePicker && <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="fltDate">Date</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDate"
                        name="date"
                        selected={query.date}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "date", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
        </div>}
        {ds && ds.map((cp, idx) => (<div key={cp.id} className={"cp_cat_" + cp.cpCat}>
            <h4 className="mt-2">
                {ds.indexOf(cp) + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <TableComponent
                tableProps={{ id: "tableCp" + cp.id }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassNames={getTbodyClassNames(cp.todayByCreatedateList)}
                dataset={DS_AGENT_TODAY_BY_CREATEDATE_SHIFT}
                dataSources={getDataSources(cp.todayByCreatedateList)}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>))}

        <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </React.Fragment>
}
