import React, { useState } from 'react'
import { useEffect } from 'react'
import { getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import { NORM_PROPERTIES, getNorm } from '../../../services/reports/PropertyConvertion'
import { callTeamTodayByCreatedateReport } from '../../../services/reports/TeamTodayReportService'
import TeamPerformanceComponent from './TeamPerformanceComponent'
import './TeamPerformanceReport.css'
import TeamReportComponent from '../RelPerformanceReport/TeamReportComponent'

const calcNode = (teamId, cpId, raw) => {
    const rslt = {}
    let ear
    const myFields = raw && raw.length > 0 ? Object.keys(raw[0]) : []
    const sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld)).map(fld => NORM_PROPERTIES[fld])

    sumFields.forEach(field => {
        rslt[field] = 0

        raw.forEach(row => {
            if (
                (teamId === row.teamId && cpId === row.cpId) ||
                (teamId === row.teamId && cpId === undefined) ||
                (teamId === undefined && cpId === row.cpId)
            ) {
                rslt[field] += row[field]
            }
        })
    })

    ear = rslt.approved * 100 / (rslt.approved + rslt.rejected + rslt.callback)

    rslt.ear = isNaN(ear) ? 0 : ear

    return rslt
}

const TeamPerformanceReport = (params) => {
    const [ds, setds] = useState({})
    const [zoom, setZoom] = useState()//TODO

    useEffect(() => {
        // Promise.all([Axios.get("/payload/team_today.json")]).then(httpResp => {
        // const resp = [httpResp[0].data]
        // let arr = getNorm(resp[0].teamTodayEntities, NORM_PROPERTIES)
        Promise.all([callTeamTodayByCreatedateReport({})]).then(httpResp => {
            const resp = [httpResp[0]]
            let arr = getNorm(resp[0].data, NORM_PROPERTIES)
            let rslt

            arr = arr.filter(row => !row.cpCat || row.cpCat === 1)

            rslt = {
                rows: arr,
                teams: arr.map(ele => { return { teamId: ele.teamId, teamName: ele.teamName } })
                    .reduce((ob1, ob2) => {
                        return ob1.map(ob => ob.teamId).indexOf(ob2.teamId) === -1 ? [...ob1, ob2] : [...ob1]
                    }, []),
                campaigns: arr.map(ele => { return { cpId: ele.cpId, cpName: ele.cpName } })
                    .reduce((ob1, ob2) => {
                        return ob1.map(ob => ob.cpId).indexOf(ob2.cpId) === -1 ? [...ob1, ob2] : [...ob1]
                    }, []),
                total: {
                    teams: {},
                    campaigns: {}
                }
            }

            rslt.teams.forEach(tm => {
                rslt.total.teams[tm.teamId] = calcNode(tm.teamId, undefined, arr)
            })
            rslt.campaigns.forEach(cp => {
                rslt.total.campaigns[cp.cpId] = calcNode(undefined, cp.cpId, arr)
            })

            rslt.teams.forEach(tm => {
                rslt.total[tm.teamId] = {}
                rslt.campaigns.forEach(cp => {
                    rslt.total[tm.teamId][cp.cpId] = calcNode(tm.teamId, cp.cpId, arr)
                })
            })

            rslt.teams.sort((tm1, tm2) => rslt.total.teams[tm2.teamId].totalSoAmount - rslt.total.teams[tm1.teamId].totalSoAmount)
            rslt.campaigns.sort((cp1, cp2) => rslt.total.campaigns[cp2.cpId].totalSoAmount - rslt.total.campaigns[cp1.cpId].totalSoAmount)

            setds({ ...rslt })
            document.getElementById("redisDateChart").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
        })

        resetWindow()
    }, [])

    return (<React.Fragment>

        {ds.teams && <React.Fragment>
            {!zoom && <TeamPerformanceComponent ds={ds}
                onZoomClick={setZoom}>
            </TeamPerformanceComponent>}

            {zoom && <TeamReportComponent
                hierarchy={zoom.hierarchy}
                dsOrigin={
                    ds.rows.filter(row => {
                        let isValid = zoom.query.teamId === undefined || zoom.query.teamId === row.teamId

                        if (isValid) {
                            isValid = zoom.query.cpId === undefined || zoom.query.cpId === row.cpId
                        }

                        return isValid
                    })
                }
                onClose={() => setZoom(undefined)}>
            </TeamReportComponent>}
        </React.Fragment>}

        <div className="row"><div id="redisDateChart" className="col-12 small text-right"></div></div>
    </React.Fragment>)
}

export default TeamPerformanceReport