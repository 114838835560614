const DS_SOURCE = {
    "columns": [
        { "column": "_no", "title": "No", "width": "10%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_pub", "title": "Pub", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_validated", "title": "Validated", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "15%", "thClassName": "text-center", "tdClassName": "", "sort": true }
    ]
}

export { DS_SOURCE }