import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayByModifydateStatisticReport } from '../../../services/statisticReports/AgentTodayStatisticReportService'
import { getDateStrFormat, getDateFormat, getNumberFixed, getNumberWithCommas, getValWithPercentHtml, resetWindow, convertTime, isDecimal } from '../../../services/UtilService'
import { DS_AGENT_TODAY_BY_CREATEDATE_AGENTALL } from '../../Report/LeadDistToday/LeadDistTodayConfig'

const AgentAllStatisticReport = (props) => {
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({ valid: false, dateFrom: undefined, dateTo: undefined, keyword: undefined, cpCat: 1 })
    const maxDate = new Date()
    const minDate = new Date()

    minDate.setDate(minDate.getDate() - 31)

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    const onSearch = () => {
        const dateFrom = getDateFormat(query.dateFrom)
        const dateTo = getDateFormat(query.dateTo)
        Promise.all([callAgentTodayByModifydateStatisticReport(dateFrom, dateTo)]).then(resp => {
            const reportInsgAgentResponse = resp[0]
            const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse : []
            setDs(campaigns)
        })
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }


        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } 
        else if (sort.field === "_uncallAvgRing" || sort.field === "_avgtalktime") {
            val1 = val1.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            val2 = val2.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    const getDataSource = () => {
        let rslt = ds && ds.length > 0 ? [...ds] : []
        const keyword = query.keyword
        const tot = {
            totalDayLead: 0,
            dayNew: 0,
            dayApproved: 0,
            dayRejected: 0,
            dayTrash: 0,
            dayUrgent: 0,
            dayDelivered: 0,
            dayCallback: 0,
            dayUncall: 0,
            dayTotalSoAmount: 0,
            dayDelivSaleOrder: 0,
            dayDelivAmount: 0,
            leadCreatedDate: undefined,
            dayAvgUncallRingingTime: 0,
            dayTotalCall: 0,
            dayAvgTalkTime: 0,
            dayTotalOverdueCallback: 0,
            dayAvgUncallCall: 0,
        }

        rslt = rslt.filter(row => {
            return (query.cpCat === row.cpCat || (query.cpCat !== 1 && row.cpCat !== 1))
        })
        if (keyword) {
            rslt = rslt.filter(row => row.userName.indexOf(keyword.toLocaleLowerCase()) > -1)
        }

        let arrUncallAvgAtt = [];
        let arrUncallAvgRing = [];
        let arrAvgtalktime = [];

        if (rslt.length > 1) {
            rslt.sort(sortList)
            rslt.forEach(_en => {
                const _totalDayLead = _en.totalDayLead
                const _dayNew = _en.dayNew
                const _dayApproved = _en.dayApproved
                const _dayRejected = _en.dayRejected
                const _dayTrash = _en.dayTrash
                const _dayUrgent = _en.dayUrgent
                const _dayDelivered = _en.dayDelivered
                const _dayCallback = _en.dayCallback
                const _dayUncall = _en.dayUncall
                const _dayTotalSoAmount = _en.dayTotalSoAmount
                const _dayDelivSaleOrder = _en.dayDelivSaleOrder
                const _dayDelivAmount = _en.dayDelivAmount
                const _aov = _dayTotalSoAmount / _dayApproved
                const _daov = _dayDelivAmount / _dayDelivSaleOrder
                const _usr = _en.userName.split("@")
                const _teamName = _en.teamName ? _en.teamName : "No team"
                const _leadCreatedDate = _en.leadCreatedDateFormat

                const _uncallAvgAtt = _en.dayAvgUncallCall
                const _uncallAvgRing = _en.dayAvgUncallRingingTime === null ? 0 : _en.dayAvgUncallRingingTime
                const _totalcalls = _en.dayTotalCall
                const _avgtalktime = _en.dayAvgTalkTime === null ? 0 : _en.dayAvgTalkTime
                const _odcb = _en.dayTotalOverdueCallback

                tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
                tot.dayNew += _dayNew ? _dayNew : 0
                tot.dayApproved += _dayApproved ? _dayApproved : 0
                tot.dayRejected += _dayRejected ? _dayRejected : 0
                tot.dayTrash += _dayTrash ? _dayTrash : 0
                tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
                tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
                tot.dayCallback += _dayCallback ? _dayCallback : 0
                tot.dayUncall += _dayUncall ? _dayUncall : 0
                tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
                tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
                tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0

                arrUncallAvgAtt.push(_uncallAvgAtt)
                arrUncallAvgRing.push(_uncallAvgRing)
                arrAvgtalktime.push(_avgtalktime)
                arrUncallAvgAtt = arrUncallAvgAtt.filter(function(value, index, arr) {
                    return value >= 1;
                })
                arrUncallAvgRing = arrUncallAvgRing.filter(function(value, index, arr) {
                    return value >= 1;
                })
                arrAvgtalktime = arrAvgtalktime.filter(function(value, index, arr) {
                    return value >= 1;
                })

                tot.dayAvgUncallCall += _uncallAvgAtt ? _uncallAvgAtt : 0
                tot.dayAvgUncallRingingTime += _uncallAvgRing ? _uncallAvgRing : 0
                tot.dayTotalCall += _totalcalls ? _totalcalls : 0
                tot.dayAvgTalkTime += _avgtalktime ? _avgtalktime : 0
                tot.dayTotalOverdueCallback += _odcb ? _odcb : 0

                tot.leadCreatedDate = _leadCreatedDate &&
                    (tot.leadCreatedDate === undefined || _leadCreatedDate.localeCompare(tot.leadCreatedDate) > 0) ?
                    _leadCreatedDate : tot.leadCreatedDate

                _en["_name"] = { "__html": _usr[0] }
                _en["_team"] = { "__html": _teamName }
                _en["_leads"] = { "__html": _totalDayLead + "" }
                _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount, 0) }
                _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
                _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
                _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
                _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
                _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
                _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
                _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
                _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
                _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
                _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
                _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
                _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
                _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
                _en["_leadCreatedDate"] = { "__html": _leadCreatedDate ? getDateStrFormat(_leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" }
                _en["_uncallAvgAtt"] = { "__html": isDecimal(_uncallAvgAtt) === true ? _uncallAvgAtt.toFixed(1) + "" : _uncallAvgAtt + "" }
                _en["_uncallAvgRing"] = { "__html": _uncallAvgRing === 0 ? "0s" : convertTime(_uncallAvgRing.toFixed(1)) }
                _en["_totalcalls"] = { "__html": _totalcalls + "" }
                _en["_avgtalktime"] = { "__html": _avgtalktime === 0 ? "0s" : convertTime(_avgtalktime) }
                _en["_odcb"] = { "__html": _odcb + "" }
            })

            let sum = 0;
            let suma = 0;
            let sumd = 0;
            arrUncallAvgAtt.forEach(function(element){
                sum += element;
            });
            arrUncallAvgRing.forEach(function(element){
                suma += element;
            });
            arrAvgtalktime.forEach(function(element){
                sumd += element;
            });

            const trTot = {
                "_name": { "__html": "TOTAL" },
                "_team": { "__html": "" },
                "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) },
                "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
                "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
                "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
                "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
                "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
                "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
                "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
                "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
                "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
                "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
                "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
                "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
                "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
                "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
                "_leadCreatedDate": { "__html": tot.leadCreatedDate ? getDateStrFormat(tot.leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" },
                "trClassName": "total",
                "_uncallAvgAtt": { "__html": sum === 0 ? "0" : (sum / arrUncallAvgAtt.length).toFixed(1) + "" },
                "_uncallAvgRing": { "__html": suma === 0 ? "0s" : convertTime((suma / arrUncallAvgRing.length).toFixed(1)) },
                "_totalcalls": { "__html": tot.dayTotalCall + "" },
                "_avgtalktime": { "__html": sumd === 0 ? "0s" : convertTime((sumd / arrAvgtalktime.length)) },
                "_odcb": { "__html": tot.dayTotalOverdueCallback + "" },
            }
            Object.keys(tot).forEach(fld => {
                trTot[fld] = tot[fld]
            })
            rslt.push(trTot)
        }

        return rslt
    }

    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>

        <div className="form-row">
            <div className="form-group col-md-3">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        // minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-3">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        // minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-3">
                <label htmlFor="fltDateTo">Keyword</label>
                <div className="form-control border-0">
                    <input type="text"
                        id="fltKeyword"
                        name="keyword"
                        className="form-control"
                        onChange={e => onQueryChange(e)}></input>
                </div>
            </div>
        </div>
        {query.valid && <div className="form-row justify-content-end mb-3">
            <button id="btnFresh" className={query.cpCat === 1 ? "btn btn-success mr-1" : "btn btn-secondary mr-1"} onClick={e => onQueryChange({ target: { name: "cpCat", value: 1 } })}>Fresh</button>
            <button id="btnResell" className={query.cpCat !== 1 ? "btn btn-success mr-5" : "btn btn-secondary mr-5"} onClick={e => onQueryChange({ target: { name: "cpCat", value: 2 } })}>Resell</button>
            <button className="btn btn-success" onClick={e => onSearch()}>Search</button>
        </div>}

        {ds && ds.length > 0 && <div className='table-Wrap'><TableComponent
            className="mt-2 w-100 table-no-pad"
            theadClassName="big"
            tbodyClassName="big"
            dataset={DS_AGENT_TODAY_BY_CREATEDATE_AGENTALL}
            dataSource={getDataSource()}
            sort={sort}
            onSortHandler={onSortHandler}>
        </TableComponent></div>}
    </React.Fragment>
}

export default AgentAllStatisticReport