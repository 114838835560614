import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callPostHSReportCampaignBatchOverview } from '../../../services/hsReports/CampaignOverviewHSReportService'
import { resetWindow } from '../../../services/UtilService'
import { DS_CAMPAIGN_OVERVIEW } from './CampaignResellOverviewHSReportDataset'
export default (props) => {
    const iniFilter = { "campaign": "M" }
    const iniDataSource = { "campaign": [], "batch": [], "selected": {} }
    const [filter] = useState(iniFilter)
    const [dataSource, setDataSource] = useState(iniDataSource)
    const [query, setquery] = useState({
        date: undefined,
        load: false
    })

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        setquery({ ...query })
    }

    const onTrClick = (row) => {
        dataSource.selected[row.name] = dataSource.selected[row.name] ? false : true
        setDataSource({ ...dataSource })
    }

    const getProcessed = (arrCp, arrBatch, selected) => {
        arrCp.forEach(cp => {
            const icon = selected[cp.name] ? '<i class="fa fa-minus table-sm"></i>' : '<i class="fa fa-plus table-sm"></i>'
            cp["_name"] = { "__html": cp['name'] + icon }
        })
        arrBatch = arrBatch.filter(row => {
            return selected[row.name]
        })
        const grpIdx = arrCp.map(row => row.name)
        const arr = arrCp && arrBatch ? arrCp.concat(arrBatch) : []
        const rslt = arr.sort((a, b) => {
            // if (a.name === b.name) {
            //     if (a.grp === b.grp) {
            //         return b.totalLeads - a.totalLeads
            //     } else {
            //         return a.grp - b.grp
            //     }
            // } else {
            //     return b.totalLeads - a.totalLeads
            // }

            let diff = grpIdx.indexOf(a.name) - grpIdx.indexOf(b.name)

            if (diff === 0) {
                return b.totalLeads - a.totalLeads
            } else {
                return diff
            }
        })

        return rslt
    }

    useEffect(() => {
        let callFns = []

        if (query.load) {
            callFns = [
                callPostHSReportCampaignBatchOverview({ "date": query.date, "leadType": filter.campaign })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "A" })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "M" })
            ]

            Promise.all(
                callFns
            ).then(resp => {
                const dsCampaign = resp[0]
                setDataSource({
                    "campaign": dsCampaign.campaign ? dsCampaign.campaign : [],
                    "batch": dsCampaign.batch ? dsCampaign.batch : [],
                    "selected": {}
                })
                setquery({ ...query, load: false })
            })
        }
    }, [filter, query])

    useEffect(() => {
    }, [dataSource.campaign, dataSource.selected])

    useEffect(() => {
        resetWindow()
    }, [])

    return (<div className="card mb-4">
        <div className="card-body">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-3">
                            <input type="text" id="fltDate"
                                name="date"
                                placeholder="yyyy-mm-dd"
                                value={query.date}
                                onChange={e => onQueryChange({ target: { name: "date", value: e.target.value } })}
                                className="form-control"></input>
                        </div>
                        <div className="col-1">
                            <button className="btn btn-success"
                                onClick={e => { onQueryChange({ target: { name: "load", value: true } }) }}
                            >Search</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 scroll-x">
                            <TableComponent className="mt-2 w-100"
                                theadClassName="big"
                                dataset={DS_CAMPAIGN_OVERVIEW}
                                dataSource={
                                    getProcessed(dataSource.campaign, dataSource.batch, dataSource.selected)
                                }
                                onTrClick={onTrClick}>
                            </TableComponent>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-3">
                        <label className="small">Top {dataSource.topFresh.length} Agents(fresh)</label>

                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_FRESH} dataSource={dataSource.topFresh}></TableComponent>
                            </div>
                        </div>

                        <label className="small mt-5">Top {dataSource.topResell.length} Agents(resell)</label>
                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_RESELL} dataSource={dataSource.topResell}></TableComponent>
                            </div>
                        </div>
                    </div> */}
            </div>

            <div className="row"><div id="redisDateSum" className="col-12 small text-right"></div></div>
            <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
        </div>
    </div>)
}
