import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { NORM_PROPERTIES } from '../../../services/reports/PropertyConvertion'
import { getRedisDateFormat } from '../../../services/UtilService'
import { DS_TEAM_TODAY } from './TeamTodayConfig'


import './TeamTodayReport.css'

const sortTableIdx = {
    "_name": "name",
    "_leads": "totalLead",
    "_sale": "totalSoAmount",
    "_new": "new",
    "_approved": "approved",
    "_ear": "ear",
    "_aov": "aov",
    "_rejected": "rejected",
    "_callback": "callback",
    "_uncall": "uncall",
    "_trash": "trash",
    "_urgent": "urgent",
    "_deliver": "delivered",
    "_dsale": "delivAmount",
    "_dr": "delivSaleOrder",
    "_daov": "daov"
}
const TeamTodayReportComponent = (props) => {
    const { dsOrigin, callTeamReport, extractTeam } = props
    const agentTypes = [{ "key": "1", "label": "Fresh-Campaign" }, { "key": "2", "label": "Resell-Campaign" }]
    const [agentType, setagentType] = useState("1")
    const [dsRaw, setdsRaw] = useState({})
    const [ds, setDs] = useState([])
    const [dsSumTotal, setDsSumTotal] = useState([])
    const [toggle, settoggle] = useState({ 0: false })
    const [sortTable, setsortTable] = useState({ "field": "totalLead", "direction": "desc" })

    const getTrClassName = (row) => {
        const indents = ['', 'row-level-1', 'row-level-2', 'row-level-3 cursor-pointer', 'row-level-4']

        return indents[row.key.length]
    }

    const getTdContent = (row, col) => {
        let td = row[col.column]

        if (col.column === "_name") {
            if (row.key.length === 3) {
                td = {
                    "__html": '<span>' + row.name +
                        (toggle[row.key.join("_")] ? '<i class="fa fa-minus table-sm"></i>' : '<i class="fa fa-plus table-sm"></i>') +
                        '</span>'
                }
            }
        }

        return <span dangerouslySetInnerHTML={td}></span>
    }

    const onTrClick = (row) => {
        if (row.key.length === 1) {
            const strKey = row.key.join("_")

            if (toggle[strKey] === undefined || toggle[strKey]) {
                toggle[strKey] = false
            } else {
                toggle[strKey] = true
            }
            settoggle({ ...toggle })
        } else if (row.key.length === 3) {
            const strKey = row.key.join("_")

            if (toggle[strKey]) {
                toggle[strKey] = false
            } else {
                toggle[strKey] = true
            }
            settoggle({ ...toggle })
        }
    }

    const onTableCollapse = (e, ds, opt) => {
        e.preventDefault()

        ds.filter(row => row.key.length === 3)
            .forEach(row => {
                toggle[row.key.join("_")] = opt
            })

        settoggle({ ...toggle })
    }

    const isTrRender = (row) => {
        let rslt = true
        const key = row.key

        if (row.key.length === 4) {
            rslt = toggle[key[0] + "_" + key[1] + "_" + key[2]]
        }

        return rslt
    }

    const onTableSortHandler = (param) => {
        setsortTable(param)
    }

    useEffect(() => {
        if (callTeamReport) {
            Promise.all([callTeamReport({})]).then(resp => {
                const data = resp[0].data
                // console.log(data)
                document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
                setdsRaw([...data])
            })
        }

        if (dsOrigin) {
            setdsRaw(dsOrigin)
        }

    }, [dsOrigin, callTeamReport])



    useEffect(() => {
        let data = dsRaw && dsRaw.filter ? dsRaw.filter(row => !row.cpCat || row.cpCat === parseInt(agentType)) : []
        let myFields
        let sumFields
        const sumRow = { "name": "TOTAL" }

        if (data.length > 0) {
            myFields = Object.keys(data[0])
            sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld) > -1).map(fld => NORM_PROPERTIES[fld])
            data = props.buildHierarchy(data)
            props.sumHierarchy(data)
            props.calcHierarchy(data)

            sumFields.forEach(field => {
                sumRow[field] = 0
            })
            Object.keys(data).forEach(t0 => {
                sumFields.forEach(field => {
                    sumRow[field] += data[t0][field] ? data[t0][field] : 0
                })
            })

            props.calcHierarchyEle(sumRow)
            setDsSumTotal([sumRow])
        } else {
            setDsSumTotal(undefined)
        }
        setDs({ ...data })
    }, [dsRaw, agentType, props])

    return <React.Fragment>
        <div className="row justify-content-end my-3">
            {agentTypes.map(agtType => <button key={agtType.key}
                className={"btn mr-1" + (agentType === agtType.key ? " btn-success" : " btn-secondary")}
                onClick={e => { setagentType(agtType.key) }}
            >{agtType.label}{agtType.type}</button>)}
        </div>

        {[0].map(x => {
            const dsTable = Object.keys(ds).sort((t1, t2) => {
                const sortField = sortTableIdx[sortTable.field]
                const val1 = ds[t1][sortField]
                const val2 = ds[t2][sortField]
                let diff

                if (isNaN(val1) || isNaN(val2)) {
                    diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
                } else {
                    diff = val1 - val2
                }

                return sortTable.direction === "asc" ? diff : diff * -1
            }).map(t0 => {
                const dsRow = { ...ds[t0] }
                delete dsRow.cate

                return dsRow
            })

            return (<TableComponent
                key={x}
                id="tableTeamSum"
                className="mt-2 w-100 table-no-pad mb-5"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_TEAM_TODAY}
                dataSource={dsTable}
                dataSourceTotal={dsTable && dsTable.length > 1 ? dsSumTotal : undefined}
                sort={sortTable}
                onSortHandler={onTableSortHandler}
            >
            </TableComponent>)
        })}


        {Object.keys(ds).sort((t1, t2) => {
            const sortField = sortTableIdx[sortTable.field]
            const val1 = ds[t1][sortField]
            const val2 = ds[t2][sortField]
            let diff

            if (isNaN(val1) || isNaN(val2)) {
                diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
            } else {
                diff = val1 - val2
            }

            return sortTable.direction === "asc" ? diff : diff * -1
        }).map(t0 => {
            const team = ds[t0]
            const _ds = extractTeam(ds, t0, sortTable, sortTableIdx)

            return (<div id={"team" + t0} key={[t0]}>
                <h4 className="mt-2">
                    {team.name}
                    <a className="ml-3 team" href="/#" onClick={e => { e.preventDefault(); onTrClick({ "key": [t0] }) }}>
                        {(toggle[t0] !== undefined && !toggle[t0]) && <i className="fas fa-angle-down"></i>}
                        {(toggle[t0] === undefined || toggle[t0]) && <i className="fas fa-angle-up"></i>}
                    </a>

                    {toggle && (toggle[t0] === undefined || toggle[t0]) && <span className="float-right">
                        <a className="icon-collapse mr-2" href="/#" onClick={e => { onTableCollapse(e, _ds, false) }}><i className="fas fa-minus"></i></a>
                        <a className="icon-collapse" href="/#" onClick={e => { onTableCollapse(e, _ds, true) }}><i className="fas fa-plus"></i></a>
                    </span>}
                </h4>

                {toggle && (toggle[t0] === undefined || toggle[t0]) && <TableComponent
                    tableProps={{ id: "tableTeam" }}
                    className="mt-2 w-100 table-no-pad"
                    theadClassName="big"
                    tbodyClassName=""
                    getTrClassName={getTrClassName}
                    getTdContent={getTdContent}
                    dataSource={_ds}
                    dataset={DS_TEAM_TODAY}
                    isTrRender={isTrRender}
                    onTrClick={onTrClick}
                    sort={sortTable}
                    onSortHandler={onTableSortHandler}>
                </TableComponent>}
            </div>)
        })}
        <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </React.Fragment>
}

export default TeamTodayReportComponent