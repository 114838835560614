import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useParams } from 'react-router'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayByCreatedateStatisticReport, callAgentTodaySumByCreatedateStatisticReport, setAgentTodayByCreatedateStatisticReport } from '../../../services/statisticReports/AgentTodayStatisticReportService'
import { getStorage } from '../../../services/StorageService'
import { getDateFormat, getParseDate, resetWindow } from '../../../services/UtilService'
import { DS_AGENT_TODAY_BY_CREATEDATE_AGENT } from '../../Report/LeadDistToday/LeadDistTodayConfig'

const getAgentRows = (ds) => {
    const flds = ["totalDayLead",
        "newLead",
        "dayApproved",
        "dayDelivered",
        "dayIntransit",
        "dayReturned",
        "dayDelay",
        "dayNew",
        "dayRejected",
        "dayUncall",
        "dayCallback",
        "dayTrash",
        "dayUrgent",
        "daySaleOrder",
        "totalProduct",
        "dayTotalSoAmount",
        "upsellValue",
        "upsellOrder",
        "totalDelivProduct"]
    const arr = ds.map(cp => {
        return cp.todayByCreatedateList.filter(row => row.userId !== undefined)
    }).reduce((acc, cur) => {

        return acc.concat(cur)
    }, []).reduce((acc, cur) => {
        const found = acc.filter(row => row.userId === cur.userId)

        if (found && found.length > 0) {
            flds.forEach(fld => {
                found[0][fld] += cur[fld]
            })
        } else {
            acc.push({ ...cur })
        }

        console.log(acc)

        return acc
    }, [])

    setAgentTodayByCreatedateStatisticReport(arr)

    return arr
}

const LeadDistTodayStatisticReportAgent = (props) => {
    const params = useParams()
    const { creFrom, creTo, prodId, terms, sourceId } = params
    const _title = prodId && terms && sourceId ? getStorage(prodId + terms + sourceId) : undefined
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({
        valid: creFrom && creTo,
        dateFrom: creFrom ? getParseDate(creFrom) : undefined,
        dateTo: creTo ? getParseDate(creTo) : undefined
    })
    const maxDate = new Date()
    const minDate = new Date()

    minDate.setDate(minDate.getDate() - 31)

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    const onSearch = () => {
        const dateFrom = getDateFormat(query.dateFrom)
        const dateTo = getDateFormat(query.dateTo)

        const payload = {
            creFrom: dateFrom,
            creTo: dateTo,
            prodId: prodId,
            terms: terms,
            sourceId: sourceId
        }
        Promise.all([callAgentTodaySumByCreatedateStatisticReport(payload)]).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []

            setDs(getAgentRows(campaigns))
        })
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }


        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    useEffect(() => {
        resetWindow()

        if (params.creFrom && params.creTo) {
            Promise.all([callAgentTodayByCreatedateStatisticReport(params)]).then(resp => {
                const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
                const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
                setDs(getAgentRows(campaigns))
            })
        }
    }, [params])

    return <React.Fragment>
        {_title && <h3 className="mt-3">{_title}</h3>}

        <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-6">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
        </div>
        {query.valid && <div className="form-row justify-content-end mb-3">
            <button className="btn btn-success" onClick={e => onSearch()}>Search</button>
        </div>}


        <TableComponent
            tableProps={{ id: "tableCp" }}
            className="mt-2 w-100 table-no-pad"
            theadClassName="big"
            tbodyClassName="big"
            dataset={DS_AGENT_TODAY_BY_CREATEDATE_AGENT}
            dataSource={ds.sort(sortList)}
            sort={sort}
            onSortHandler={onSortHandler}>
        </TableComponent>
    </React.Fragment>
}

export default LeadDistTodayStatisticReportAgent