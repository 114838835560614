const ROLE_MANAGER = 'director'
const ROLE_ADV = 'adv'
const ROLE_DIRECTOR = 'director'

const ORG_VIETNAM = "vn";
const ORG_VIETNAM2 = "vn2";
const ORG_VIETNAM3 = "vn3";
const ORG_VIETNAM4 = "vn4";
const ORG_VIETNAMID = "vnid";
const ORG_INDONESIA = "id";
const ORG_INDONESIA2 = "id2";
const ORG_INDONESIA3 = "id3";
const ORG_THAILAND = "th";
const ORG_THAILAND1 = "th1";
const ORG_THAILAND2 = "th2";
const ORG_MALAYSIA = "my";
const ORG_PHILIPPINES = "ph";
const ORG_INDIA1 = "in1";

const ORG_ALL = [
    ORG_THAILAND,
    ORG_THAILAND1,
    ORG_THAILAND2,
    ORG_INDONESIA,
    ORG_INDONESIA2,
    ORG_VIETNAM,
    ORG_VIETNAM2,
    ORG_VIETNAM3,
    ORG_VIETNAM4,
    ORG_VIETNAMID,
    ORG_MALAYSIA,
    ORG_PHILIPPINES,
    ORG_INDONESIA3,
    ORG_INDIA1
];
const SIZE_L = "L";
const SIZE_M = "M";
const SIZE_X = "X";

export {
    ROLE_MANAGER,
    ROLE_ADV,
    ROLE_DIRECTOR,
    ORG_VIETNAM,
    ORG_VIETNAM2,
    ORG_VIETNAM3,
    ORG_VIETNAM4,
    ORG_VIETNAMID,
    ORG_INDONESIA,
    ORG_INDONESIA2,
    ORG_INDONESIA3,
    ORG_THAILAND,
    ORG_THAILAND1,
    ORG_THAILAND2,
    ORG_MALAYSIA,
    ORG_PHILIPPINES,
    ORG_INDIA1,
    ORG_ALL,
    SIZE_L,
    SIZE_M,
    SIZE_X
};