import Axios from 'axios'
import { URL_POST_AUTHENTICATE, URL_GET_CUSTOM_CONFIG } from '../config/DomainConfig'
import { getStorage, removeStorage, setStorage } from './StorageService'
import { STORAGE_CUSTOM_CONFIG, STORAGE_JWT, STORAGE_LEAD_STATUSES, STORAGE_PERMISSION, STORAGE_ROLE, STORAGE_USERNAME } from '../config/StorageConfig'
import { ORG_ALL } from "../config/CommonConfig"

const getJwtToken = () => {
    const jwt = getStorage(STORAGE_JWT)

    return !jwt || jwt === 'undefined' ? undefined : jwt
}
const getPermissions = () => {
    const perm = getStorage(STORAGE_PERMISSION)
    return perm ? perm : []
}
const setJwtToken = (jwt, username, permissions, customConfig, role) => {
    setStorage(STORAGE_JWT, jwt)
    setStorage(STORAGE_USERNAME, username)
    setStorage(STORAGE_PERMISSION, JSON.stringify(permissions))
    setStorage(STORAGE_CUSTOM_CONFIG, JSON.stringify(customConfig))
    setStorage(STORAGE_ROLE, role)
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
}

const resetJwtToken = () => {
    removeStorage(STORAGE_JWT)
    removeStorage(STORAGE_USERNAME)
    removeStorage(STORAGE_PERMISSION)
    removeStorage(STORAGE_LEAD_STATUSES)
    removeStorage(STORAGE_ROLE)
}

const callPostAuthenticate = async payload => {
    const resp = await Axios.post(URL_POST_AUTHENTICATE(), payload)
    const token = resp.data.jwttoken
    const username = resp.data.username
    const permissions = ["/"]
    const role = resp.data?.role
    let geoCode
    // permissions.push('/report/leadDistTodayV2/th')
    // permissions.push('/statisticReport/leadDistTodayV2/th')
    // permissions.push('/statisticReport/campaigOverviewV3/th')
    
    
    resp.data.permissions.forEach(row => {
        geoCode = row.geoCode.toLowerCase()

        permissions.push(ORG_ALL.indexOf(geoCode) > -1 ? row.pagePath + "/" + geoCode : row.pagePath);

        if (permissions.indexOf(geoCode) === -1) {
            permissions.push(geoCode)
        }
    })
// console.log(permissions)
    return { token, username, permissions, role }
}

const callGetCustomConfig = async () => {
    let rslt = {}
    const resp = await Axios.get(URL_GET_CUSTOM_CONFIG())

    if (resp.status === 200 && resp.data) {
        rslt = resp.data
    }

    return rslt
}

export { getJwtToken, getPermissions, setJwtToken, resetJwtToken, callPostAuthenticate, callGetCustomConfig }