import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useParams } from 'react-router'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayByCreatedateStatisticReport, callAgentTodaySumByCreatedateStatisticReport } from '../../../services/statisticReports/AgentTodayStatisticReportService'
import { getStorage } from '../../../services/StorageService'
import { getDateFormat, getDateStrFormat, getNumberFixed, getNumberWithCommas, getParseDate, getValWithPercentHtml, resetWindow, convertTime } from '../../../services/UtilService'
import { DS_AGENT_TODAY_BY_CREATEDATE_STATISTIC } from '../../Report/LeadDistToday/LeadDistTodayConfig'
import "./LeadDistTodayStatistic.css"

const getTotalDs = (ds, sortFn) => {
    let rslt = []

    if (ds && ds.length > 0) {
        rslt = ds.map((cp, cpIdx) => {
            const trTot = cp.todayByCreatedateList.filter(tr => tr.trClassName === "total")[0]
            const trCp = {}

            if (trTot) {
                Object.keys(trTot).forEach(fld => {
                    trCp[fld] = trTot[fld]
                })
                trCp["_name"] = { "__html": (cpIdx + 1) + ". " + cp.name }
                trCp["trClassName"] = ""
            }

            return trCp
        }).sort(sortFn)

    }

    if (rslt.length > 0) {
        const tot = {
            totalDayLead: 0,
            dayNew: 0,
            dayApproved: 0,
            dayRejected: 0,
            dayTrash: 0,
            dayUrgent: 0,
            dayDelivered: 0,
            dayCallback: 0,
            dayUncall: 0,
            dayTotalSoAmount: 0,
            dayDelivSaleOrder: 0,
            dayDelivAmount: 0,
            leadCreatedDate: undefined,
            dayAvgUncallRingingTime: 0,
            dayTotalCall: 0,
            dayAvgTalkTime: 0,
            dayTotalOverdueCallback: 0,
            dayAvgUncallCall: 0,
        }

        let arrUncallAvgAtt = [];
        let arrUncallAvgRing = [];
        let arrAvgtalktime = [];

        rslt.forEach(_en => {
            const _totalDayLead = _en.totalDayLead
            const _dayNew = _en.dayNew
            const _dayApproved = _en.dayApproved
            const _dayRejected = _en.dayRejected
            const _dayTrash = _en.dayTrash
            const _dayUrgent = _en.dayUrgent
            const _dayDelivered = _en.dayDelivered
            const _dayCallback = _en.dayCallback
            const _dayUncall = _en.dayUncall
            const _dayTotalSoAmount = _en.dayTotalSoAmount
            const _dayDelivSaleOrder = _en.dayDelivSaleOrder
            const _dayDelivAmount = _en.dayDelivAmount
            const _leadCreatedDate = _en.leadCreatedDate
            const _uncallAvgAtt = _en._uncallAvgAtt.__html
            const _uncallAvgRing = _en._uncallAvgRing.avgTime
            const _totalcalls = _en.dayTotalCall
            const _avgtalktime = _en._avgtalktime.avgTime
            const _odcb = _en.dayTotalOverdueCallback
            
            // const _aov = _dayTotalSoAmount / _dayApproved
            // const _daov = _dayDelivAmount / _dayDelivSaleOrder
            // const _usr = _en.userName.split("@")
            // const _teamName = _en.teamName ? _en.teamName : "No team"
            tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
            tot.dayNew += _dayNew ? _dayNew : 0
            tot.dayApproved += _dayApproved ? _dayApproved : 0
            tot.dayRejected += _dayRejected ? _dayRejected : 0
            tot.dayTrash += _dayTrash ? _dayTrash : 0
            tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
            tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
            tot.dayCallback += _dayCallback ? _dayCallback : 0
            tot.dayUncall += _dayUncall ? _dayUncall : 0
            tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
            tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
            tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0
            tot.leadCreatedDate = _leadCreatedDate &&
                (tot.leadCreatedDate === undefined || _leadCreatedDate.localeCompare(tot.leadCreatedDate) > 0) ?
                _leadCreatedDate : tot.leadCreatedDate

            arrUncallAvgAtt.push(parseFloat(_uncallAvgAtt))
            arrUncallAvgRing.push(_uncallAvgRing)
            arrAvgtalktime.push(_avgtalktime)
            arrUncallAvgAtt = arrUncallAvgAtt.filter(function(value, index, arr) {
                return value >= 1;
            })
            arrUncallAvgRing = arrUncallAvgRing.filter(function(value, index, arr) {
                return value >= 1;
            })
            arrAvgtalktime = arrAvgtalktime.filter(function(value, index, arr) {
                return value >= 1;
            })

            tot.dayAvgUncallCall += _uncallAvgAtt ? _uncallAvgAtt : 0
            tot.dayAvgUncallRingingTime += _uncallAvgRing ? _uncallAvgRing : 0
            tot.dayTotalCall += _totalcalls ? _totalcalls : 0
            tot.dayAvgTalkTime += _avgtalktime ? _avgtalktime : 0
            tot.dayTotalOverdueCallback += _odcb ? _odcb : 0
        })

        let sum = 0;
        let suma = 0;
        let sumd = 0;
        arrUncallAvgAtt.forEach(function(element){
            sum += element;
        });
        arrUncallAvgRing.forEach(function(element){
            suma += element;
        });
        arrAvgtalktime.forEach(function(element){
            sumd += element;
        });

        rslt.push({
            "_name": { "__html": "TOTAL" },
            "_team": { "__html": "" },
            "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) + "" },
            "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
            "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
            "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
            "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
            "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
            "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
            "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
            "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
            "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
            "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
            "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
            "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
            "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
            "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
            "_leadCreatedDate": { "__html": tot.leadCreatedDate ? getDateStrFormat(tot.leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" },
            "trClassName": "total",
            "_uncallAvgAtt": { "__html": sum === 0 ? "0" : (sum / arrUncallAvgAtt.length).toFixed(1) + "" },
            "_uncallAvgRing": { "__html": suma === 0 ? "0s" : convertTime((suma / arrUncallAvgRing.length)) },
            "_totalcalls": { "__html": tot.dayTotalCall + "" },
            "_avgtalktime": { "__html": sumd === 0 ? "0s" : convertTime((sumd / arrAvgtalktime.length)) },
            "_odcb": { "__html": tot.dayTotalOverdueCallback + "" },
        })
    }

    return rslt
}
const LeadDistTodayStatisticReportCampaign = (props) => {
    const params = useParams()
    const { creFrom, creTo, prodId, terms, sourceId } = params
    const _title = prodId && terms && sourceId ? getStorage(prodId + terms + sourceId) : undefined
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [ds, setDs] = useState([])
    const [query, setquery] = useState({
        valid: creFrom && creTo,
        dateFrom: creFrom ? getParseDate(creFrom) : undefined,
        dateTo: creTo ? getParseDate(creTo) : undefined
    })
    const maxDate = new Date()
    const minDate = new Date()

    minDate.setDate(minDate.getDate() - 31)

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    const onSearch = () => {
        const dateFrom = getDateFormat(query.dateFrom)
        const dateTo = getDateFormat(query.dateTo)

        const payload = {
            creFrom: dateFrom,
            creTo: dateTo,
            prodId: prodId,
            terms: terms,
            sourceId: sourceId
        }
        Promise.all([callAgentTodaySumByCreatedateStatisticReport(payload)]).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
            setDs(campaigns)
        })
    }

    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }


        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else if (sort.field === "_leadCreatedDate") {
            val1 = val1.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            val2 = val2.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        }
        else if (sort.field === "_uncallAvgRing" || sort.field === "_avgtalktime") {
            val1 = val1.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            val2 = val2.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    useEffect(() => {
        resetWindow()

        if (params.creFrom && params.creTo) {
            Promise.all([callAgentTodayByCreatedateStatisticReport(params)]).then(resp => {
                const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
                const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
                setDs(campaigns)
            })
        }
    }, [params])

    return <React.Fragment>
        {_title && <h3 className="mt-3">{_title}</h3>}

        <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-6">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
        </div>
        {query.valid && <div className="form-row justify-content-end mb-3">
            <button className="btn btn-success" onClick={e => onSearch()}>Search</button>
        </div>}

        {ds && ds.length > 0 && <div className="mb-3 table-Wrap">
            <TableComponent
                tableProps={{ id: "tableSummary" }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_AGENT_TODAY_BY_CREATEDATE_STATISTIC}
                dataSource={getTotalDs(ds.filter(cp => cp.cpCat === 1), sortList)}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>}

        {ds && ds.filter(cp => cp.cpCat === 1).map((cp, idx) => (<div key={cp.id}>
            <h4 className="mt-5">
                {ds.indexOf(cp) + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <div className='table-Wrap'>
                <TableComponent
                    tableProps={{ id: "tableCp" + cp.id }}
                    className="mt-2 w-100 table-no-pad"
                    theadClassName="big"
                    tbodyClassName="big"
                    dataset={DS_AGENT_TODAY_BY_CREATEDATE_STATISTIC}
                    dataSource={cp.todayByCreatedateList.sort(sortList)}
                    sort={sort}
                    onSortHandler={onSortHandler}>
                </TableComponent>
            </div>
        </div>))}
    </React.Fragment>
}

export default LeadDistTodayStatisticReportCampaign