import Axios from "axios"
import {
    URL_POST_REPORT_PUB_PERFORMANCE,
    URL_POST_REPORT_PUB_PERFORMANCE_MARGIN,
    URL_POST_REPORT_PUB_PERFORMANCE_MARGIN_RELOAD,
    URL_POST_REPORT_PUB_PERFORMANCE_TOTAL
} from "../../config/DomainConfig"
import { getNumberFixed } from "../UtilService";

const callPostReportPubPerformance = async payload => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE(),
        payload)
    const idx = {}
    let prodName;
    let terms;
    let _created
    // let sourceId;

    if ((resp && resp.data && resp.data.pubPerformanceEntities) || (resp && resp.data && resp.data.mapOfferToMrpData)) {
        _created = resp.data.created
        resp.data.pubPerformanceEntities.forEach(row => {
            if (row && row != null) {
                prodName = row.prodName // group by prodName
                terms = row.terms
                // sourceId = row.sourceId
                if (!idx[prodName]) {
                    idx[prodName] = {}
                }

                if (!idx[prodName][terms]) {
                    idx[prodName][terms] = {
                        "prodId": row.prodId,
                        "leads": {},
                        "amount": {},
                        "leadNew": {},
                        "approved": {},
                        "uncalled": {},
                        "rejected": {},
                        "validated": {},
                        "validatedPb": {},
                        "validatedPbPct": {},
                        "callback": {},
                        "urgent": {},
                        "trash": {},
                        "name": {},
                        "cp": {},
                        "pub": {},
                        "sources": {},
                        "attempt": {},
                        "avgUncalledClosedCall": 0,
                        "splActualLocal": row.splActualLocal,
                        "splMrpLocal": row.splMrpLocal,
                        "runCPL": row.runCPL
                    }
                    idx[prodName][terms].name[terms] = row.prodName
                    idx[prodName][terms].cp[terms] = row.cpName
                    // idx[prodName].pub[sourceId] = row.pub
                    if(resp.data.mapOfferToMrpData?.hasOwnProperty(row.prodName)){
                        idx[prodName][terms].leadMrp = resp.data.mapOfferToMrpData[row.prodName]?.leadMrp.toString()
                        idx[prodName][terms].arMrp = getNumberFixed(resp.data.mapOfferToMrpData[row.prodName]?.arMrp * 100, 0)
                        idx[prodName][terms].isArMrpMovedUp = resp.data.mapOfferToMrpData[row.prodName]?.isArMrpMovedUp
                    }


                    idx[prodName][terms].leads[terms] = 0
                    idx[prodName][terms].amount[terms] = 0
                    idx[prodName][terms].leadNew[terms] = 0
                    idx[prodName][terms].approved[terms] = 0
                    idx[prodName][terms].uncalled[terms] = 0
                    idx[prodName][terms].rejected[terms] = 0
                    idx[prodName][terms].validated[terms] = 0
                    idx[prodName][terms].validatedPb[terms] = 0
                    idx[prodName][terms].validatedPbPct[terms] = 0
                    idx[prodName][terms].callback[terms] = 0
                    idx[prodName][terms].urgent[terms] = 0
                    idx[prodName][terms].trash[terms] = 0

                    idx[prodName][terms].sources[terms] = []
                    idx[prodName][terms].attempt[terms] = []
                }

                idx[prodName][terms].leads[terms] += row.leads ? row.leads : 0
                idx[prodName][terms].amount[terms] += row.amount ? row.amount : 0
                idx[prodName][terms].leadNew[terms] += row.leadNew ? row.leadNew : 0
                idx[prodName][terms].approved[terms] += row.approved ? row.approved : 0
                idx[prodName][terms].uncalled[terms] += row.uncalled ? row.uncalled : 0
                idx[prodName][terms].rejected[terms] += row.rejected ? row.rejected : 0
                idx[prodName][terms].validated[terms] += row.validated ? row.validated : 0
                idx[prodName][terms].validatedPb[terms] += row.validatedPb ? row.validatedPb : 0
                idx[prodName][terms].validatedPbPct[terms] += row.validatedPbPct ? row.validatedPbPct : 0
                idx[prodName][terms].callback[terms] += row.callback ? row.callback : 0
                idx[prodName][terms].urgent[terms] += row.urgent ? row.urgent : 0
                idx[prodName][terms].trash[terms] += row.trash ? row.trash : 0
                idx[prodName][terms].avgUncalledClosedCall += row.avgUncalledClosedCall
                if(row.avgUncalledClosedCall > 0) {
                    idx[prodName][terms].attempt[terms].push(row)
                }
                idx[prodName][terms].sources[terms].push(row)
            }
        })
    }

    return {
        created: _created,
        pubPerformanceEntities: [].concat(
            ...Object.keys(idx).map(pid => {
                return Object.keys(idx[pid]).map(tid => idx[pid][tid])
            })
        ).sort((a, b) => {
            return Object.keys(b.leads).map(terms => b.leads[terms]).reduce((x, y) => Math.max(x, y)) -
                Object.keys(a.leads).map(terms => a.leads[terms]).reduce((x, y) => Math.max(x, y))
        })
    }
}

const callPostReportPubProdPerformance = async payload => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE(),
        payload)
    const idx = {}
    let prodId;
    let terms;
    let _created
    // let sourceId;

    if (resp && resp.data && resp.data.pubPerformanceEntities) {
        _created = resp.data.created
        resp.data.pubPerformanceEntities.forEach(row => {
            if (row && row != null) {
                prodId = row.prodId
                terms = row.terms
                // sourceId = row.sourceId
                if (!idx[prodId]) {
                    idx[prodId] = {}
                }

                if (!idx[prodId][terms]) {
                    idx[prodId][terms] = {
                        "prodId": prodId,
                        "leads": {},
                        "amount": {},
                        "leadNew": {},
                        "approved": {},
                        "uncalled": {},
                        "rejected": {},
                        "validated": {},
                        "trash": {},
                        "name": {},
                        "pub": {},
                        "sources": {}
                    }
                    idx[prodId][terms].name[terms] = row.prodName
                    // idx[prodId].pub[sourceId] = row.pub


                    idx[prodId][terms].leads[terms] = 0
                    idx[prodId][terms].amount[terms] = 0
                    idx[prodId][terms].leadNew[terms] = 0
                    idx[prodId][terms].approved[terms] = 0
                    idx[prodId][terms].uncalled[terms] = 0
                    idx[prodId][terms].rejected[terms] = 0
                    idx[prodId][terms].validated[terms] = 0
                    idx[prodId][terms].trash[terms] = 0

                    idx[prodId][terms].sources[terms] = []
                }

                idx[prodId][terms].leads[terms] += row.leads ? row.leads : 0
                idx[prodId][terms].amount[terms] += row.amount ? row.amount : 0
                idx[prodId][terms].leadNew[terms] += row.leadNew ? row.leadNew : 0
                idx[prodId][terms].approved[terms] += row.approved ? row.approved : 0
                idx[prodId][terms].uncalled[terms] += row.uncalled ? row.uncalled : 0
                idx[prodId][terms].rejected[terms] += row.rejected ? row.rejected : 0
                idx[prodId][terms].validated[terms] += row.validated ? row.validated : 0
                idx[prodId][terms].trash[terms] += row.trash ? row.trash : 0

                idx[prodId][terms].sources[terms].push(row)
            }
        })
    }

    return {
        created: _created,
        pubPerformanceEntities: [].concat(
            ...Object.keys(idx).map(pid => {
                return Object.keys(idx[pid]).map(tid => idx[pid][tid])
            })
        ).sort((a, b) => {
            return Object.keys(b.leads).map(terms => b.leads[terms]).reduce((x, y) => Math.max(x, y)) -
                Object.keys(a.leads).map(terms => a.leads[terms]).reduce((x, y) => Math.max(x, y))
        })
    }
}

const callPostReportPubPerformanceGroupByProd = async payload => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE(),
        payload)
    const idx = {}
    let prodId
    let terms
    // let sourceId;

    if (resp && resp.data) {
        resp.data.forEach(row => {
            if (row && row != null) {
                prodId = row.prodId
                terms = row.terms
                // sourceId = row.sourceId
                if (!idx[prodId]) {
                    idx[prodId] = {
                        "prodId": prodId,
                        "leads": {},
                        "amount": {},
                        "leadNew": {},
                        "approved": {},
                        "uncalled": {},
                        "rejected": {},
                        "validated": {},
                        "trash": {},
                        "name": {},
                        "pub": {},
                        "sources": {}
                    }
                }

                if (!idx[prodId].sources[terms]) {
                    idx[prodId].name[terms] = row.prodName
                    // idx[prodId].pub[sourceId] = row.pub


                    idx[prodId].leads[terms] = 0
                    idx[prodId].amount[terms] = 0
                    idx[prodId].leadNew[terms] = 0
                    idx[prodId].approved[terms] = 0
                    idx[prodId].uncalled[terms] = 0
                    idx[prodId].rejected[terms] = 0
                    idx[prodId].validated[terms] = 0
                    idx[prodId].trash[terms] = 0

                    idx[prodId].sources[terms] = []
                }

                idx[prodId].leads[terms] += row.leads ? row.leads : 0
                idx[prodId].amount[terms] += row.amount ? row.amount : 0
                idx[prodId].leadNew[terms] += row.leadNew ? row.leadNew : 0
                idx[prodId].approved[terms] += row.approved ? row.approved : 0
                idx[prodId].uncalled[terms] += row.uncalled ? row.uncalled : 0
                idx[prodId].rejected[terms] += row.rejected ? row.rejected : 0
                idx[prodId].validated[terms] += row.validated ? row.validated : 0
                idx[prodId].trash[terms] += row.trash ? row.trash : 0

                idx[prodId].sources[terms].push(row)
            }
        })
    }

    return Object.keys(idx).map(pid => idx[pid]).sort((a, b) => {
        return Object.keys(b.leads).map(terms => b.leads[terms]).reduce((x, y) => Math.max(x, y)) -
            Object.keys(a.leads).map(terms => a.leads[terms]).reduce((x, y) => Math.max(x, y))
    })
}

const callPostReportPubPerformanceTotal = async () => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE_TOTAL())
    return resp ? resp.data : undefined
}

const callPostReportPubPerformanceMargin = async () => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE_MARGIN())
    let rules = []

    if (resp && resp.data) {
        rules = resp.data
    }

    return rules
}
const callPostReportPubPerformanceMarginReload = async () => {
    const resp = await Axios.post(URL_POST_REPORT_PUB_PERFORMANCE_MARGIN_RELOAD())

    return resp
}

const mergePubSource = (rows, pubs) => {
    let rslt = rows.filter(row => pubs.indexOf(row.pubId) === -1)
    pubs.forEach(pub => {
        const pubRows = rows.filter(row=>row.pubId === pub)

        if(pubRows.length > 0) {
            const leads = pubRows.reduce((acc, cur)=>{return acc + cur.leads}, 0)
            const amount = pubRows.reduce((acc, cur)=>{return acc + cur.amount}, 0)
            const ele = {
                sourceId: pubRows[0].sourceId,
                pub: pub,
                leads: leads
            }
            const flds = ["approved", "validated", "rejected", 
            "duplicated", "trash", "uncalled", 
            "callback", "urgent"]
            
            flds.forEach(fld => {
                const tot = pubRows.reduce((acc, cur)=>{return acc + cur[fld]}, 0)

                ele[fld] = tot
                ele[fld + "Pct"] = tot / leads
             })
            let countAvg = 0
            const totalAvg = pubRows.reduce((acc, cur)=>{
                if(cur.avgUncalledClosedCall > 0) {
                    countAvg++
                }
                return acc + cur.avgUncalledClosedCall
            }, 0)
            ele.aov = ele.approved > 0 ? amount / ele.approved : 0
            ele.avgUncalledClosedCall = totalAvg / countAvg
            rslt = rslt.concat(ele)

            // console.log([rslt, ele])
        }
        
    })

    return rslt
}

export {
    callPostReportPubPerformance,
    callPostReportPubProdPerformance,
    callPostReportPubPerformanceGroupByProd,
    callPostReportPubPerformanceMargin,
    callPostReportPubPerformanceMarginReload,
    callPostReportPubPerformanceTotal,
    mergePubSource
}