import Axios from "axios";
import {
    URL_GET_STATISTIC_REPORT_NETWORK_PERFORMANCE
} from "../../config/DomainConfig"

const callGetStatisticReportNetworkPerformance = async (dateFrom, dateTo) => {
    const resp = await Axios.get(URL_GET_STATISTIC_REPORT_NETWORK_PERFORMANCE() + "/" + dateFrom + "/" + dateTo)
    const idx = {}
    let prodId;
    let terms;
    let _created
    // let sourceId;

    if (resp && resp.data && resp.data.pubPerformanceEntities) {
        _created = resp.data.created
        resp.data.pubPerformanceEntities.forEach(row => {
            if (row && row != null) {
                prodId = row.prodId
                terms = row.terms
                // sourceId = row.sourceId
                if (!idx[prodId]) {
                    idx[prodId] = {}
                }

                if (!idx[prodId][terms]) {
                    idx[prodId][terms] = {
                        "prodId": prodId,
                        "leads": {},
                        "amount": {},
                        "leadNew": {},
                        "approved": {},
                        "uncalled": {},
                        "rejected": {},
                        "validated": {},
                        "validatedPb": {},
                        "trash": {},
                        "name": {},
                        "pub": {},
                        "sources": {}
                    }
                    idx[prodId][terms].name[terms] = row.prodName
                    // idx[prodId].pub[sourceId] = row.pub


                    idx[prodId][terms].leads[terms] = 0
                    idx[prodId][terms].amount[terms] = 0
                    idx[prodId][terms].leadNew[terms] = 0
                    idx[prodId][terms].approved[terms] = 0
                    idx[prodId][terms].uncalled[terms] = 0
                    idx[prodId][terms].rejected[terms] = 0
                    idx[prodId][terms].validated[terms] = 0
                    idx[prodId][terms].validatedPb[terms] = 0
                    idx[prodId][terms].trash[terms] = 0

                    idx[prodId][terms].sources[terms] = []
                }

                idx[prodId][terms].leads[terms] += row.leads ? row.leads : 0
                idx[prodId][terms].amount[terms] += row.amount ? row.amount : 0
                idx[prodId][terms].leadNew[terms] += row.leadNew ? row.leadNew : 0
                idx[prodId][terms].approved[terms] += row.approved ? row.approved : 0
                idx[prodId][terms].uncalled[terms] += row.uncalled ? row.uncalled : 0
                idx[prodId][terms].rejected[terms] += row.rejected ? row.rejected : 0
                idx[prodId][terms].validated[terms] += row.validated ? row.validated : 0
                idx[prodId][terms].validatedPb[terms] += row.validatedPb ? row.validatedPb : 0
                idx[prodId][terms].trash[terms] += row.trash ? row.trash : 0

                idx[prodId][terms].sources[terms].push(row)
            }
        })
    }

    return {
        created: _created,
        pubPerformanceEntities: [].concat(
            ...Object.keys(idx).map(pid => {
                return Object.keys(idx[pid]).map(tid => idx[pid][tid])
            })
        ).sort((a, b) => {
            return Object.keys(b.leads).map(terms => b.leads[terms]).reduce((x, y) => Math.max(x, y)) -
                Object.keys(a.leads).map(terms => a.leads[terms]).reduce((x, y) => Math.max(x, y))
        })
    }
}

export {
    callGetStatisticReportNetworkPerformance
}