import React, { useEffect } from 'react'
import { resetWindow } from '../../../services/UtilService'
import DailyStatisticReport from './DailyStatisticReport'

const ByModifiedDateStatisticReport = (props) => {

    useEffect(() => {
        resetWindow()
    }, [])

    return <DailyStatisticReport dateType="modifydate">

    </DailyStatisticReport>
}

export default ByModifiedDateStatisticReport