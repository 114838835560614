import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import Login from './page/Login/Login.js'

import AuthContent from './AuthContent'
import Axios from 'axios'
import { getJwtToken } from './services/AuthenticateService.js'
import { getStorage, setStorage } from './services/StorageService.js'
import { STORAGE_DOMAIN_IDX, STORAGE_MENU_ORG, STORAGE_THRESHOLD_LAST_FRESH } from './config/StorageConfig.js'
import { ORG_ALL, ORG_THAILAND } from './config/CommonConfig.js'

function App() {
  const jwt = getJwtToken()
  const domainIdx = getStorage(STORAGE_DOMAIN_IDX)
  const thresholdLastFresh = getStorage(STORAGE_THRESHOLD_LAST_FRESH)

  if (jwt && domainIdx !== undefined) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  } else {
    if (window.location.pathname !== '/login') {
      window.location = '/login'
    }
  }

  if (!thresholdLastFresh || thresholdLastFresh === 'undefined') {
    setStorage(STORAGE_THRESHOLD_LAST_FRESH, process.env.REACT_APP_THRESHOLD_LAST_FRESH)
  }

  Axios.interceptors.response.use(response => {
    const BODY = document.getElementsByTagName("BODY")[0]
    BODY.className = BODY.className.replaceAll(" ajax-loading", "")

    return response
  }, error => {
    const BODY = document.getElementsByTagName("BODY")[0]
    BODY.className = BODY.className.replaceAll(" ajax-loading", "")

    if (error.response && error.response.status === 401) {
      // history.push('/login');
      window.location = '/login'
    }
  })

  Axios.interceptors.request.use((config) => {
    const BODY = document.getElementsByTagName("BODY")[0]
    BODY.className = BODY.className + " ajax-loading"

    const menu = getStorage(STORAGE_MENU_ORG)
    const paths = window.location.pathname.split('/')
    const validOrg = ORG_ALL;
    let org = ORG_THAILAND
    
    for (let i = 0; i < paths.length; i++) {
      if (validOrg.indexOf(paths[i]) > -1) {
        org = paths[i]
        break;
      }
    }

    if (paths.indexOf('login') === -1) {
      config.headers.common['Org'] = validOrg.indexOf(org) > -1 ? org : ORG_THAILAND
    } else {
      config.headers.common['Org'] = Object.keys(menu)[0]
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  return (<Suspense fallback={<div>Loading...</div>}>
    <Route exact path="/login"><Login></Login></Route>
    {jwt && <AuthContent></AuthContent>}
    <div className="loading">
      <div className="loading-center">
        <div className="loading-content">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </Suspense>);
}

export default App;
