import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callPostHSReportDealHistory } from '../../../services/hsReports/DealHistoryHSReportService'
import { getDateFormat, resetWindow } from '../../../services/UtilService'
export default (props) => {
    const iniDataSource = { "columns": { "columns": [] }, "data": [] }
    const [dataSource, setDataSource] = useState(iniDataSource)
    const [query, setquery] = useState({
        dateFrom: undefined,
        dateTo: undefined
    })

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    useEffect(() => {
        if (query.dateFrom && query.dateTo) {
            let callFns = [callPostHSReportDealHistory(getDateFormat(query.dateFrom), getDateFormat(query.dateTo))]

            Promise.all(
                callFns
            ).then(rest => {
                console.log(rest[0])
                setDataSource({ ...rest[0] })

            })
        }
    }, [query])

    useEffect(() => {
    }, [dataSource])

    useEffect(() => {
        resetWindow()
    }, [])

    return (<div className="card mb-4">
    <div className="form-row">
        <div className="form-group col-md-6">
            <label htmlFor="fltDateFrom">Date From</label>
            <div className="form-control border-0">
                <ReactDatePicker id="fltDateFrom"
                    name="dateFrom"
                    selected={query.dateFrom}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                    className="form-control"></ReactDatePicker>
            </div>
        </div>
        <div className="form-group col-md-6">
            <label htmlFor="fltDateTo">Date To</label>
            <div className="form-control border-0">
                <ReactDatePicker id="fltDateTo"
                    name="dateTo"
                    selected={query.dateTo}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                    className="form-control"></ReactDatePicker>
            </div>
        </div>
    </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-12 scroll-x">
                            <TableComponent className="mt-2 w-100"
                                theadClassName="big"
                                tbodyClassName="big"
                                dataset={dataSource.columns}
                                dataSource={dataSource.data}>
                            </TableComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"><div id="redisDateSum" className="col-12 small text-right"></div></div>
            <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
        </div>
    </div>)
}
