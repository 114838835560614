import React from 'react'
import { NavLink } from 'react-router-dom'

const SideNavMenuComponent = (props) => {
    const { checkPermission, callApi, allow, permissions, children, path, menuType, mnuOrg } = props
    const getSingleMenu = () => {
        return (menuType && menuType === "api" ?
            (<a className="nav-link" href="/#" onClick={() => { callApi(props.path, props.text) }}>
                <div className="sb-nav-link-icon"><i className={props.className}></i></div>
                <span>{props.text}</span>
            </a>) :
            (<NavLink key={props.path} to={path} className="nav-link" activeClassName="active">
                <span>{props.text}</span>
            </NavLink>))
    }

    const getHierarchyMenus = () => {
        if (mnuOrg[props.id] === undefined) {
            mnuOrg[props.id] = true
        }

        return (<React.Fragment>
            <a href={'/#' + props.id} className="nav-link" onClick={e => props.toggleMenu(e, props.id)}>
                <div className="sb-nav-link-icon"><i className={props.className}></i></div>
                <span>{props.text}</span>
                {mnuOrg[props.id] && <i className="fa fa-angle-up ml-3"></i>}
                {!mnuOrg[props.id] && <i className="fa fa-angle-down ml-3"></i>}
            </a>
            {mnuOrg[props.id] && <div className="collapse show">
                <nav className="sb-sidenav-menu-nested nav">
                    {children.map(child => <SideNavMenuComponent
                        key={child.id}
                        id={child.id}
                        checkPermission={checkPermission}
                        callApi={callApi}
                        permissions={permissions}
                        mnuOrg={mnuOrg}
                        toggleMenu={props.toggleMenu}
                        {...child}>
                    </SideNavMenuComponent>)}
                </nav>
            </div>}
        </React.Fragment>)
    }

    return (<React.Fragment>
        {checkPermission(allow, permissions, path, children) ? <React.Fragment>
            {children && children.length > 0 ? getHierarchyMenus() : getSingleMenu()}
        </React.Fragment> : <React.Fragment></React.Fragment>}
    </React.Fragment>)
}

export default SideNavMenuComponent