import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callPostHSReportCampaignOverview } from '../../../services/hsReports/CampaignOverviewHSReportService'
import { getCurrentDateStr, resetWindow } from '../../../services/UtilService'
import { DS_CAMPAIGN_OVERVIEW } from './CampaignOverviewHSReportDataset'
export default (props) => {
    const iniFilter = { "campaign": "A" }
    const iniDataSource = { "campaign": [], "topFresh": [], "topResell": [] }
    const [filter] = useState(iniFilter)
    const [dataSource, setDataSource] = useState(iniDataSource)

    useEffect(() => {
        let qDate = new URLSearchParams(window.location.search).get("date")
        let callFns = []

        if (qDate) {
            callFns = [
                callPostHSReportCampaignOverview({ "date": qDate, "leadType": filter.campaign })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "A" })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "M" })
            ]
        } else {
            qDate = getCurrentDateStr()
            callFns = [
                callPostHSReportCampaignOverview({ "date": qDate, "leadType": filter.campaign })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "A" })
                // , callPostReportCampaignTopAgent({ "date": qDate, "leadType": "M" })
            ]
        }

        Promise.all(
            callFns
        ).then(rest => {
            const dsCampaign = rest[0]
                setDataSource({
                    "campaign": dsCampaign.campaignOverviewEntities ? dsCampaign.campaignOverviewEntities : []
                })

        })
    }, [filter])

    useEffect(() => {
    }, [dataSource])

    useEffect(() => {
        resetWindow()
    }, [])

    return (<div className="card mb-4">
        <div className="card-body">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-12 scroll-x">
                            <TableComponent className="mt-2 w-100"
                                theadClassName="big"
                                tbodyClassName="big"
                                dataset={DS_CAMPAIGN_OVERVIEW}
                                dataSource={
                                    dataSource.campaign.sort((a, b) => b.totalLeads - a.totalLeads)
                                }>
                            </TableComponent>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-3">
                        <label className="small">Top {dataSource.topFresh.length} Agents(fresh)</label>

                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_FRESH} dataSource={dataSource.topFresh}></TableComponent>
                            </div>
                        </div>

                        <label className="small mt-5">Top {dataSource.topResell.length} Agents(resell)</label>
                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_RESELL} dataSource={dataSource.topResell}></TableComponent>
                            </div>
                        </div>
                    </div> */}
            </div>

            <div className="row"><div id="redisDateSum" className="col-12 small text-right"></div></div>
            <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
        </div>
    </div>)
}
