const setStorage = (key, value) => {
    const serialized = typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, serialized)
}
const getStorage = (key) => {
    const serialized = localStorage.getItem(key)
    let value = serialized

    try {
        if (serialized) {
            value = JSON.parse(serialized)
        } else {
            value = undefined
        }
    } catch (e) { }

    return value
}

const removeStorage = (key) => {
    localStorage.removeItem(key)
}

export {
    setStorage,
    getStorage,
    removeStorage
}