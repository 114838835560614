import Axios from "axios"
import { URL_POST_STATISTIC_REPORT_AGENT_TODAY, URL_POST_STATISTIC_REPORT_AGENT_TODAY_SUM, URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE } from "../../config/DomainConfig"
import { getDateStrFormat, getNumberFixed, getNumberWithCommas, getValWithPercentHtml, isDecimal, convertTime } from "../UtilService"

const callAgentTodayByCreatedateStatisticReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_STATISTIC_REPORT_AGENT_TODAY(), {
        creFrom: payload.creFrom,
        creTo: payload.creTo,
        prodId: payload.prodId === "0" ? undefined : payload.prodId,
        terms: payload.terms === "0" ? undefined : payload.terms,
        sourceId: payload.sourceId === "0" ? undefined : payload.sourceId,
        isShift: payload.isShift
    })

    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data

        data.reportInsgAgentResponse.campaigns.forEach(_cp => {
            setAgentTodayByCreatedateStatisticReport(_cp.todayByCreatedateList)
        })
    }

    return data
}

const setAgentTodayByCreatedateStatisticReport = (arr) => {
    const tot = {
        totalDayLead: 0,
        dayNew: 0,
        dayApproved: 0,
        dayRejected: 0,
        dayTrash: 0,
        dayUrgent: 0,
        dayDelivered: 0,
        dayCallback: 0,
        dayUncall: 0,
        dayTotalSoAmount: 0,
        dayDelivSaleOrder: 0,
        dayDelivAmount: 0,
        leadCreatedDate: undefined
    }
    
    arr.forEach(_en => {
        const _totalDayLead = _en.totalDayLead
        const _dayNew = _en.dayNew
        const _dayApproved = _en.dayApproved
        const _dayRejected = _en.dayRejected
        const _dayTrash = _en.dayTrash
        const _dayUrgent = _en.dayUrgent
        const _dayDelivered = _en.dayDelivered
        const _dayCallback = _en.dayCallback
        const _dayUncall = _en.dayUncall
        const _dayTotalSoAmount = _en.dayTotalSoAmount
        const _dayDelivSaleOrder = _en.dayDelivSaleOrder
        const _dayDelivAmount = _en.dayDelivAmount
        const _aov = _dayTotalSoAmount / _dayApproved
        const _daov = _dayDelivAmount / _dayDelivSaleOrder
        const _usr = _en.userName.split("@")
        const _teamName = _en.teamName ? _en.teamName : "No team"
        const _leadCreatedDate = _en.leadCreatedDateFormat

        tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
        tot.dayNew += _dayNew ? _dayNew : 0
        tot.dayApproved += _dayApproved ? _dayApproved : 0
        tot.dayRejected += _dayRejected ? _dayRejected : 0
        tot.dayTrash += _dayTrash ? _dayTrash : 0
        tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
        tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
        tot.dayCallback += _dayCallback ? _dayCallback : 0
        tot.dayUncall += _dayUncall ? _dayUncall : 0
        tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
        tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
        tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0
        tot.leadCreatedDate = _leadCreatedDate &&
            (tot.leadCreatedDate === undefined || _leadCreatedDate.localeCompare(tot.leadCreatedDate) > 0) ?
            _leadCreatedDate : tot.leadCreatedDate

        _en["_name"] = { "__html": _usr[0] }
        _en["_team"] = { "__html": _teamName }
        _en["_leads"] = { "__html": _totalDayLead + "" }
        _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount, 0) }
        _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
        _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
        _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
        _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
        _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
        _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
        _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
        _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
        _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
        _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
        _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
        _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
        _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
        _en["_leadCreatedDate"] = { "__html": _leadCreatedDate ? getDateStrFormat(_leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" }
        _en["_shift"] = { "__html": _en.shift ? _en.shift : "" }
    })

    const trTot = {
        "_name": { "__html": "TOTAL" },
        "_team": { "__html": "" },
        "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) },
        "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
        "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
        "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
        "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
        "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
        "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
        "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
        "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
        "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
        "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
        "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
        "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
        "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
        "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
        "_leadCreatedDate": { "__html": tot.leadCreatedDate ? getDateStrFormat(tot.leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" },
        "_shift": { "__html": "<span>&nbsp;</span>" },
        "shift": 0,
        "trClassName": "total",
    }
    Object.keys(tot).forEach(fld => {
        trTot[fld] = tot[fld]
    })

    arr.push(trTot)
}


const callAgentTodaySumByCreatedateStatisticReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_STATISTIC_REPORT_AGENT_TODAY_SUM(), {
        creFrom: payload.creFrom,
        creTo: payload.creTo,
        prodId: payload.prodId === "0" ? undefined : payload.prodId,
        terms: payload.terms === "0" ? undefined : payload.terms,
        sourceId: payload.sourceId === "0" ? undefined : payload.sourceId,
        isShift: payload.isShift
    })

    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data

        data.reportInsgAgentResponse.campaigns.forEach(_cp => {
            setAgentTodaySumByCreatedateStatisticReport(_cp.todayByCreatedateList)
        })
    }

    return data
}

const setAgentTodaySumByCreatedateStatisticReport = (arr) => {
    const tot = {
        totalDayLead: 0,
        dayNew: 0,
        dayApproved: 0,
        dayRejected: 0,
        dayTrash: 0,
        dayUrgent: 0,
        dayDelivered: 0,
        dayCallback: 0,
        dayUncall: 0,
        dayTotalSoAmount: 0,
        dayDelivSaleOrder: 0,
        dayDelivAmount: 0,
        leadCreatedDate: undefined,
        dayAvgUncallRingingTime: 0,
        dayTotalCall: 0,
        dayAvgTalkTime: 0,
        dayTotalOverdueCallback: 0,
        dayAvgUncallCall: 0,
    }

    let arrUncallAvgAtt = [];
    let arrUncallAvgRing = [];
    let arrAvgtalktime = [];
    
    arr.forEach(_en => {
        const _totalDayLead = _en.totalDayLead
        const _dayNew = _en.dayNew
        const _dayApproved = _en.dayApproved
        const _dayRejected = _en.dayRejected
        const _dayTrash = _en.dayTrash
        const _dayUrgent = _en.dayUrgent
        const _dayDelivered = _en.dayDelivered
        const _dayCallback = _en.dayCallback
        const _dayUncall = _en.dayUncall
        const _dayTotalSoAmount = _en.dayTotalSoAmount
        const _dayDelivSaleOrder = _en.dayDelivSaleOrder
        const _dayDelivAmount = _en.dayDelivAmount
        const _aov = _dayTotalSoAmount / _dayApproved
        const _daov = _dayDelivAmount / _dayDelivSaleOrder
        const _usr = _en.userName.split("@")
        const _teamName = _en.teamName ? _en.teamName : "No team"
        const _leadCreatedDate = _en.leadCreatedDateFormat
        const _uncallAvgAtt = _en.dayAvgUncallCall
        const _uncallAvgRing = _en.dayAvgUncallRingingTime === null ? 0 : _en.dayAvgUncallRingingTime
        const _totalcalls = _en.dayTotalCall
        const _avgtalktime = _en.dayAvgTalkTime === null ? 0 : _en.dayAvgTalkTime
        const _odcb = _en.dayTotalOverdueCallback

        tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
        tot.dayNew += _dayNew ? _dayNew : 0
        tot.dayApproved += _dayApproved ? _dayApproved : 0
        tot.dayRejected += _dayRejected ? _dayRejected : 0
        tot.dayTrash += _dayTrash ? _dayTrash : 0
        tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
        tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
        tot.dayCallback += _dayCallback ? _dayCallback : 0
        tot.dayUncall += _dayUncall ? _dayUncall : 0
        tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
        tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
        tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0
        tot.leadCreatedDate = _leadCreatedDate &&
            (tot.leadCreatedDate === undefined || _leadCreatedDate.localeCompare(tot.leadCreatedDate) > 0) ?
            _leadCreatedDate : tot.leadCreatedDate

        arrUncallAvgAtt.push(_uncallAvgAtt)
        arrUncallAvgRing.push(_uncallAvgRing)
        arrAvgtalktime.push(_avgtalktime)
        arrUncallAvgAtt = arrUncallAvgAtt.filter(function(value, index, arr) {
            return value >= 1;
        })
        arrUncallAvgRing = arrUncallAvgRing.filter(function(value, index, arr) {
            return value >= 1;
        })
        arrAvgtalktime = arrAvgtalktime.filter(function(value, index, arr) {
            return value >= 1;
        })

        tot.dayAvgUncallCall += _uncallAvgAtt ? _uncallAvgAtt : 0
        tot.dayAvgUncallRingingTime += _uncallAvgRing ? _uncallAvgRing : 0
        tot.dayTotalCall += _totalcalls ? _totalcalls : 0
        tot.dayAvgTalkTime += _avgtalktime ? _avgtalktime : 0
        tot.dayTotalOverdueCallback += _odcb ? _odcb : 0

        _en["_name"] = { "__html": _usr[0] }
        _en["_team"] = { "__html": _teamName }
        _en["_leads"] = { "__html": _totalDayLead + "" }
        _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount, 0) }
        _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
        _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
        _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
        _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
        _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
        _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
        _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
        _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
        _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
        _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
        _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
        _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
        _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
        _en["_leadCreatedDate"] = { "__html": _leadCreatedDate ? getDateStrFormat(_leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" }
        _en["_shift"] = { "__html": _en.shift ? _en.shift : "" }
        _en["_uncallAvgAtt"] = { "__html": isDecimal(_uncallAvgAtt) === true ? _uncallAvgAtt.toFixed(1) + "" : _uncallAvgAtt + "" }
        _en["_uncallAvgRing"] = { "__html": _uncallAvgRing === 0 ? "0s" : convertTime(_uncallAvgRing.toFixed(1)) }
        _en["_totalcalls"] = { "__html": _totalcalls + "" }
        _en["_avgtalktime"] = { "__html": _avgtalktime === 0 ? "0s" : convertTime(_avgtalktime.toFixed(1)) }
        _en["_odcb"] = { "__html": _odcb + "" }
    })

    let sum = 0;
    let suma = 0;
    let sumd = 0;
    arrUncallAvgAtt.forEach(function(element){
        sum += element;
    });
    arrUncallAvgRing.forEach(function(element){
        suma += element;
    });
    arrAvgtalktime.forEach(function(element){
        sumd += element;
    });

    const trTot = {
        "_name": { "__html": "TOTAL" },
        "_team": { "__html": "" },
        "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) },
        "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
        "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
        "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
        "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
        "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
        "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
        "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
        "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
        "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
        "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
        "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
        "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
        "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
        "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
        "_leadCreatedDate": { "__html": tot.leadCreatedDate ? getDateStrFormat(tot.leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" },
        "_shift": { "__html": "<span>&nbsp;</span>" },
        "shift": 0,
        "trClassName": "total",
        "_uncallAvgAtt": { "__html": sum === 0 ? "0" : (sum / arrUncallAvgAtt.length).toFixed(1) + "" },
        "_uncallAvgRing": { "__html": suma === 0 ? "0s" : convertTime((suma / arrUncallAvgRing.length).toFixed(1)), "avgTime" : suma / arrUncallAvgRing.length },
        "_totalcalls": { "__html": tot.dayTotalCall + "" },
        "_avgtalktime": { "__html": sumd === 0 ? "0s" : convertTime((sumd / arrAvgtalktime.length)), "avgTime" : sumd / arrAvgtalktime.length },
        "_odcb": { "__html": tot.dayTotalOverdueCallback + "" },
    }
    Object.keys(tot).forEach(fld => {
        trTot[fld] = tot[fld]
    })

    arr.push(trTot)
}

const callAgentTodayByModifydateStatisticReport = async (dateFrom, dateTo) => {
    let data = []
    const resp = await Axios.post(URL_POST_STATISTIC_REPORT_AGENT_TODAY(), { modFrom: dateFrom, modTo: dateTo })
    const fields = ['totalDayLead', 'dayNew', 'dayApproved', 'dayRejected', 'dayTrash', 'dayUrgent', 'dayDelivered', 'dayCallback', 'dayUncall', 'dayTotalSoAmount', 'dayDelivSaleOrder', 'dayDelivAmount']

    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data.reportInsgAgentResponse.campaigns
            .map(cp => cp.todayByCreatedateList)
            .reduce((acc, cur) => {
                return acc.concat(cur)
            }, [])
        data = data.reduce((acc, cur) => {
            const found = acc.filter(row => row.userId === cur.userId && row.cpCat === cur.cpCat)

            if (found && found.length > 0) {
                fields.forEach(fld => {
                    found[0][fld] += cur[fld]
                })
            } else {
                acc.push(cur)
            }

            return acc
        }, [])

        data.forEach(_en => {
            const _totalDayLead = _en.totalDayLead
            const _dayNew = _en.dayNew
            const _dayApproved = _en.dayApproved
            const _dayRejected = _en.dayRejected
            const _dayTrash = _en.dayTrash
            const _dayUrgent = _en.dayUrgent
            const _dayDelivered = _en.dayDelivered
            const _dayCallback = _en.dayCallback
            const _dayUncall = _en.dayUncall
            const _dayTotalSoAmount = _en.dayTotalSoAmount
            const _dayDelivSaleOrder = _en.dayDelivSaleOrder
            const _dayDelivAmount = _en.dayDelivAmount
            const _aov = _dayTotalSoAmount / _dayApproved
            const _daov = _dayDelivAmount / _dayDelivSaleOrder
            const _usr = _en.userName.split("@")
            const _teamName = _en.teamName ? _en.teamName : "No team"
            const _leadCreatedDate = _en.leadCreatedDateFormat

            _en["_name"] = { "__html": _usr[0] }
            _en["_team"] = { "__html": _teamName }
            _en["_leads"] = { "__html": _totalDayLead + "" }
            _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount, 0) }
            _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
            _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
            _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
            _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
            _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
            _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
            _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
            _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
            _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
            _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
            _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
            _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
            _en["_leadCreatedDate"] = { "__html": _leadCreatedDate ? getDateStrFormat(_leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" }
            _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
        })
    }

    return data
}

const callAgentTodayV2Statistic = async (payload) => {
    let data = {}
    // const resp = await Axios.post(URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE(), payload)
    const resp = await Axios.post(URL_POST_REPORT_AGENT_TODAY_BY_CREATEDATE() + '&creFrom=' + payload.creFrom + '&creTo=' + payload.creTo )

    if (resp && resp.data && resp.data.reportInsgAgentResponse && resp.data.reportInsgAgentResponse.campaigns) {
        data = resp.data

        data.reportInsgAgentResponse.campaigns.forEach(_cp => {
            const tot = {
                totalDayLead: 0,
                dayNew: 0,
                dayApproved: 0,
                dayRejected: 0,
                dayTrash: 0,
                dayUrgent: 0,
                dayDelivered: 0,
                dayCallback: 0,
                dayUncall: 0,
                dayTotalSoAmount: 0,
                dayDelivSaleOrder: 0,
                dayDelivAmount: 0,
                leadCreatedDate: undefined
            }

            _cp.todayByCreatedateList.forEach(_en => {
                const _totalDayLead = _en.totalDayLead
                const _dayNew = _en.dayNew
                const _dayApproved = _en.dayApproved
                const _dayRejected = _en.dayRejected
                const _dayTrash = _en.dayTrash
                const _dayUrgent = _en.dayUrgent
                const _dayDelivered = _en.dayDelivered
                const _dayCallback = _en.dayCallback
                const _dayUncall = _en.dayUncall
                const _dayTotalSoAmount = _en.dayTotalSoAmount
                const _dayDelivSaleOrder = _en.dayDelivSaleOrder
                const _dayDelivAmount = _en.dayDelivAmount
                const _aov = _dayTotalSoAmount / _dayApproved
                const _arqa = 5 + 10 / 2
                const _ucatmp = 15 + 50 / 2
                const _daov = _dayDelivAmount / _dayDelivSaleOrder
                const _usr = _en.userName.split("@")
                const _hour = "00:00:00"
                const _leadCreatedDate = _en.leadCreatedDateFormat

                tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
                tot.dayNew += _dayNew ? _dayNew : 0
                tot.dayApproved += _dayApproved ? _dayApproved : 0
                tot.dayRejected += _dayRejected ? _dayRejected : 0
                tot.dayTrash += _dayTrash ? _dayTrash : 0
                tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
                tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
                tot.dayCallback += _dayCallback ? _dayCallback : 0
                tot.dayUncall += _dayUncall ? _dayUncall : 0
                tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
                tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
                tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0
                tot.leadCreatedDate = _leadCreatedDate &&
                    (tot.leadCreatedDate === undefined || _leadCreatedDate.localeCompare(tot.leadCreatedDate) > 0) ?
                    _leadCreatedDate : tot.leadCreatedDate

                _en["trKey"] = _en.cpId + _usr[0] + "-" + _en.shift
                _en["_name"] = { "__html": _usr[0] }
                _en["_hour"] = { "__html": _hour }
                _en["_leads"] = { "__html": _totalDayLead + "" }
                _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount, 0) }
                _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
                _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
                _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
                _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
                _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
                _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
                _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
                _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
                _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
                _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
                _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
                _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
                _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
                _en["_arqa"] = { "__html":  getNumberWithCommas(_arqa, 0)}
                _en["_ucatmp"] = { "__html":  getNumberWithCommas(_ucatmp, 0) }
                _en["_leadCreatedDate"] = { "__html": _leadCreatedDate ? getDateStrFormat(_leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" }
                _en["_shift"] = { "__html": _en.shift ? _en.shift : "" }

            })

            _cp.todayByCreatedateList.push({
                "trKey": _cp.id + "TOTAL",
                "_name": { "__html": "TOTAL" },
                "_hour": { "__html": "TOTAL" },
                "_leads": { "__html": tot.totalDayLead + "" },
                "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
                "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
                "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
                "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
                "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
                "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
                "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
                "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
                "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
                "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
                "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
                "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
                "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
                "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.totalDayLead, 0) },
                "_arqa": { "__html": 1000 },
                "_ucatmp": { "__html": 2000 },
                "_leadCreatedDate": { "__html": tot.leadCreatedDate ? getDateStrFormat(tot.leadCreatedDate, "yyyy-MM-dd HH:mm") : "-" },
                "_shift": { "__html": "<span>&nbsp;</span>" },
                "shift": 0,
                "trClassName": "total",
            })
        })
    }

    return data
}

export {
    callAgentTodayByCreatedateStatisticReport,
    callAgentTodaySumByCreatedateStatisticReport,
    setAgentTodayByCreatedateStatisticReport,
    callAgentTodayByModifydateStatisticReport,
    callAgentTodayV2Statistic
}