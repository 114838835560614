import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentMtdReport, buildAgentData, buildHierarchy, sumHierarchy, calcHierarchy, extractTeamAgent, calcHierarchyEle } from '../../../services/reports/AgentMtdReportService'
import { getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import { DS_TEAM_TODAY } from '../TeamTodayReport/TeamTodayConfig'
import TeamTodayReportComponent from '../TeamTodayReport/TeamTodayReportComponent'
import { DS_AGENT_MTD } from './AgentMtdConfig'

import './AgentMtdReport.css'

export default (props) => {
    const views = [{ "key": "campaign", "label": "Campaign View" }, { "key": "agent", "label": "Agent View" }]
    const agentTypes = [{ "key": "1", "label": "Fresh Campaign" }, { "key": "2", "label": "Resell Campaign" }, { "key": "0", "label": "All Campaign"}]
    const [agentType, setagentType] = useState(1)
    const [view, setview] = useState(views[0].key)
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const [dsAgent, setdsAgent] = useState([])
    const [ds, setDs] = useState([])
    const sortTableIdx = {
        "_name": "name",
        "_team": "teamName",
        "_leads": "totalMthLead",
        "_sale": "mthTotalSoAmount",
        "_approved": "mthApproved",
        "_ear": "ear",
        "_aov": "aov",
        "_rejected": "mthRejected",
        "_callback": "mthCallback",
        "_uncall": "mthUncall",
        "_trash": "mthTrash",
        "_urgent": "mthUrgent",
        "_deliver": "mthDelivered",
        "_dsale": "mthDelivAmount",
        "_dr": "mthDelivSaleOrder",
        "_daov": "daov"
    }
    const sumFields = ["totalMthLead", "mthApproved", "mthRejected"
        , "mthTrash", "mthUrgent", "mthDelivered"
        , "mthCallback", "mthUncall", "mthTotalSoAmount"
        , "mthDelivSaleOrder", "mthDelivAmount"]

    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }

        let val1 = a[sort.field]["__html"], val2 = b[sort.field]["__html"]
        let diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)

        if (sort.field !== "_name" || sort.field !== "_team") {
            val1 = parseInt(val1.split("(")[0].trim().replaceAll(",", ""))
            val2 = parseInt(val2.split("(")[0].trim().replaceAll(",", ""))
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    useEffect(() => {
        Promise.all([callAgentMtdReport({})]).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const campaigns = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
            const agents = buildAgentData(campaigns)
            // eslint-disable-next-line array-callback-return
            const filtered = campaigns.filter(item => {
                if(agentType === 1){
                    return item.cpCat === 1;
                }else if(agentType === 2){
                    return item.cpCat === 2;
                }else if(agentType === 0){
                    return true;
                }
            });
            setDs(filtered)
            setdsAgent([...agents])
            document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
        })
        resetWindow()
    }, [agentType])

    return <React.Fragment>
        <div className="row justify-content-end my-3">
            {
                view === "campaign" && (
                    <div className="row justify-content-end mb-2 mr-0 selectCustom">
                        <select 
                        value={agentType} 
                        onChange={e => { setagentType(Number(e.target.value)) }} >
                            {agentTypes.map(agtType => <option key={agtType.key} value={agtType.key}>{agtType.label}</option>)}
                        </select>
                    </div>
                )
            }
            {views.map(vType => <button key={vType.key}
                className={"btn mr-1" + (view === vType.key ? " btn-success" : " btn-secondary")}
                onClick={e => { setview(vType.key) }}
            >{vType.label}{vType.type}</button>)}
        </div>

        {dsAgent && view === "agent" && <TeamTodayReportComponent
            DS_TEAM={DS_TEAM_TODAY}
            dsOrigin={dsAgent}
            buildHierarchy={buildHierarchy}
            sumHierarchy={sumHierarchy}
            calcHierarchy={calcHierarchy}
            extractTeam={extractTeamAgent}
            calcHierarchyEle={calcHierarchyEle}
            sortTableIdx={sortTableIdx}
            sumFields={sumFields}>
        </TeamTodayReportComponent>}
        
        {ds && view === "campaign" && ds.map((cp, idx) => (<div key={cp.id} className={"cp_catt_" + cp.cpCat}>
            <h4 className="mt-2">
                {ds.indexOf(cp) + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <TableComponent
                tableProps={{ id: "tableCp" + cp.id }}
                className="mt-2 w-100 table-no-pad"
                theadClassName="big"
                tbodyClassName="big"
                dataset={DS_AGENT_MTD}
                dataSource={cp.mtdList.sort(sortList)}
                sort={sort}
                onSortHandler={onSortHandler}>
            </TableComponent>
        </div>))}

        <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </React.Fragment>
}
