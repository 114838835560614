import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { URL_GET_DAILY_PUBS_PRIORITY } from '../../../config/DomainConfig';
import { getOrg, resetWindow } from '../../../services/UtilService'
import './DailyPubsPriority.css';
import { ROLE_ADV, ROLE_DIRECTOR, ROLE_MANAGER } from '../../../config/CommonConfig';

export default (props) => {

    const [data, setData] = useState([]);
    const [dataPlan, setDataPlan] = useState([]);
    const [dataTotalPlan, setDataTotalPlanPlan] = useState({});
    const today = new Date().toJSON().slice(0, 10);
    const role = localStorage.getItem("role")

    useEffect(() => {
        const geo = getOrg();
        axios.get(URL_GET_DAILY_PUBS_PRIORITY() + '?planDate=' + today + '&geo=' + geo)
            .then(resp => {
                const dailyPlanningDetailReview = resp.data.dailyPlanningDetailReview
                const listLeadPlanBySaleCampaign = resp.data.listLeadPlanBySaleCampaign
                const totalLeadPlanBySaleCampaign = resp.data.totalLeadPlanBySaleCampaign
                setData(dailyPlanningDetailReview)
                setDataPlan(listLeadPlanBySaleCampaign)
                setDataTotalPlanPlan(totalLeadPlanBySaleCampaign)
            })
            .catch(error => console.log(error));
        resetWindow()
    }, [today])

    return <React.Fragment>
        <div className='prority'>
            <h1>PRIORITY ON {new Date().toJSON().slice(0, 10).replace(/-/g, '.')}</h1>
            <div className='table-Wrap'>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th>Priority</th>
                        <th style={{ textAlign: 'left' }}>Aff</th>
                        <th style={{ textAlign: 'left' }}>Offer</th>
                        <th style={{ textAlign: 'left' }}>Sales Camp</th>
                        <th className='borderRight' style={{ textAlign: 'left' }}>Sub</th>
                        <th style={{ textAlign: 'right' }}>Lead d-1</th>
                        <th style={{ textAlign: 'right' }}>AR QA</th>
                        {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <th style={{ textAlign: 'right' }}>AR QC</th>}
                        <th style={{ textAlign: 'right' }}>TR</th>
                        <th style={{ textAlign: 'right' }}>AR MRP</th>
                        <th className='borderRight' style={{ textAlign: 'right' }}>Lead MRP</th>
                        <th className='borderRight' style={{ textAlign: 'right' }}>Plan</th>
                        <th style={{ textAlign: 'left' }}>Note for Sales</th>
                    </tr>
                    {
                        data && data.length > 0 ? (
                            data.map((item, indexPrority) => {
                                return (
                                    <tr key={indexPrority}>
                                        <td>{item.priority}</td>
                                        <td style={{ textAlign: 'left' }}>{item.affiliateId}</td>
                                        <td style={{ textAlign: 'left' }}>{item.offerId}</td>
                                        <td style={{ textAlign: 'left' }}>{item.salesCampaign}</td>
                                        <td className='borderRight' style={{ textAlign: 'left' }}>{item.subId}</td>
                                        <td style={{ textAlign: 'right' }}>{item.leadDay1Ago}</td>
                                        <td style={{ textAlign: 'right' }}>{item.arQa}%</td>
                                        {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <td style={{ textAlign: 'right' }}>{item.arQc}%</td>}
                                        <td style={{ textAlign: 'right' }}>{item.tr}%</td>
                                        <td style={{ textAlign: 'right' }}>{item.arMrp}%</td>
                                        <td className='text-info borderRight' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{item.leadMrp}</td>
                                        <td className='text-info borderRight' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{item.plan}</td>
                                        <td style={{ textAlign: 'left' }}>{item.noteForSales}</td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr style={{ textAlign: 'center', width: '100%' }}>
                                <td colSpan={100}>No data</td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </div>
        <div className='prority'>
            <h1>LEAD PLAN BY SALES CAMPAIGN</h1>
            <div className='table-Wrap' style={{ overflowY: 'hidden' }}>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th style={{ textAlign: 'left', paddingLeft: '10px' }}>Sales Camp</th>
                        <th style={{ textAlign: 'left', paddingLeft: '10px' }}>Aff</th>
                        <th style={{ textAlign: 'left', paddingLeft: '10px' }}>Offer</th>
                        <th className='borderRight' style={{ textAlign: 'left', paddingLeft: '10px' }}>Sub</th>
                        <th style={{ textAlign: 'right' }}>Lead d-1</th>
                        <th style={{ textAlign: 'right' }}>AR QA</th>
                        {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <th style={{ textAlign: 'right' }}>AR QC</th>}
                        <th style={{ textAlign: 'right' }}>TR</th>
                        <th style={{ textAlign: 'right' }}>AR MRP</th>
                        <th className='borderRight' style={{ textAlign: 'right' }}>Lead MRP</th>
                        <th style={{ textAlign: 'right' }}>Plan</th>
                    </tr>
                    {
                        dataPlan && dataPlan.length > 0 ? (
                            dataPlan.map((item, indexLeadPlan) => {
                                return (
                                    <>
                                        <tr key={indexLeadPlan}>
                                            <td width={150} style={{ textAlign: 'left', paddingLeft: '10px' }} >
                                                {item.salesCampaign}
                                                <span
                                                    onClick={() => {
                                                        dataPlan[indexLeadPlan].showExpand = !dataPlan[indexLeadPlan].showExpand
                                                        setDataPlan([...dataPlan])
                                                    }}
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                >
                                                    {
                                                        item.showExpand ? (
                                                            <i className="fas fa-angle-up"></i>
                                                        ) : (
                                                            <i className="fas fa-angle-down"></i>
                                                        )
                                                    }
                                                </span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td className='borderRight'></td>
                                            <td style={{ textAlign: 'right' }}>{item.leadDay1Ago}</td>
                                            <td style={{ textAlign: 'right' }}>{item.arQa}%</td>
                                            {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <td style={{ textAlign: 'right' }}>{item.arQc}%</td>}
                                            <td style={{ textAlign: 'right' }}>{item.tr}%</td>
                                            <td style={{ textAlign: 'right' }}>{item.arMrp}%</td>
                                            <td className='text-info borderRight' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{item.leadMrp}</td>
                                            <td className='text-info' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{item.plan}</td>
                                        </tr>
                                        {
                                            item.showExpand && (
                                                item.listDailyPlanningDetailReturn.map((e, idx) => (
                                                    <tr key={idx}>
                                                        <td></td>
                                                        <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{e.affiliateId}</td>
                                                        <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{e.offerId}</td>
                                                        <td className='borderRight' style={{ textAlign: 'left', paddingLeft: '10px' }}>{e.subId}</td>
                                                        <td style={{ textAlign: 'right' }}>{e.leadDay1Ago}</td>
                                                        <td style={{ textAlign: 'right' }}>{e.arQa}%</td>
                                                        {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <td style={{ textAlign: 'right' }}>{e.arQc}%</td>}
                                                        <td style={{ textAlign: 'right' }}>{e.tr}%</td>
                                                        <td style={{ textAlign: 'right' }}>{e.arMrp}%</td>
                                                        <td className='text-info borderRight' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{e.leadMrp}</td>
                                                        <td className='text-info' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{e.plan}</td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </>
                                )
                            })
                        ) : (
                            <tr style={{ textAlign: 'center', width: '100%' }}>
                                <td colSpan={100}>No data</td>
                            </tr>
                        )
                    }
                    {
                        dataTotalPlan && (
                            <tr style={{ fontWeight: 'bold', background: 'rgb(128 128 128)', color: '#fff' }}>
                                <td width={150} style={{ textAlign: 'left', paddingLeft: '10px' }} >
                                    Total
                                </td>
                                <td></td>
                                <td></td>
                                <td className='borderRight'></td>
                                <td style={{ textAlign: 'right' }}>{dataTotalPlan.leadDay1Ago}</td>
                                <td style={{ textAlign: 'right' }}>{dataTotalPlan.arQa}%</td>
                                {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <td style={{ textAlign: 'right' }}>{dataTotalPlan.arQc}%</td>}
                                <td style={{ textAlign: 'right' }}>{dataTotalPlan.tr}%</td>
                                <td style={{ textAlign: 'right' }}>{dataTotalPlan.arMrp}%</td>
                                <td className='borderRight' style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{dataTotalPlan.leadMrp}</td>
                                <td style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}>{dataTotalPlan.plan}</td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </div>
    </React.Fragment>
}