import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { DS_TEAM } from './TeamReportConfig'


import './TeamReport.css'
import { buildHierarchy, calcHierarchy, calcHierarchyEle, extractTeam, sumHierarchy } from './TeamReportFunctions'
import { NORM_PROPERTIES } from '../../../services/reports/PropertyConvertion'

const sortTableIdx = {
    "_name": "name",
    "_leads": "totalLead",
    "_sale": "totalSoAmount",
    "_approved": "approved",
    "_ear": "ear",
    "_aov": "aov",
    "_rejected": "rejected",
    "_callback": "callback",
    "_uncall": "uncall",
    "_trash": "trash",
    "_urgent": "urgent",
    "_deliver": "delivered",
    "_dsale": "delivAmount",
    "_dr": "delivSaleOrder",
    "_daov": "daov"
}

const TeamReportComponent = (props) => {
    const { dsOrigin } = props
    const agentTypes = [{ "key": "1", "label": "Fresh-Campaign" }, { "key": "2", "label": "Resell-Campaign" }]
    const hierarchTypes = [{ "key": "team", "label": "Team" }, { "key": "campaign", "label": "Campaign" }, { "key": "agent", "label": "Agent" }]
    const [agentType, setagentType] = useState("1")
    const [dsRaw, setdsRaw] = useState({})
    const [ds, setDs] = useState([])
    const [dsSumTotal, setDsSumTotal] = useState([])
    const [toggle, settoggle] = useState({ 0: false })
    const [sortTable, setsortTable] = useState({ "field": "totalLead", "direction": "desc" })
    const [hierarchy, sethierarchy] = useState(props.hierarchy ? [...props.hierarchy] : [])

    const getTrClassName = (row) => {
        const indents = ['', '', 'row-level-2 cursor-pointer', 'row-level-4']

        return indents[row.key.length]
    }

    const getTdContent = (row, col) => {
        let td = row[col.column]

        if (col.column === "_name") {
            if (row.key.length === 2) {
                td = {
                    "__html": '<span>' + row.name +
                        (toggle[row.key.join("_")] === undefined || toggle[row.key.join("_")] ? '<i class="fa fa-minus table-sm"></i>' : '<i class="fa fa-plus table-sm"></i>') +
                        '</span>'
                }
            }
        }

        return <span dangerouslySetInnerHTML={td}></span>
    }

    const onTrClick = (row) => {
        if (row.key.length === 1) {
            const strKey = row.key.join("_")

            if (toggle[strKey] === undefined || toggle[strKey]) {
                toggle[strKey] = false
            } else {
                toggle[strKey] = true
            }
            settoggle({ ...toggle })
        } else if (row.key.length === 2) {
            const strKey = row.key.join("_")

            if (toggle[strKey] === undefined || toggle[strKey]) {
                toggle[strKey] = false
            } else {
                toggle[strKey] = true
            }
            settoggle({ ...toggle })
        }
    }

    const onTableCollapse = (e, ds, opt) => {
        e.preventDefault()

        ds.filter(row => row.key.length === 2)
            .forEach(row => {
                toggle[row.key.join("_")] = opt
            })

        settoggle({ ...toggle })
    }

    const isTrRender = (row) => {
        let rslt = true
        const key = row.key

        if (row.key.length === 3) {
            rslt = toggle[key[0] + "_" + key[1]] === undefined || toggle[key[0] + "_" + key[1]]
        }

        return rslt
    }

    const onTableSortHandler = (param) => {
        setsortTable(param)
    }

    const toggleHierarchy = (hrcKey) => {
        const idx = hierarchy.indexOf(hrcKey)
        if (idx === -1) {
            hierarchy.push(hrcKey)
        } else {
            hierarchy.splice(idx, 1)
        }

        sethierarchy([...hierarchy])
    }

    useEffect(() => {
        if (dsOrigin) {
            setdsRaw(dsOrigin)
        }

    }, [dsOrigin])

    useEffect(() => {
        if (hierarchy && hierarchy.length === 3) {
            const cpCat = parseInt(agentType)
            let data = dsRaw && dsRaw.filter ? dsRaw.filter(row => !row.cpCat || row.cpCat === cpCat) : []
            const sumRow = { "name": "TOTAL" }
            let myFields
            let sumFields

            if (data.length > 0) {
                myFields = Object.keys(data[0])
                sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld) > -1).map(fld => NORM_PROPERTIES[fld])
                    .filter(fld => myFields.indexOf(fld) > -1)
                data = buildHierarchy(
                    data,
                    cpCat,
                    props.conditions &&
                        props.conditions.hierarchy ?
                        props.conditions.hierarchy : hierarchy
                )
                sumHierarchy(data)
                calcHierarchy(data)
                
                sumFields.forEach(field => {
                    sumRow[field] = 0
                })
                Object.keys(data).forEach(t0 => {
                    sumFields.forEach(field => {
                        sumRow[field] += data[t0][field] ? data[t0][field] : 0
                    })
                })
                calcHierarchyEle(sumRow)
                setDsSumTotal([sumRow])
            } else {
                setDsSumTotal(undefined)
            }

            setDs({ ...data })
        }
    }, [dsRaw, agentType, hierarchy, props])
    //, conditions, sumFields

    return <React.Fragment>
        <div className="row justify-content-end my-3">

            {hierarchy.length === 3 && agentTypes.map(agtType => <button key={agtType.key}
                className={"btn mr-1" + (agentType === agtType.key ? " btn-success" : " btn-secondary")}
                onClick={e => { setagentType(agtType.key) }}
            >{agtType.label}{agtType.type}</button>)}

            <div className="btn-group ml-3" role="group" aria-label="Basic example">
                {hierarchTypes.sort((hrcType1, hrcType2) => {
                    let val1 = hierarchy.indexOf(hrcType1.key)
                    let val2 = hierarchy.indexOf(hrcType2.key)

                    val1 = val1 === -1 ? 9 : val1
                    val2 = val2 === -1 ? 9 : val2

                    return val1 - val2
                }).map(hrcType => (<button key={hrcType.key} type="button"
                    className={"btn" + (hierarchy.indexOf(hrcType.key) !== -1 ? " btn-success" : " btn-secondary")}
                    onClick={e => toggleHierarchy(hrcType.key)}>
                    {hrcType.label}
                </button>))}
            </div>

            <button className="btn btn-danger ml-3"
                onClick={props.onClose}>
                <i className="fa fa-window-close"></i>
            </button>
        </div>

        {hierarchy.length === 3 && <React.Fragment>
            {[0].map(x => {
                const dsTable = Object.keys(ds).sort((t1, t2) => {
                    const sortField = sortTableIdx[sortTable.field]
                    const val1 = ds[t1][sortField]
                    const val2 = ds[t2][sortField]
                    let diff

                    if (isNaN(val1) || isNaN(val2)) {
                        diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
                    } else {
                        diff = val1 - val2
                    }

                    return sortTable.direction === "asc" ? diff : diff * -1
                }).map(t0 => {
                    const dsRow = { ...ds[t0] }
                    delete dsRow.cate

                    return dsRow
                })

                return (<TableComponent
                    key={x}
                    id="tableTeamSum"
                    className="mt-2 w-100 table-no-pad mb-5"
                    theadClassName="big"
                    tbodyClassName="big"
                    dataset={DS_TEAM}
                    dataSource={dsTable}
                    dataSourceTotal={dsTable && dsTable.length > 1 ? dsSumTotal : undefined}
                    sort={sortTable}
                    onSortHandler={onTableSortHandler}
                >
                </TableComponent>)
            })}


            {Object.keys(ds).sort((t1, t2) => {
                const sortField = sortTableIdx[sortTable.field]
                const val1 = ds[t1][sortField]
                const val2 = ds[t2][sortField]
                let diff

                if (isNaN(val1) || isNaN(val2)) {
                    diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
                } else {
                    diff = val1 - val2
                }

                return sortTable.direction === "asc" ? diff : diff * -1
            }).map(t0 => {
                const team = ds[t0]
                const _ds = extractTeam(ds, t0, sortTable, sortTableIdx)

                return (<div id={"team" + t0} key={[t0]}>
                    <h4 className="mt-2">
                        {team.name}

                        <a className="ml-3 team" href="/#" onClick={e => { e.preventDefault(); onTrClick({ "key": [t0] }) }}>
                            {(toggle[t0] !== undefined && !toggle[t0]) && <i className="fas fa-angle-down"></i>}
                            {(toggle[t0] === undefined || toggle[t0]) && <i className="fas fa-angle-up"></i>}
                        </a>

                        {toggle && (toggle[t0] === undefined || toggle[t0]) && <span className="float-right">
                            <a className="icon-collapse mr-2" href="/#" onClick={e => { onTableCollapse(e, _ds, false) }}><i className="fas fa-minus"></i></a>
                            <a className="icon-collapse" href="/#" onClick={e => { onTableCollapse(e, _ds, true) }}><i className="fas fa-plus"></i></a>
                        </span>}
                    </h4>

                    {toggle && (toggle[t0] === undefined || toggle[t0]) && <TableComponent
                        tableProps={{ id: "tableTeam" }}
                        className="mt-2 w-100 table-no-pad"
                        theadClassName="big"
                        tbodyClassName=""
                        getTrClassName={getTrClassName}
                        getTdContent={getTdContent}
                        dataSource={_ds}
                        dataset={DS_TEAM}
                        isTrRender={isTrRender}
                        onTrClick={onTrClick}
                        sort={sortTable}
                        onSortHandler={onTableSortHandler}>
                    </TableComponent>}
                </div>)
            })}
        </React.Fragment>}
    </React.Fragment>
}

export default TeamReportComponent