import React from 'react'
import { FORMAT_NUMBER_M, getNumberFixed, getNumberFormat, getNumberWithCommas, getPageOrg } from '../../../services/UtilService'
import { ROLE_ADV, ROLE_DIRECTOR, ROLE_MANAGER } from '../../../config/CommonConfig'

const EAR_MARGIN = { th: 75, vn: 70, id: 70 }
const NUMBER_FORMAT = { th: "", vn: FORMAT_NUMBER_M, id: FORMAT_NUMBER_M }
const role = localStorage.getItem("role")

const getHtmlNode = (total, numberFormat) => {
    // let ear = total.approved * 100 / (total.approved + total.rejected + total.callback)
    const lHtml = getNumberWithCommas(total.totalLead)
    const amtHtml = getNumberFormat(numberFormat, total.totalSoAmount)
    const aovHtml = total.totalLead > 0 ? getNumberFormat(numberFormat, total.totalSoAmount / total.approved) : 0
    const arHtml = getNumberFixed(total.approved * 100 / total.totalLead)
    const arqaHtml = getNumberFixed(total.validated * 100 / total.totalLead)
    const arqcHtml = getNumberFixed(total.validatedPb * 100 / total.totalLead)
    const tHtml = getNumberFixed(total.trash * 100 / total.totalLead)
    const rHtml = getNumberFixed(total.rejected * 100 / total.totalLead)
    const cHtml = getNumberFixed(total.callback * 100 / total.totalLead)
    const uHtml = getNumberFixed(total.uncall * 100 / total.totalLead)
    const earHtml = getNumberFixed(isNaN(total.ear) ? 0 : total.ear)

    return (<React.Fragment>
        <p>
            <span className="mr-1">{lHtml}</span>
            <span className="">({amtHtml})</span>
        </p>
        <p>
            [<span className="mr-1 text-info">{arHtml}%</span>/
            <span className="mx-1 text-success">{arqaHtml}%</span> {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <span className="mx-1 text-success">({arqcHtml}%)</span>}/
            <span className="ml-1 text-danger">{tHtml}%</span>]
        </p>
        <p>
            <span className="mr-1 text-danger">rj: {rHtml}%</span>
            <span className="mr-1 text-info">cb: {cHtml}%</span>
            <span>u: {uHtml}%</span>
        </p>
        <p>
            <span className="mx-1 text-success">eAr: {earHtml}%</span>
            <span className="">aov: {aovHtml}</span>
        </p>
    </React.Fragment>)
}

const getBgDanger = (ear, margin) => {
    let rslt = ""

    if (!ear || Math.ceil(ear) < margin) {
        rslt = " bg-dangerous " + margin
    }

    return rslt
}

const RelPerformanceComponent = (props) => {
    const { ds } = props
    const pageOrg = getPageOrg()
    const earMargin = EAR_MARGIN[pageOrg]
    const numberFormat = NUMBER_FORMAT[pageOrg]
    const onZoomClick = (opt, teamId, cpId) => {
        const options = {
            "team": ["team", "campaign", "agent"],
            "campaign": ["campaign", "team", "agent"],
            "rel": ["team", "campaign", "agent"]
        }
        const query = {
            teamId: teamId,
            cpId: cpId
        }

        props.onZoomClick({
            hierarchy: options[opt],
            query: query
        })
    }

    return (<React.Fragment>
        <div className="card mb-4">
            <div className="card-body">
                <div className="scroll-x wallboard team-performance-report">

                    {ds.campaigns.map(cp => (<div key={[cp.cpId]} className="wb-row">
                        <div className="wb-col ml-1">
                            <div className={"card text-center qualified more4 cursor-pointer" + getBgDanger(ds.total.campaigns[cp.cpId].ear, earMargin)}
                                data-campaign={cp.cpId}
                                onClick={e => { onZoomClick("campaign", undefined, cp.cpId) }}>
                                <div className="card-body">
                                    <p><span className="mr-1 weight-500 title">{cp.cpName}</span></p>
                                    {getHtmlNode(ds.total.campaigns[cp.cpId], numberFormat)}
                                </div>
                            </div>
                        </div>

                        {ds.teams.filter(tm => ds.total[tm.teamId][cp.cpId].totalLead > 0)
                            .sort((tm1, tm2) => ds.total[tm2.teamId][cp.cpId].totalLead - ds.total[tm1.teamId][cp.cpId].totalLead)
                            .map(tm => {
                                const bgDangerous = getBgDanger(ds.total[tm.teamId][cp.cpId].ear, earMargin)
                                return (<div key={[tm.teamId]} className="wb-col ml-1">
                                    <div className={"card text-center more4 cursor-pointer qualified" + bgDangerous}
                                        data-team={tm.teamId}
                                        data-campaign={cp.cpId}
                                        onClick={e => { onZoomClick("rel", tm.teamId, cp.cpId) }}>
                                        <div className="card-body">
                                            <React.Fragment>
                                                <p><span className="mr-1">{tm.teamId ? tm.teamName : "No team"}</span></p>
                                                {getHtmlNode(ds.total[tm.teamId][cp.cpId], numberFormat)}
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>)
                            })}
                    </div>))}
                </div>
            </div>
        </div>
    </React.Fragment>)
}

export default RelPerformanceComponent