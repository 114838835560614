const DS_SOURCE = {
    "columns": [
        { "column": "_no", "title": "No.", "width": "5%", "thClassName": "text-center", "tdClassName": "", "sort": false },
        { "column": "_aff_id", "title": "Aff ID", "width": "15%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false },
        { "column": "_cpa_payout", "title": "CPA Payout", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false },
        { "column": "_cpa_revenue", "title": "CPA Revenue", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false },
        { "column": "_cpl_payout", "title": "CPL Payout", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false },
        { "column": "_cpl_revenue", "title": "CPL Revenue", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center", "sort": false }
    ]
}

export { DS_SOURCE }