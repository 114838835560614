import React, { useState, useEffect } from "react";
import ReactDatePicker from 'react-datepicker'
import { callGetStatisticReportCampaignOverviewV2 } from '../../../services/statisticReports/CampaignOverviewV2StatisticReportService'
import { resetWindow, getRedisDateFormat, getDateFormat } from '../../../services/UtilService'
import './CampaignOverviewV2StatisticReport.css'
import { ROLE_ADV, ROLE_DIRECTOR, ROLE_MANAGER } from "../../../config/CommonConfig";

export default () => {
  const [dataSource, setDataSource] = useState([])
  const agentTypes = [{ "key": "1", "label": "Fresh Campaign" }, { "key": "2", "label": "Resell Campaign" }, { "key": "0", "label": "All Campaign"}]
  const dateTypes = [{ "key": "1", "label": "View by created date", "value": "createdate" }, { "key": "2", "label": "View by modified date", "value": "modifydate" }]
  const [dateType, setDateType] = useState("createdate")
  const role = localStorage.getItem("role")

  const [query, setquery] = useState({
    valid: false,
    agentType: "1",
    dateFrom: undefined,
    dateTo: undefined
  })

  const headerDefault = [
    { label: "Campaign", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "Leads", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "New", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "Uncall", rowspan: 0, colspan: 5, scope: "colgroup" },
    { label: "Callback", rowspan: 0, colspan: 4, scope: "colgroup" },
    { label: "Urgent", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "Approved", rowspan: 0, colspan: 6, scope: "colgroup" },
    { label: "Reject", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "Trash", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "Closed", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "AR_QA", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "AR_QC", rowspan: 2, colspan: 0, scope: "rowgroup" },
    { label: "#1-2", rowspan: 0, colspan: 0, scope: "col" },
    { label: "#3-5", rowspan: 0, colspan: 0, scope: "col" },
    { label: "#6-8", rowspan: 0, colspan: 0, scope: "col" },
    { label: "#8+", rowspan: 0, colspan: 0, scope: "col" },
    { label: "Total", rowspan: 0, colspan: 0, scope: "col" },
    { label: "P", rowspan: 0, colspan: 0, scope: "col" },
    { label: "C", rowspan: 0, colspan: 0, scope: "col" },
    { label: "NP", rowspan: 0, colspan: 0, scope: "col" },
    { label: "Total", rowspan: 0, colspan: 0, scope: "col" },
    { label: "N", rowspan: 0, colspan: 0, scope: "col" },
    { label: "V", rowspan: 0, colspan: 0, scope: "col" },
    { label: "P", rowspan: 0, colspan: 0, scope: "col" },
    { label: "D", rowspan: 0, colspan: 0, scope: "col" },
    { label: "C", rowspan: 0, colspan: 0, scope: "col" },
    { label: "Total", rowspan: 0, colspan: 0, scope: "col" },
  ].filter(item => !(![ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && item.label === 'AR_QC'));

  useEffect(() => {
    resetWindow()
  }, [])

  const onQueryChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    query.valid = false;

    query[name] = value
    if(query.dateFrom && query.dateTo) {
      query.valid = query.dateFrom.getTime() <= query.dateTo.getTime()
    }
    setquery({ ...query })
  }

  const onSearch = (e) => {
    var obj = {
      agentType: query.agentType,
      typeDate: dateType,
      dateFrom: '',
      dateTo: ''
    }
    obj.dateFrom = getDateFormat(query.dateFrom, 'yyyy-MM-dd') + ' 00:00:00'
    obj.dateTo = getDateFormat(query.dateTo, 'yyyy-MM-dd') + ' 23:59:59'
    let callFns = []
    callFns = [callGetStatisticReportCampaignOverviewV2(obj)]
    Promise.all(callFns).then(rest => {
      setDataSource(rest[0][0])
      document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(rest[0][1])
    })
  }

  return (
    <div className="w-100 mt-2">
      <div className='panelDefault'>
        <div className='panelHeader'>The leads in this report will be counted by {dateType === 'createdate' ? ' Created date' : ' Modified date'}</div>
          <div className="d-flex flex-column" style={{ padding: '10px'}}>
            <div className="form-row mt-2" style={{ alignItems: 'center'}}>
                <div className="form-group col-md-2">
                    <label htmlFor="fltDateFrom">{dateType === 'createdate' ? 'Created Date From' : 'Modified Date From'}</label>
                    <div className="form-control border-0 p-0">
                        <ReactDatePicker id="fltDateFrom"
                            name="dateFrom"
                            selected={query.dateFrom}
                            maxDate={query.dateTo ? query.dateTo : new Date()}
                            dateFormat="dd/MM/yyyy"
                            onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                            className="form-control"></ReactDatePicker>
                    </div>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="fltDateTo">{dateType === 'createdate' ? 'Created Date To' : 'Modified Date To'}</label>
                    <div className="form-control border-0 p-0">
                        <ReactDatePicker id="fltDateTo"
                            name="dateTo"
                            selected={query.dateTo}
                            maxDate={new Date()}
                            minDate={query.dateFrom ? query.dateFrom : null}
                            dateFormat="dd/MM/yyyy"
                            onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                            className="form-control"></ReactDatePicker>
                    </div>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="fltCampaign">{'Campaign'}</label>
                    <div className="form-control border-0 selectCustom w-100 p-0" style={{ height: '30px'}}>
                      <select className="w-100" style={{ borderRadius: '1.25rem'}} id="fltCampaign" value={query.agentType} onChange={e => { setquery({ ...query, agentType: e.target.value }) }}>
                        {agentTypes.map(agtType => <option key={agtType.key} value={agtType.key}>{agtType.label}</option>)}
                      </select>
                    </div>
                </div>
                <div className="selectCustom form-group col-md-2" style={{ height: '30px', marginTop: '7px' }}>
                  <select className="w-100" value={dateType} onChange={e => { setDateType(e.target.value) }}>
                    {dateTypes.map(item => <option key={item.key} value={item.value}>{item.label}</option>)}
                  </select>
                </div>
                <div className="form-group col-md-2 mt-4">
                  <button id="btnSearch" disabled={!query.valid} className="form-control w-50 border-0 btn btn-success" onClick={e => onSearch(e)}>Search</button>
                </div>
            </div>
        </div>
    </div>
      <table className="table-compoment mt-2 w-100 table-no-pad">
        <colgroup span="22"></colgroup>
        <thead className="text-center">
          <tr>
            {headerDefault.map((col, index) => 
              col.colspan <= 0 && col.rowspan > 0 ? (
                <th key={index} rowSpan={col.rowspan} scope={col.scope} className="thTableV2">
                  {col.label}
                </th>
              ) :
              col.colspan > 0 && col.rowspan <= 0 && (
                <th key={index} colSpan={col.colspan} scope={col.scope} className="thTableV2">
                  {col.label}
                </th>
              )
            )}
          </tr>
          <tr>
            {headerDefault.map((col, index) =>
              col.colspan <= 0 && col.rowspan <= 0 && <th key={index} scope="col" className="thTableV2">{col.label}</th>
            )}
          </tr>
        </thead>
        {
          dataSource.length > 0 ? (
            <tbody>
              {
                dataSource.map((row, index) => {
                  let colOptions = Object.keys(row).map(function(key, id) {
                  let tdCol = key.includes('%') 
                                ? <td key={key} className="tdTableV2">{row[key][0] ? row[key][0] : 0} <span className="percent">{'('+ (row[key][1] ? row[key][1] : 0) + '%)'}</span></td> 
                                  : id <= 0
                                  ? <td key={key} className="text-center tdTableV2">{row[key]}</td> 
                                : <td key={key} className="tdTableV2">{row[key]}</td>;
                    return tdCol
                  })
                  if (index <= 0) {
                    return <tr key={index} className="text-right total">{colOptions}</tr>
                  } else {
                    return <tr key={index} className="text-right">{colOptions}</tr>
                  }
                }
                )
              }
            </tbody>
          ) : (
            <tbody>
              <tr className="text-center"><td colSpan={100}>No Data</td></tr>
            </tbody>
          )       
        }        
      </table>
      <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </div>
  );
};
