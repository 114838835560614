const DS_CAMPAIGN_OVERVIEW = {
    "columns": [
        { "column": "_name", "title": "Campaign", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_totalLeads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_totalNew", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_approved", "title": "AR", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_arqa", "title": "AR-QA", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_ear", "title": "E-AR", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_uncall", "title": "Uncall", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_trash", "title": "Trash", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_rejected", "title": "Rejected", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_callback", "title": "Callback", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_urgent", "title": "Urgent", "width": "10%", "thClassName": "text-center", "tdClassName": "text-center" }
    ]
}
const DS_TOP10_AGENT_FRESH = {
    "columns": [
        { "column": "_userName", "title": "Agent", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_totalAmount", "title": "Sales", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true },
        { "column": "_totalApproved", "title": "Approved", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true },
        { "column": "_aov", "title": "AOV", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true }
    ]
}
const DS_TOP10_AGENT_RESELL = {
    "columns": [
        { "column": "_userName", "title": "Agent", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_totalAmount", "title": "Sales", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true },
        { "column": "_totalApproved", "title": "Approved", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true },
        { "column": "_aov", "title": "AOV", "weight": 1, "thClassName": "text-center", "tdClassName": "text-center", "comma": true }
    ]
}

export {
    DS_CAMPAIGN_OVERVIEW,
    DS_TOP10_AGENT_FRESH,
    DS_TOP10_AGENT_RESELL
}