import React, { useEffect, useState } from 'react'
import { callGetFulfillmentStock } from '../../../services/FulfillmentService'
import { resetWindow } from '../../../services/UtilService'

const FulfillmentStock = (props) => {
    const [ds, setds] = useState(undefined)

    useEffect(() => {
        Promise.all([callGetFulfillmentStock()]).then(resp => {
            setds(resp[0])
        })
        resetWindow()
    }, [])

    return (<React.Fragment>
        {ds && (<table className="table-compoment mt-2 w-100 table-no-pad">
            <colgroup>
            <col width="20%"></col>
            </colgroup>
            <thead>
                {ds.head.map((hd, idx) => <tr key={idx}>
                    {hd.map(col => {
                        const { __html, ...attr } = col
                        return (<th {...attr} dangerouslySetInnerHTML={{ __html: __html }}></th>)
                    })}
                </tr>)}
            </thead>
            <tbody>
                {ds.body.map((bd, idx) => <tr key={idx}>
                    {bd.map(col => {
                        const { __html, ...attr } = col
                        return (<td {...attr} dangerouslySetInnerHTML={{ __html: __html }}></td>)
                    })}
                </tr>)}
            </tbody>
        </table>)}
    </React.Fragment>)
}

export default FulfillmentStock