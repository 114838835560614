import Axios from "axios"
import { URL_GET_STATISTIC_REPORT_BY_CREATEDATE, URL_GET_STATISTIC_REPORT_BY_MODIFIEDDATE } from "../../config/DomainConfig"
import { getNumberFixed, getNumberWithCommas, getValWithPercentHtml } from "../UtilService"

const callDailyStatisticReport = async (dateType, dateFrom, dateTo) => {
    const data = { campaigns: [] }
    const cpIdx = {}
    let resp

    if (dateType === "createdate") {
        resp = await Axios.get(URL_GET_STATISTIC_REPORT_BY_CREATEDATE() + "/" + dateFrom + "/" + dateTo)
    } else {
        resp = await Axios.get(URL_GET_STATISTIC_REPORT_BY_MODIFIEDDATE() + "/" + dateFrom + "/" + dateTo)
    }
    if (resp && resp.data && resp.data.reportInsgDailyEntities && resp.data.reportInsgDailyEntities) {
        resp.data.reportInsgDailyEntities.forEach(_row => {
            const cpId = _row.cpId

            if (!cpIdx[cpId]) {
                cpIdx[cpId] = {
                    id: cpId,
                    name: _row.cpName,
                    cpCat: _row.cpCat,
                    cpSubCat: _row.cpSubCat,
                    amount: 0,
                    list: [],
                    summary: {}
                }
            }

            cpIdx[cpId].amount = cpIdx[cpId].amount + _row.dayTotalSoAmount
            cpIdx[cpId].list.push(_row)
        })

        data.campaigns.push(...Object.keys(cpIdx).map(cpid => cpIdx[cpid]))
    }

    data.campaigns.forEach(_cp => {
        const tot = {
            totalDayLead: 0,
            dayNew: 0,
            dayApproved: 0,
            dayRejected: 0,
            dayTrash: 0,
            dayUrgent: 0,
            dayDelivered: 0,
            dayCallback: 0,
            dayUncall: 0,
            dayTotalSoAmount: 0,
            dayDelivSaleOrder: 0,
            dayDelivAmount: 0
        }

        _cp.list.forEach(_en => {
            const _totalDayLead = _en.totalDayLead
            const _dayNew = _en.dayNew
            const _dayApproved = _en.dayApproved
            const _dayRejected = _en.dayRejected
            const _dayTrash = _en.dayTrash
            const _dayUrgent = _en.dayUrgent
            const _dayDelivered = _en.dayDelivered
            const _dayCallback = _en.dayCallback
            const _dayUncall = _en.dayUncall
            const _dayTotalSoAmount = _en.dayTotalSoAmount
            const _dayDelivSaleOrder = _en.dayDelivSaleOrder
            const _dayDelivAmount = _en.dayDelivAmount
            const _aov = _dayTotalSoAmount / _dayApproved
            const _daov = _dayDelivAmount / _dayDelivSaleOrder
            const _teamName = _en.teamName ? _en.teamName : "No team"

            tot.totalDayLead += _totalDayLead ? _totalDayLead : 0
            tot.dayNew += _dayNew ? _dayNew : 0
            tot.dayApproved += _dayApproved ? _dayApproved : 0
            tot.dayRejected += _dayRejected ? _dayRejected : 0
            tot.dayTrash += _dayTrash ? _dayTrash : 0
            tot.dayUrgent += _dayUrgent ? _dayUrgent : 0
            tot.dayDelivered += _dayDelivered ? _dayDelivered : 0
            tot.dayCallback += _dayCallback ? _dayCallback : 0
            tot.dayUncall += _dayUncall ? _dayUncall : 0
            tot.dayTotalSoAmount += _dayTotalSoAmount ? _dayTotalSoAmount : 0
            tot.dayDelivSaleOrder += _dayDelivSaleOrder ? _dayDelivSaleOrder : 0
            tot.dayDelivAmount += _dayDelivAmount ? _dayDelivAmount : 0

            _en["_name"] = { "__html": _en.date }
            _en["_team"] = { "__html": _teamName }
            _en["_leads"] = { "__html": getNumberWithCommas(_totalDayLead) + "" }
            _en["_sale"] = { "__html": getNumberWithCommas(_dayTotalSoAmount) }
            _en["_new"] = { "__html": getValWithPercentHtml(_dayNew, _totalDayLead) }
            _en["_approved"] = { "__html": getValWithPercentHtml(_dayApproved, _totalDayLead) }
            _en["_ear"] = { "__html": getNumberFixed(_dayApproved * 100 / (_dayApproved + _dayRejected + _dayCallback), 0) + "%" }
            _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
            _en["_rejected"] = { "__html": getValWithPercentHtml(_dayRejected, _totalDayLead) }
            _en["_callback"] = { "__html": getValWithPercentHtml(_dayCallback, _totalDayLead) }
            _en["_uncall"] = { "__html": getValWithPercentHtml(_dayUncall, _totalDayLead) }
            _en["_trash"] = { "__html": getValWithPercentHtml(_dayTrash, _totalDayLead) }
            _en["_urgent"] = { "__html": getValWithPercentHtml(_dayUrgent, _totalDayLead) }
            _en["_deliver"] = { "__html": getValWithPercentHtml(_dayDelivered, _totalDayLead) }
            _en["_dsale"] = { "__html": getNumberFixed(_dayDelivAmount, 0) }
            _en["_dr"] = { "__html": getValWithPercentHtml(_dayDelivSaleOrder, _dayApproved) }
            _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
        })

        const trTotal = {
            "_name": { "__html": "TOTAL" },
            "_team": { "__html": "" },
            "_leads": { "__html": getNumberWithCommas(tot.totalDayLead, 0) + "" },
            "_sale": { "__html": getNumberWithCommas(tot.dayTotalSoAmount, 0) },
            "_new": { "__html": getValWithPercentHtml(tot.dayNew, tot.totalDayLead) },
            "_approved": { "__html": getValWithPercentHtml(tot.dayApproved, tot.totalDayLead) },
            "_ear": { "__html": getNumberFixed(tot.dayApproved * 100 / (tot.dayApproved + tot.dayRejected + tot.dayCallback), 0) + "%" },
            "_aov": { "__html": getNumberFixed(tot.dayTotalSoAmount / tot.dayApproved, 0) },
            "_rejected": { "__html": getValWithPercentHtml(tot.dayRejected, tot.totalDayLead) },
            "_callback": { "__html": getValWithPercentHtml(tot.dayCallback, tot.totalDayLead) },
            "_uncall": { "__html": getValWithPercentHtml(tot.dayUncall, tot.totalDayLead) },
            "_trash": { "__html": getValWithPercentHtml(tot.dayTrash, tot.totalDayLead) },
            "_urgent": { "__html": getValWithPercentHtml(tot.dayUrgent, tot.totalDayLead) },
            "_deliver": { "__html": getValWithPercentHtml(tot.dayDelivered, tot.totalDayLead) },
            "_dsale": { "__html": getNumberFixed(tot.dayDelivAmount, 0) },
            "_dr": { "__html": getValWithPercentHtml(tot.dayDelivSaleOrder, tot.dayApproved) },
            "_daov": { "__html": getNumberFixed(tot.dayDelivAmount / tot.dayDelivSaleOrder, 0) },
            "trClassName": "total",
            ...tot
        }
        _cp.list.push({ ...trTotal })

        _cp.summary = JSON.parse(JSON.stringify(trTotal))
        _cp.summary["_name"]["__html"] = _cp.name
    })

    return data
}

export { callDailyStatisticReport }