import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './index.css';
import './plugins/fontawesome-5.15.1/css/all.min.css'
import './plugins/sb-admin-theme/css/styles.css'
import './plugins/sb-admin-theme/css/custom.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { isMobileAndTablet, resetWindow } from './services/UtilService';

const autoLoadListener = () => {
  const isMobileOrTablet = isMobileAndTablet()

  if (!isMobileOrTablet) {
    resetWindow()
    const nxFullScreen = window.screen.height === window.innerHeight;

    if (nxFullScreen) {
      //setAppContext({ "fullScreen": nxFullScreen })
      document.getElementsByTagName('body')[0].className += ' fullscreen'
    } else {
      let bodyClass = document.getElementsByTagName('body')[0].className
      bodyClass = bodyClass.replaceAll(' fullscreen', '')
      document.getElementsByTagName('body')[0].className = bodyClass
    }
  }
}
window.addEventListener('resize', function () {
  autoLoadListener();
});
autoLoadListener();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
