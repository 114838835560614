import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callPostHSReportCampaignOverview } from '../../../services/hsReports/CampaignOverviewHSReportService'
import { resetWindow } from '../../../services/UtilService'
import { DS_CAMPAIGN_OVERVIEW } from '../CampaignOverviewHSReport/CampaignOverviewHSReportDataset'
export default (props) => {
    const iniDataSource = { "campaign": [], "topFresh": [], "topResell": [] }
    const [dataSource, setDataSource] = useState(iniDataSource)
    const [query, setquery] = useState({
        dateFrom: undefined,
        dateTo: undefined,
        leadCpName: undefined,
        batchId: undefined,
        load: false,
        leadType: "M"
    })

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        setquery({ ...query })
    }

    useEffect(() => {
        if (query.load) {
            const callFns = [
                callPostHSReportCampaignOverview({ ...query })
            ]

            Promise.all(
                callFns
            ).then(rest => {
                const dsCampaign = rest[0]
                setDataSource({
                    "campaign": dsCampaign.campaignOverviewEntities ? dsCampaign.campaignOverviewEntities : []
                })
                setquery({ ...query, load: false })
            })
        }
    }, [query])

    useEffect(() => {
    }, [dataSource])

    useEffect(() => {
        resetWindow()
    }, [])

    return (<div className="card mb-4">
        <div className="card-body">
            <div className="row">
                <div className="col-2">
                    <input type="text" id="fltLeadCpName"
                        name="leadCpName"
                        placeholder="Campaign Name"
                        value={query.leadCpName}
                        onChange={e => onQueryChange({ target: { name: "leadCpName", value: e.target.value } })}
                        className="form-control"></input>
                </div>
                <div className="col-2">
                    <input type="text" id="fltBatchId"
                        name="batchId"
                        placeholder="Batch ID"
                        value={query.batchId}
                        onChange={e => onQueryChange({ target: { name: "batchId", value: e.target.value } })}
                        className="form-control"></input>
                </div>
                <div className="col-2">
                    <input type="text" id="fltDateFrom"
                        name="dateFrom"
                        placeholder="yyyy-mm-dd"
                        value={query.dateFrom}
                        onChange={e => onQueryChange({ target: { name: "dateFrom", value: e.target.value } })}
                        className="form-control"></input>
                </div>
                <div className="col-2">
                    <input type="text" id="fltDateTo"
                        name="dateTo"
                        placeholder="yyyy-mm-dd"
                        value={query.dateTo}
                        onChange={e => onQueryChange({ target: { name: "dateTo", value: e.target.value } })}
                        className="form-control"></input>
                </div>
                <div className="col-1">
                    <button className="btn btn-success"
                        onClick={e => { onQueryChange({ target: { name: "load", value: true } }) }}
                    >Search</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-12 scroll-x">
                            <TableComponent className="mt-2 w-100"
                                theadClassName="big"
                                tbodyClassName="big"
                                dataset={DS_CAMPAIGN_OVERVIEW}
                                dataSource={
                                    dataSource.campaign.sort((a, b) => b.totalLeads - a.totalLeads)
                                }>
                            </TableComponent>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-3">
                        <label className="small">Top {dataSource.topFresh.length} Agents(fresh)</label>

                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_FRESH} dataSource={dataSource.topFresh}></TableComponent>
                            </div>
                        </div>

                        <label className="small mt-5">Top {dataSource.topResell.length} Agents(resell)</label>
                        <div className="row">
                            <div className="col-12 scroll-x">
                                <TableComponent dataset={DS_TOP10_AGENT_RESELL} dataSource={dataSource.topResell}></TableComponent>
                            </div>
                        </div>
                    </div> */}
            </div>

            <div className="row"><div id="redisDateSum" className="col-12 small text-right"></div></div>
            <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
        </div>
    </div>)
}
