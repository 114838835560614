import React, { useEffect } from 'react'
import { callTeamTodayByCreatedateReport, extractTeamAgent, calcHierarchyEle, buildHierarchy, calcHierarchy, sumHierarchy } from '../../../services/reports/TeamTodayReportService'
import { resetWindow } from '../../../services/UtilService'
import TeamTodayReportComponent from '../TeamTodayReport/TeamTodayReportComponent'

const TeamDistTodayReport = (props) => {

    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>
        <TeamTodayReportComponent
            buildHierarchy={buildHierarchy}
            sumHierarchy={sumHierarchy}
            calcHierarchy={calcHierarchy}
            extractTeam={extractTeamAgent}
            calcHierarchyEle={calcHierarchyEle}
            callTeamReport={callTeamTodayByCreatedateReport}>
        </TeamTodayReportComponent>
    </React.Fragment>
}

export default TeamDistTodayReport