const DS_RESCUE_SUMMARY = {
    "columns": [
        { "column": "_day", "title": "day", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_9-pending", "title": "9-pending", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
        { "column": "_1-new", "title": "1-new", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
        { "column": "_2-intransit", "title": "2-intransit", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
        { "column": "_3-delivering", "title": "3-delivering", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
        { "column": "_4-returning", "title": "4-returning", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
        { "column": "_0-unknown", "title": "0-unknown", "width": "14%", "thClassName": "text-center", "tdClassName": "text-center pointer" },
    ]
}
const DS_RESCUE_DETAIL = {
    "columns": [
        { "column": "_no", "title": "No.", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_doId", "title": "Do ID", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_trackingCode", "title": "Tracking Code", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_ffm", "title": "FFM", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_lm", "title": "LM", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_packageName", "title": "Package Name", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_amount", "title": "Amount", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_customerName", "title": "Customer", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_customerPhone", "title": "Phone", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_province", "title": "Province", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_createdate", "title": "Create", "width": "9%", "thClassName": "text-center", "tdClassName": "text-center" },
    ]
}
const DS_RESCUE_POSTBACK = {
    "columns": [
        { "column": "_date", "title": "Date", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_status", "title": "Status.", "width": "20%", "thClassName": "text-center", "tdClassName": "text-center" },
        { "column": "_reason", "title": "Reason.", "width": "60%", "thClassName": "text-center", "tdClassName": "text-center" }
    ]
}

export {
    DS_RESCUE_SUMMARY,
    DS_RESCUE_DETAIL,
    DS_RESCUE_POSTBACK
}