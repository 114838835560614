import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callGetFindDoPostback, callGetFindDoStatusDetail, callGetFindDoStatusDetailXlsx } from '../../../services/reports/RescueSummaryReportService'
import { getNumberFixed, resetWindow } from '../../../services/UtilService'
import { DS_RESCUE_DETAIL, DS_RESCUE_POSTBACK } from './RescueSummaryReportConfig'

const RescueSummaryReportDetail = (props) => {
    const params = useParams()
    const _query = { keyword: undefined }
    const _dsPb = { rows: undefined }
    const [ds, setDs] = useState([])
    const [dsPb, setDsPb] = useState(_dsPb)
    const [query, setQuery] = useState(_query)
    const [selected, setselected] = useState({ code: undefined })

    const getFilteredDs = (arr, condi) => {
        const rslt = arr.filter(row => {
            let isValid = true
            let val
            const kw = condi.keyword
            const flds = ["doId", "trackingCode", "ffm", "lm", "package_name", "amount"]

            if (kw !== undefined && kw.length > 0) {
                isValid = false
                flds.forEach(fld => {
                    val = isNaN(row[fld]) ? row[fld] : (row[fld] === undefined || row[fld] === null ? "" : (row[fld]).toString())

                    if (val) {
                        isValid = isValid || val.toLocaleLowerCase().indexOf(kw.toLocaleLowerCase()) > -1
                    }
                })
            }

            return isValid
        })

        return rslt ? rslt : []
    }

    const onTraingSelected = (row) => {
        const tracking = row.trackingCode
        const fldMap = {
            date: ["TransactionDate", "datetime"],
            status: ["Status", "code"],
            reason: ["StatusText", "status_name"]
        }

        setselected({ code: tracking })
        Promise.all([callGetFindDoPostback(tracking)]).then(resp => {
            let rslt = resp[0]

            rslt = rslt ? rslt.map(row => {
                let jo = {}

                try {
                    jo = JSON.parse(row.pbAll.replaceAll('\\"', '"'))
                } catch (err) {
                    console.log(err)
                }

                return jo
            }) : []

            rslt = rslt.map(row => {
                const pb = {}

                Object.keys(fldMap).forEach(fld => {
                    let val = undefined

                    fldMap[fld].forEach(pnFld => {
                        if (row[pnFld]) {
                            val = row[pnFld]
                        }
                    })

                    pb[fld] = val
                })

                if (!pb.reason) {
                    pb.reason = "<div><pre>" + JSON.stringify(row, null, 2) + "</pre></div>"
                }

                return {
                    _date: { __html: pb.date ? pb.date : "-" },
                    _status: { __html: pb.status ? pb.status : "-" },
                    _reason: { __html: pb.reason }
                }
            })
            console.log(rslt)

            setDsPb({ rows: rslt })
        })
    }

    useEffect(() => {
        Promise.all([callGetFindDoStatusDetail(params.statusCase, params.days)]).then(resp => {
            const rslt = resp[0]

            setDs(rslt.map((row, idx) => {
                return {
                    "_no": { __html: idx + 1 },
                    "_doId": { __html: row["doId"] },
                    "_trackingCode": { __html: row["trackingCode"] },
                    "_ffm": { __html: row["ffm"] },
                    "_lm": { __html: row["lm"] },
                    "_packageName": { __html: row["packageName"] },
                    "_amount": { __html: getNumberFixed(row["amount"], 0) },

                    "_customerName": { __html: row["customerName"] },
                    "_customerPhone": { __html: row["customerPhone"] },
                    "_province": { __html: row["province"] },
                    "_createdate": { __html: new Date(row["createdate"]).toISOString().substring(0, 19).replaceAll("T", " ") },

                    ...row
                }
            }))
        })
        resetWindow()
    }, [params])

    return <React.Fragment>
        <h4 className="mt-3">
            {params.statusCase} - {params.days}[{ds.length} row(s)]
            <button className="btn btn-success ml-3" onClick={(e) => callGetFindDoStatusDetailXlsx(params.statusCase, params.days)}>Download</button>
        </h4>
        <div className="row my-3">
            <div className="col-12">
                <input type="text" className="form-control"
                    value={query.keyword}
                    onChange={(e) => { setQuery({ keyword: e.target.value }) }}
                ></input>
            </div>
        </div>
        {ds && (<TableComponent
            className="col-12"
            dataset={DS_RESCUE_DETAIL}
            dataSource={getFilteredDs(ds, query)}
            onTrClick={onTraingSelected}>
        </TableComponent>)}

        {dsPb.rows !== undefined && <div className="static-modal">
            <div className="static-modal-content">
                <div className="container">
                    {/* {JSON.stringify(dsPb)} */}
                    <button className="btn btn-danger float-right"
                        onClick={e => setDsPb(_dsPb)}>
                        <i className="fa fa-window-close"></i>
                    </button>
                    <h4>{selected.code}</h4>
                    <div className="pre-scrollable">
                        <TableComponent
                            className="col-12"
                            tbodyClassName="big"
                            dataset={DS_RESCUE_POSTBACK}
                            dataSource={dsPb.rows}>
                        </TableComponent>
                    </div>
                </div>
            </div>
        </div>}
    </React.Fragment>
}

export default RescueSummaryReportDetail