import { NORM_PROPERTIES } from "../../../services/reports/PropertyConvertion"
import { getNumberFixed, getNumberWithCommas, getValWithPercentHtml } from "../../../services/UtilService"

const buildHierarchy = (arr, cat, lvl) => {
    let data = {}
    let teamId, teamName, cpId, userId
    let lvlKey, lvlLabel
    let key1, key2, key3
    let lbl1, lbl2, lbl3

    arr.filter(row => row.cpCat === cat).forEach(row => {
        teamName = row.teamName ? row.teamName : "No team"
        cpId = row.cpId
        userId = row.userId
        teamId = row.teamId ? row.teamId : 0

        lvlKey = {
            team: teamId,
            campaign: cpId,
            agent: userId
        }
        lvlLabel = {
            team: teamName,
            campaign: row.cpName,
            agent: row.userName
        }

        key1 = lvlKey[lvl[0]]
        key2 = lvlKey[lvl[1]]
        key3 = lvlKey[lvl[2]]
        lbl1 = lvlLabel[lvl[0]]
        lbl2 = lvlLabel[lvl[1]]
        lbl3 = lvlLabel[lvl[2]]
        
        if (!data[key1]) {
            data[key1] = {
                key: [key1],
                name: lbl1
            }
        }

        if (!data[key1][key2]) {
            data[key1][key2] = {
                key: [key1, key2],
                name: lbl2,
                campaign: []
            }
        }

        row.key = [key1, key2, key3]
        row.name = lbl3
        data[key1][key2].campaign.push(row)
    })

    return data
}

const sumHierarchy = (data) => {
    let myFields
    let sumFields

    Object.keys(data).forEach(key1 => {
        Object.keys(data[key1]).filter(fld => ["key", "name"].indexOf(fld) === -1).forEach(key2 => {
            if (!sumFields) {
                myFields = Object.keys(data[key1][key2].campaign[0])
                sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld) > -1).map(fld => NORM_PROPERTIES[fld])
                    .filter(fld => myFields.indexOf(fld) > -1)
            }
        })
    })

    Object.keys(data).filter(fld => !isNaN(fld)).forEach(key1 => {
        sumFields.forEach(field => {
            data[key1][field] = 0
        })

        Object.keys(data[key1]).filter(fld => !isNaN(fld)).forEach(key2 => {

            sumFields.forEach(field => {
                data[key1][key2][field] = 0
            })

            data[key1][key2].campaign.forEach(cp => {

                sumFields.forEach(field => {
                    data[key1][field] += cp[field] ? cp[field] : 0
                    data[key1][key2][field] += cp[field] ? cp[field] : 0
                })
            })
        })
    })
}

const calcHierarchy = (data) => {
    Object.keys(data).filter(fld => !isNaN(fld)).forEach(key1 => {

        calcHierarchyEle(data[key1])

        Object.keys(data[key1]).filter(fld => !isNaN(fld)).forEach(key2 => {

            calcHierarchyEle(data[key1][key2])

            data[key1][key2].campaign.forEach(cp => {
                calcHierarchyEle(cp)
            })
        })
    })
}

const calcHierarchyEle = (_en) => {
    const _totalLead = _en.totalLead
    const _new = _en.new
    const _approved = _en.approved
    const _rejected = _en.rejected
    const _trash = _en.trash
    const _urgent = _en.urgent
    const _delivered = _en.delivered
    const _callback = _en.callback
    const _uncall = _en.uncall
    const _totalSoAmount = _en.totalSoAmount
    const _delivSaleOrder = _en.delivSaleOrder
    const _delivAmount = _en.delivAmount
    const _usr = _en.name.split("@")[0]
    let _ear = _approved * 100 / (_approved + _rejected + _callback)
    let _aov = _totalSoAmount / _approved
    let _daov = _delivAmount / _delivSaleOrder

    _ear = isNaN(_ear) ? 0 : _ear
    _aov = isNaN(_aov) ? 0 : _aov
    _daov = isNaN(_daov) ? 0 : _daov

    _en["_name"] = { "__html": _usr }
    _en["_leads"] = { "__html": getNumberWithCommas(_totalLead) + "" }
    _en["_sale"] = { "__html": getNumberWithCommas(_totalSoAmount) }
    _en["_new"] = { "__html": getValWithPercentHtml(_new, _totalLead) }
    _en["_approved"] = { "__html": getValWithPercentHtml(_approved, _totalLead) }
    _en["_ear"] = { "__html": getNumberFixed(_ear, 0) + "%" }
    _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
    _en["_rejected"] = { "__html": getValWithPercentHtml(_rejected, _totalLead) }
    _en["_callback"] = { "__html": getValWithPercentHtml(_callback, _totalLead) }
    _en["_uncall"] = { "__html": getValWithPercentHtml(_uncall, _totalLead) }
    _en["_trash"] = { "__html": getValWithPercentHtml(_trash, _totalLead) }
    _en["_urgent"] = { "__html": getValWithPercentHtml(_urgent, _totalLead) }
    _en["_deliver"] = { "__html": getValWithPercentHtml(_delivered, _totalLead) }
    _en["_dsale"] = { "__html": getNumberFixed(_delivAmount, 0) }
    _en["_dr"] = { "__html": getValWithPercentHtml(_delivSaleOrder, _approved) }
    _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
    _en["ear"] = _ear
    _en["aov"] = _aov
    _en["daov"] = _daov
}

const extractTeam = (ds, t0, sort, sortIdx) => {

    return Object.keys(ds[t0]).filter(fld => !isNaN(fld)).sort((ele1, ele2) => {
        const sortField = sortIdx[sort.field]
        const dsLv2 = ds[t0]
        const val1 = dsLv2[ele1][sortField]
        const val2 = dsLv2[ele2][sortField]
        let diff

        if (isNaN(val1) || isNaN(val2)) {
            diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
        } else {
            diff = val1 - val2
        }

        return sort.direction === "asc" ? diff : diff * -1
    }).map(ct0 => {
        const dsLv2 = ds[t0][ct0]
        const campaigns = dsLv2.campaign.sort((ele1, ele2) => {
            const sortField = sortIdx[sort.field]
            const val1 = ele1[sortField]
            const val2 = ele2[sortField]
            let diff

            if (isNaN(val1) || isNaN(val2)) {
                diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
            } else {
                diff = val1 - val2
            }

            return sort.direction === "asc" ? diff : diff * -1
        })

        return [dsLv2, ...campaigns]
    }).reduce((prCt, nxCt) => prCt.concat(nxCt), [])
}

export {
    buildHierarchy,
    sumHierarchy,
    calcHierarchy,
    calcHierarchyEle,
    extractTeam
}