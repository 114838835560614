import { ROLE_ADV, ROLE_DIRECTOR, ROLE_MANAGER } from "../../config/CommonConfig"
import { URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V2, URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V3 } from "../../config/DomainConfig"
import { getNumberWithCommas } from "../UtilService"

const { default: Axios } = require("axios")
const role = localStorage.getItem("role")

const callGetStatisticReportCampaignOverviewV2 = async payload => {
    const resp = await Axios.get(URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V2() + payload.agentType + '&dateType=' + payload.typeDate + '&fromDate=' + payload.dateFrom + '&toDate=' + payload.dateTo)

    if (resp && resp.data && resp.data.campaignOverviewV2Entities) {     
        let data = [[], ''], dataObject = {}
        resp.data.campaignOverviewV2Entities.forEach(element => {
            dataObject = {
                campaignName: element.campaignName,
                totalLead: getNumberWithCommas(element.totalLead),
                'totalNewLead%': [getNumberWithCommas(element.totalNewLead), element.totalNewLeadPercent],
                totalUncall12Lead: getNumberWithCommas(element.totalUncall12Lead),
                totalUncall35Lead: getNumberWithCommas(element.totalUncall35Lead),
                totalUncall68Lead: getNumberWithCommas(element.totalUncall68Lead),
                totalUncall9Lead: getNumberWithCommas(element.totalUncall9Lead),
                'totalUncallLead%': [getNumberWithCommas(element.totalUncallLead),element.totalUncallLeadPercent],
                totalPotentialCallbackLead: getNumberWithCommas(element.totalPotentialCallbackLead),
                totalConsultingCallbackLead: getNumberWithCommas(element.totalConsultingCallbackLead),
                totalNotProspectCallbackLead: getNumberWithCommas(element.totalNotProspectCallbackLead),
                'totalCallbackLead%': [getNumberWithCommas(element.totalCallbackLead), element.totalCallbackLeadPercent],
                'totalUrgentLead%': [getNumberWithCommas(element.totalUrgentLead), element.totalUrgentLeadPercent],
                totalNewSo: getNumberWithCommas(element.totalNewSo),
                totalValidatedSo: getNumberWithCommas(element.totalValidatedSo),
                totalPendingSo: getNumberWithCommas(element.totalPendingSo),
                totalDelaySo: getNumberWithCommas(element.totalDelaySo),
                totalCancelSo: getNumberWithCommas(element.totalCancelSo),
                'totalApprovedLead%': [getNumberWithCommas(element.totalApprovedLead),element.totalApprovedPercent],
                'totalRejectedLead%': [getNumberWithCommas(element.totalRejectedLead),element.totalRejectedLeadPercent],
                'totalTrashLead%': [getNumberWithCommas(element.totalTrashLead),element.totalTrashLeadPercent],
                'totalCloseLead%': [getNumberWithCommas(element.totalClosedLead), element.totalClosedLeadPercent],
                'arQa%': [getNumberWithCommas(element.arQa),element.arQaPercent],
                // 'arQc%': [getNumberWithCommas(element.arQc),element.arQcPercent || 0],
            }

            if([ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role)) {
                dataObject['arQc%'] = [getNumberWithCommas(element.arQc),element.arQcPercent]
            }

            data[0].push(dataObject)
        });
        data[1] = resp.data.created
        return data
    } else {
        return []
    }
}

const callGetStatisticReportCampaignOverviewV3 = async payload => {
    const resp = await Axios.get(URL_GET_STATISTIC_REPORT_CAMPAIGN_OVERVIEW_V3() + payload.agentType + '&dateType=' + payload.typeDate + '&fromDate=' + payload.dateFrom + '&toDate=' + payload.dateTo)

    if (resp && resp.data && resp.data.campaignOverviewV3Entities) {     
        let data = [[], ''], dataObject = {}
        resp.data.campaignOverviewV3Entities.forEach(element => {
            dataObject = {
                username: element.username,
                teamName: element.teamName,
                totalLead: getNumberWithCommas(element.totalLead),
                'totalNewLead%': [getNumberWithCommas(element.totalNewLead), element.totalNewLeadPercent],
                totalUncall12Lead: getNumberWithCommas(element.totalUncall12Lead),
                totalUncall35Lead: getNumberWithCommas(element.totalUncall35Lead),
                totalUncall68Lead: getNumberWithCommas(element.totalUncall68Lead),
                totalUncall9Lead: getNumberWithCommas(element.totalUncall9Lead),
                'totalUncallLead%': [getNumberWithCommas(element.totalUncallLead),element.totalUncallLeadPercent],
                totalPotentialCallbackLead: getNumberWithCommas(element.totalPotentialCallbackLead),
                totalConsultingCallbackLead: getNumberWithCommas(element.totalConsultingCallbackLead),
                totalNotProspectCallbackLead: getNumberWithCommas(element.totalNotProspectCallbackLead),
                'totalCallbackLead%': [getNumberWithCommas(element.totalCallbackLead), element.totalCallbackLeadPercent],
                'totalUrgentLead%': [getNumberWithCommas(element.totalUrgentLead), element.totalUrgentLeadPercent],
                totalNewSo: getNumberWithCommas(element.totalNewSo),
                totalValidatedSo: getNumberWithCommas(element.totalValidatedSo),
                totalPendingSo: getNumberWithCommas(element.totalPendingSo),
                totalDelaySo: getNumberWithCommas(element.totalDelaySo),
                totalCancelSo: getNumberWithCommas(element.totalCancelSo),
                'totalApprovedLead%': [getNumberWithCommas(element.totalApprovedLead),element.totalApprovedPercent],
                'totalRejectedLead%': [getNumberWithCommas(element.totalRejectedLead),element.totalRejectedLeadPercent],
                'totalTrashLead%': [getNumberWithCommas(element.totalTrashLead),element.totalTrashLeadPercent],
                'totalCloseLead%': [getNumberWithCommas(element.totalClosedLead), element.totalClosedLeadPercent],
                'arQa%': [getNumberWithCommas(element.arQa),element.arQaPercent],
                // 'arQc%': [getNumberWithCommas(element.arQc),element.arQcPercent || 0],
            }

            if([ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role)) {
                dataObject['arQc%'] = [getNumberWithCommas(element.arQc),element.arQcPercent]
            }

            data[0].push(dataObject)
        });
        data[1] = resp.data.created
        console.log('data',data);
        return data
    } else {
        return []
    }
}

export {
    callGetStatisticReportCampaignOverviewV2,
    callGetStatisticReportCampaignOverviewV3
}
