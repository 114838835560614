const DS_AGENT_TODAY_V2_BY_CREATEDATE = {
    "columns": [
        { "column": "_hour", "title": "Hour", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_sale", "title": "Sale", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_leads", "title": "Leads", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_new", "title": "New", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_approved", "title": "Approved", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_arqa", "title": "AR_QA", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ear", "title": "e-AR ", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_aov", "title": "AOV", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_rejected", "title": "Rejected", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_callback", "title": "Callback", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_uncall", "title": "Uncall", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_trash", "title": "Trash", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_urgent", "title": "Urgent", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
        { "column": "_ucatmp", "title": "UC_ATMP", "width": "5%", "thClassName": "text-center", "tdClassName": "text-center", "sort": true },
    ]
}

export {
    DS_AGENT_TODAY_V2_BY_CREATEDATE,
}