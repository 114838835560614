import React, { useState } from 'react'
import { SIDE_NAV_MENUS } from '../../config/SideNavConfig'
import { getPermissions } from '../../services/AuthenticateService'
import SideNavMenuComponent from './SideNavMenuComponent'
import { callApi } from '../../services/UtilService'
import { getStorage, setStorage } from '../../services/StorageService'
import { STORAGE_MENU_ORG } from '../../config/StorageConfig'

export default (props) => {
    const permissions = getPermissions()
    const storageMnuOrg = getStorage(STORAGE_MENU_ORG)
    const [mnuOrg, setMnuOrg] = useState({ ...storageMnuOrg })

    const checkPermission = (allow, permissions, path, children) => {
        let rslt = false

        if (path) {
            rslt = allow === "all"
            rslt = rslt || permissions.indexOf(path) > -1
        } else if (children && children.length > 0) {
            children.forEach(ele => {
                rslt = rslt || checkPermission(allow, permissions, ele.path, ele.children)
            })
        }

        return rslt
    }

    const toggleMenu = (e, grp) => {
        e.preventDefault()
        if (mnuOrg[grp]) {
            mnuOrg[grp] = false
        } else {
            mnuOrg[grp] = true
        }

        setStorage(STORAGE_MENU_ORG, { ...mnuOrg })
        setMnuOrg({ ...mnuOrg })

    }

    return (<div id="layoutSidenav_nav">
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav pt-3">
                    {SIDE_NAV_MENUS
                        .filter(grp => permissions.indexOf(grp.id.replaceAll("isgn_", "")) > -1 || grp.allow === "all")
                        .map(grp => (
                            <React.Fragment key={grp.id}>
                                {grp.text && <div className="sb-sidenav-menu-heading">
                                    <a className="nav-link" href="/#" onClick={e => toggleMenu(e, grp.id)}>
                                        {grp.text}
                                        {mnuOrg[grp.id] && <i className="fa fa-angle-up ml-3"></i>}
                                        {!mnuOrg[grp.id] && <i className="fa fa-angle-down ml-3"></i>}
                                    </a>
                                </div>}

                                {(mnuOrg[grp.id] || (grp.id === "99" && mnuOrg[grp.id] !== false) || grp.allow === "all") && grp.children
                                    .map(menu => (<SideNavMenuComponent key={menu.id}
                                        id={menu.id}
                                        checkPermission={checkPermission}
                                        callApi={callApi}
                                        permissions={permissions}
                                        mnuOrg={mnuOrg}
                                        toggleMenu={toggleMenu}
                                        {...menu}>
                                    </SideNavMenuComponent>))}

                            </React.Fragment>
                        ))}
                </div>
            </div>
            {/* <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                  Insight
              </div> */}
        </nav>
    </div>)
}
