import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callAgentTodayReport } from '../../../services/reports/AgentTodayReportService'
import { getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import { DS_AGENT_TODAY } from './AgentTodayConfig'

export default (props) => {
    const [sort, setsort] = useState({ "field": "_sale", "direction": "desc" })
    const agentTypes = [{ "key": "1", "label": "Fresh Campaign" }, { "key": "2", "label": "Resell Campaign" }, { "key": "0", "label": "All Campaign"}]
    const [agentType, setagentType] = useState(1)
    const [ds, setDs] = useState([])
    const onToggleClick = (e, cpId) => {
        e && e.preventDefault();

        const btnToggle = document.getElementById("btnToggleCp" + cpId)
        const isActive = btnToggle.className.indexOf("active") === -1
        const table = document.getElementById("tableCp" + cpId)

        if (isActive) {
            table.className = table.className.replaceAll(" d-none", "")
            btnToggle.className += " active"
        } else {
            table.className += " d-none"
            btnToggle.className = btnToggle.className.replaceAll(" active", "")
        }
    }

    const onSortHandler = (rslt) => {
        setsort(rslt)
    }

    const sortList = (a, b) => {

        if (a["_name"]["__html"] === "TOTAL") {
            return -1
        } else if (b["_name"]["__html"] === "TOTAL") {
            return 1
        }

        let val1 = a[sort.field]["__html"] ? a[sort.field]["__html"] : a[sort.field],
            val2 = b[sort.field]["__html"] ? b[sort.field]["__html"] : b[sort.field]
        let diff

        if (sort.field === "_name" || sort.field === "_team") {
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } 
        else if (sort.field === "_uncallAvgRing" || sort.field === "_avgtalktime") {
            val1 = val1.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            val2 = val2.replaceAll("h", "").replaceAll("m", "").replaceAll(" ", "")
            diff = sort.direction === "asc" ? val1.localeCompare(val2) : val2.localeCompare(val1)
        } else {
            val1 = parseInt(val1.replaceAll(",", "").split("(")[0].trim())
            val2 = parseInt(val2.replaceAll(",", "").split("(")[0].trim())
            diff = sort.direction === "asc" ? val1 - val2 : val2 - val1
        }

        return diff
    }

    useEffect(() => {
        Promise.all([callAgentTodayReport({})]).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const data = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
            // eslint-disable-next-line array-callback-return
            const filtered = data.filter(item => {
                if(agentType === 1){
                    return item.cpCat === 1;
                }else if(agentType === 2){
                    return item.cpCat === 2;
                }else if(agentType === 0){
                    return true;
                }
            });
            setDs(filtered)
            document.getElementById("redisDateTab").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
        })
        resetWindow()
    }, [agentType])

    return <React.Fragment>
        <div className="row justify-content-end mb-2 mr-0 selectCustom">
            <select 
            value={agentType} 
            onChange={e => { setagentType(Number(e.target.value)) }} >
                {agentTypes.map(agtType => <option key={agtType.key} value={agtType.key}>{agtType.label}</option>)}
            </select>
        </div>
        {ds && ds.map((cp, idx) => (<div key={cp.id} className={"cp_catt_" + cp.cpCat}>
            <h4 className="mt-2">
                {ds.indexOf(cp) + 1}. {cp.name}
                <a id={"btnToggleCp" + cp.id} className="btn-toggle ml-3 active" href="#next" onClick={e => onToggleClick(e, cp.id)}>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                </a>
            </h4>
            <div className='table-Wrap'>
                <TableComponent
                    tableProps={{ id: "tableCp" + cp.id }}
                    className="mt-2 w-100 table-no-pad"
                    theadClassName="big"
                    tbodyClassName="big"
                    dataset={DS_AGENT_TODAY}
                    dataSource={cp.todayList.sort(sortList)}
                    sort={sort}
                    onSortHandler={onSortHandler}>
                </TableComponent>
            </div>
        </div>))}

        <div className="row"><div id="redisDateTab" className="col-12 small text-right"></div></div>
    </React.Fragment>
}
