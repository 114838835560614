import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { callAgentTodayByCreatedateReport } from '../../../services/reports/AgentTodayReportService'
import { getNumberFixed, getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import './LeadDistChartToday.css'

export default (props) => {
    const [zoom, setzoom] = useState(undefined)
    const classActive = "cp-chart col-6", classInactive = "cp-chart col-6 d-none"
    const pageSize = 4
    const options = {
        scales: {
            yAxes: [
                {
                    stacked: false,
                    ticks: {
                        beginAtZero: true
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    maxBarThickness: 30,
                    ticks: {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90
                    }
                },
            ],
        }
    }
    const [dsAov, setdsAov] = useState([])
    const [ds, setDs] = useState([])
    const onNextClick = (e, direct) => {
        e.preventDefault();

        const clsHide = " d-none"
        const arr = document.getElementsByClassName("cp-chart")
        let visible = -1
        let page
        let totPage = Math.ceil(arr.length / pageSize)

        for (let i = 0; i < arr.length && visible === -1; i++) {
            if (arr[i].className.indexOf(clsHide) === -1) {
                visible = i + 1
            }
        }

        page = Math.ceil(visible / pageSize)

        if (direct === -1) {
            page = page === 1 ? totPage : page - 1
        } else {
            page = page === totPage ? 1 : page + 1
        }

        for (let i = 0; i < arr.length; i++) {
            if (Math.ceil((i + 1) / pageSize) === page) {
                arr[i].className = classActive
            } else {
                arr[i].className = classInactive
            }
        }
    }

    const onZoomClick = (idx) => {
        setzoom(idx)
    }

    useEffect(() => {
        Promise.all([callAgentTodayByCreatedateReport({})]).then(resp => {
            const reportInsgAgentResponse = resp[0].reportInsgAgentResponse
            const rslt = reportInsgAgentResponse ? reportInsgAgentResponse.campaigns : []
            let ds

            if (rslt) {
                document.getElementById("redisDateChart").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
                ds = rslt.map(cp => {
                    if (cp.todayByCreatedateList) {
                        cp.todayByCreatedateList = cp.todayByCreatedateList.filter(usr => usr.userName)
                    }

                    cp.todayByCreatedateList && cp.todayByCreatedateList.sort((a, b) => b.dayApproved - a.dayApproved)
                    return {
                        "id": cp.id,
                        "name": cp.name,
                        "cpCat": cp.cpCat,
                        "data": {
                            "labels": cp.todayByCreatedateList.map(usr => usr.userName.split("@")[0].substr(0, 12)),
                            "datasets": [
                                {
                                    label: "Delivered",
                                    data: cp.todayByCreatedateList.map(usr => usr.dayDelivSaleOrder),
                                    backgroundColor: 'rgb(0, 204, 102)',
                                },
                                {
                                    label: "SO",
                                    data: cp.todayByCreatedateList.map(usr => usr.daySaleOrder),
                                    backgroundColor: 'rgb(51, 51, 255)',
                                },
                                {
                                    label: "Approved",
                                    data: cp.todayByCreatedateList.map(usr => usr.dayApproved),
                                    backgroundColor: 'rgb(255, 153, 51)',
                                },
                                {
                                    label: "Leads",
                                    data: cp.todayByCreatedateList.map(usr => usr.totalDayLead),
                                    backgroundColor: 'rgb(153, 0, 0)',
                                }
                            ]
                        }
                    }
                })

                setDs(ds)
                setdsAov(rslt.map(cp =>
                    cp.todayByCreatedateList.map(usr => getNumberFixed(usr.dayTotalSoAmount / usr.dayApproved, 0))
                ))
            }
        })
        resetWindow()
    }, [])

    return <React.Fragment>
        <div className={"row" + (zoom === undefined ? "" : " d-none")}>
            {ds && ds.map((cp, idx) => {
                // https://www.chartjs.org/docs/latest/configuration/animations.html#animation-callbacks
                // https://stackoverflow.com/questions/42556835/show-values-on-top-of-bars-in-chart-js
                const _options = {
                    ...options,
                    animation: {
                        duration: 1,
                        onComplete: (animation) => {
                            let ctx = animation.chart.ctx
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            animation.chart.data.datasets.forEach(function (dataset, i) {
                                var meta = animation.chart.controller.getDatasetMeta(i);
                                meta.data.forEach(function (bar, index) {
                                    if (i === 3) {
                                        ctx.fillText(dsAov[idx][index], bar._model.x, bar._model.y - 5);
                                    }
                                });
                            });
                        }
                    }
                }

                return (<div key={cp.id} className={(0 <= idx && idx <= 3 ? classActive : classInactive)}>
                    <h4 className="mt-2">
                        {ds.indexOf(cp) + 1}. {cp.name}
                        <button className="btn btn-default" onClick={e => onZoomClick(idx)}><i className="fa fa-search-plus"></i></button>
                    </h4>
                    <Bar
                        data={cp.data}
                        options={_options}>
                    </Bar>
                </div>)
            }
            )}
            {ds.length > 4 && <div className="btn-float-box">
                <a id="btnBack" className="btn-float btn-primary" href="#next" onClick={e => onNextClick(e, -1)}>
                    <i className="fas fa-arrow-left"></i>
                </a>
                <a id="btnNext" className="btn-float btn-primary" href="#next" onClick={e => onNextClick(e, 1)}>
                    <i className="fas fa-arrow-right"></i>
                </a>
            </div>}
        </div>

        {zoom !== undefined && [ds[zoom]].map((cp, idx) => {
            const _options = {
                ...options,
                animation: {
                    duration: 1,
                    onComplete: (animation) => {
                        let ctx = animation.chart.ctx
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                        animation.chart.data.datasets.forEach(function (dataset, i) {
                            var meta = animation.chart.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                if (i === 3) {
                                    ctx.fillText(dsAov[idx][index], bar._model.x, bar._model.y - 5);
                                }
                            });
                        });
                    }
                }
            }

            return (<div key={zoom}>
                <h4 className="mt-2">
                    {ds.indexOf(cp) + 1}. {cp.name}
                    <button className="btn btn-default" onClick={e => onZoomClick(undefined)}><i className="fa fa-search-minus"></i></button>
                </h4>
                <Bar
                    data={cp.data}
                    options={_options}>
                </Bar>
            </div>)
        }
        )}

        <div className="row"><div id="redisDateChart" className="col-12 small text-right"></div></div>
    </React.Fragment>
}
