import {
    URL_HS_POST_REPORT_CAMPAIGN_BATCH_OVERVIEW, 
    URL_HS_POST_REPORT_CAMPAIGN_OVERVIEW
} from "../../config/DomainConfig"
import { getNumberFixed, getNumberWithCommas, getSpan } from "../UtilService"

const { default: Axios } = require("axios")

const callPostHSReportCampaignOverview = async payload => {
    const resp = await Axios.post(URL_HS_POST_REPORT_CAMPAIGN_OVERVIEW(), payload)

    if (resp && resp.data && resp.data.campaignOverviewEntities) {
        resp.data.campaignOverviewEntities.forEach(row => {
            row['_name'] = { "__html": row.name }
            row['_totalLeads'] = { "__html": getNumberWithCommas(row['totalLeads']) }
            row['_totalNew'] = { "__html": getNumberWithCommas(row['totalNew']) } //+ '(' + getNumberFixed(row['totalNew'] * 100 / row['totalLeads'], 0) + '%)'
            row['_aov'] = { "__html": getNumberFixed(row['totalAmount'] / row['approved'], 0) }
            row['_ear'] = { "__html": '<span class="percent">' + getNumberFixed(row['approved'] * 100 / (row['approved'] + row['callback'] + row['rejected']), 0) + '%</span>' }
            row['_approved'] = { "__html": getSpan(getNumberWithCommas(row['approved']), 'text-success') + '<span class="percent">(' + getNumberFixed(row['approved'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_arqa'] = { "__html": getNumberWithCommas(row['validated']) + '<span class="percent">(' + getNumberFixed(row['validated'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_uncall'] = { "__html": getNumberWithCommas(row['uncall']) + '<span class="percent">(' + getNumberFixed(row['uncall'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_trash'] = { "__html": getSpan(getNumberWithCommas(row['trash']), 'text-info') + '<span class="percent">(' + getNumberFixed(row['trash'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_rejected'] = { "__html": getNumberWithCommas(row['rejected']) + '<span class="percent">(' + getNumberFixed(row['rejected'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_callback'] = { "__html": getNumberWithCommas(row['callback']) + '<span class="percent">(' + getNumberFixed(row['callback'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            row['_urgent'] = { "__html": getNumberWithCommas(row['urgent']) + '<span class="percent">(' + getNumberFixed(row['urgent'] * 100 / row['totalLeads'], 0) + '%)</span>' }
        })
        return resp.data
    } else {
        return []
    }

}
const callPostHSReportCampaignBatchOverview = async payload => {
    const resp = await Axios.post(URL_HS_POST_REPORT_CAMPAIGN_BATCH_OVERVIEW(), payload)
    const valFields = ['totalLeads', 'totalAmount', 'totalNew',
        'approved', 'uncall', 'trash',
        'rejected', 'callback', 'urgent',
        'validated']

    if (resp && resp.data && resp.data.campaignOverviewEntities) {
        const raws = resp.data.campaignOverviewEntities
        const cpRaws = raws.reduce((acc, cur) => {

            const srchs = acc.filter(row => row.name === cur.name)

            if (srchs && srchs.length > 0) {
                srchs.forEach(srch => {
                    valFields.forEach(fld => {
                        srch[fld] += cur[fld]
                    })
                })
            } else {
                acc.push({ ...cur })
            }

            return acc
        }, [])
        const cpRows = cpRaws.map(row => {
            const rslt = { ...row }

            rslt['grp'] = 1
            rslt['trClassName'] = 'row-level-3 big cursor-pointer'
            rslt['_name'] = { "__html": (row['name']) }
            rslt['_totalLeads'] = { "__html": getNumberWithCommas(row['totalLeads']) }
            rslt['_totalNew'] = { "__html": getNumberWithCommas(row['totalNew']) } //+ '(' + getNumberFixed(row['totalNew'] * 100 / row['totalLeads'], 0) + '%)'
            rslt['_aov'] = { "__html": getNumberFixed(row['totalAmount'] / row['approved'], 0) }
            rslt['_ear'] = { "__html": '<span class="percent">' + getNumberFixed(row['approved'] * 100 / (row['approved'] + row['callback'] + row['rejected']), 0) + '%</span>' }
            rslt['_approved'] = { "__html": getSpan(getNumberWithCommas(row['approved']), 'text-success') + '<span class="percent">(' + getNumberFixed(row['approved'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_arqa'] = { "__html": getNumberWithCommas(row['validated']) + '<span class="percent">(' + getNumberFixed(row['validated'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_uncall'] = { "__html": getNumberWithCommas(row['uncall']) + '<span class="percent">(' + getNumberFixed(row['uncall'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_trash'] = { "__html": getSpan(getNumberWithCommas(row['trash']), 'text-info') + '<span class="percent">(' + getNumberFixed(row['trash'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_rejected'] = { "__html": getNumberWithCommas(row['rejected']) + '<span class="percent">(' + getNumberFixed(row['rejected'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_callback'] = { "__html": getNumberWithCommas(row['callback']) + '<span class="percent">(' + getNumberFixed(row['callback'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_urgent'] = { "__html": getNumberWithCommas(row['urgent']) + '<span class="percent">(' + getNumberFixed(row['urgent'] * 100 / row['totalLeads'], 0) + '%)</span>' }

            return rslt
        })
        const batchRows = raws.map(row => {
            const rslt = { ...row }

            rslt['grp'] = 2
            rslt['trClassName'] = 'row-level-4'
            rslt['_name'] = { "__html": row['batchId'] }
            rslt['_totalLeads'] = { "__html": getNumberWithCommas(row['totalLeads']) }
            rslt['_totalNew'] = { "__html": getNumberWithCommas(row['totalNew']) } //+ '(' + getNumberFixed(row['totalNew'] * 100 / row['totalLeads'], 0) + '%)'
            rslt['_aov'] = { "__html": getNumberFixed(row['totalAmount'] / row['approved'], 0) }
            rslt['_ear'] = { "__html": '<span class="percent">' + getNumberFixed(row['approved'] * 100 / (row['approved'] + row['callback'] + row['rejected']), 0) + '%</span>' }
            rslt['_approved'] = { "__html": getSpan(getNumberWithCommas(row['approved']), 'text-success') + '<span class="percent">(' + getNumberFixed(row['approved'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_arqa'] = { "__html": getNumberWithCommas(row['validated']) + '<span class="percent">(' + getNumberFixed(row['validated'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_uncall'] = { "__html": getNumberWithCommas(row['uncall']) + '<span class="percent">(' + getNumberFixed(row['uncall'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_trash'] = { "__html": getSpan(getNumberWithCommas(row['trash']), 'text-info') + '<span class="percent">(' + getNumberFixed(row['trash'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_rejected'] = { "__html": getNumberWithCommas(row['rejected']) + '<span class="percent">(' + getNumberFixed(row['rejected'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_callback'] = { "__html": getNumberWithCommas(row['callback']) + '<span class="percent">(' + getNumberFixed(row['callback'] * 100 / row['totalLeads'], 0) + '%)</span>' }
            rslt['_urgent'] = { "__html": getNumberWithCommas(row['urgent']) + '<span class="percent">(' + getNumberFixed(row['urgent'] * 100 / row['totalLeads'], 0) + '%)</span>' }

            return rslt
        })

        return {
            campaign: cpRows,
            batch: batchRows
        }
    } else {
        return {
            campaign: [],
            batch: []
        }
    }

}

export {
    callPostHSReportCampaignOverview,
    callPostHSReportCampaignBatchOverview
}