import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { getDateFormat, resetWindow } from '../../../services/UtilService'
import { callGetFindRcCallXlsx } from '../../../services/reports/RescueSummaryReportService'
const RescueSummaryReport = (props) => {
    const [query, setquery] = useState({ valid: false, dateFrom: undefined, dateTo: undefined, keyword: undefined, cpCat: 1 })

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>
        <div className="form-row">
            <div className="form-group col-md-3">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-3">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            {query.valid && <div className="form-group col-md-3">
                <label htmlFor="fltDateTo"> </label>
                <div className="form-control border-0">
                    <button className="btn btn-success ml-3" onClick={(e) => {
                        const dateFrom = getDateFormat(query.dateFrom)
                        const dateTo = getDateFormat(query.dateTo)
                        callGetFindRcCallXlsx(dateFrom, dateTo)
                    }}>Download</button>
                </div>
            </div>}
        </div>
    </React.Fragment>
}

export default RescueSummaryReport