import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import FooterComponent from './component/FooterComponent'
import SideNavComponent from './component/SideNavComponent/SideNavComponent'
import TopNavComponent from './component/TopNavComponent'
import TopTicker from './component/TopTicker/TopTicker'
import { SIZE_L } from './config/CommonConfig'
import { SIDE_NAV_MENUS } from './config/SideNavConfig'
import { STORAGE_SIZE } from './config/StorageConfig'
import RescueSummaryReportDetail from './page/Report/RescueSummaryReport/RescueSummaryReportDetail'
import { getPermissions } from './services/AuthenticateService'
import { getStorage } from './services/StorageService'

export default (props) => {
    const location = useLocation()
    const _iniBasket = { "menus": [] }
    const [basket, setbasket] = useState(_iniBasket)

    const getExtractNav = useCallback(
        (rslt, confs, titles) => {
            for (const idx in confs) {
                const conf = confs[idx]
                const title = [...titles, conf.text]
                if (conf.path && conf.component) {
                    rslt[conf.path] = { component: conf.component, title: title, route: conf.route }
                }

                if (conf.children) {
                    getExtractNav(rslt, conf.children, title)
                }
            }
        },
        [],
    )

    const getRoute = (mnu) => {
        const topTicker = location.pathname.indexOf('/wallboard') === 0 ? <TopTicker></TopTicker> : <></>
        const routeBody = <div id="layoutSidenav">
            <SideNavComponent></SideNavComponent>
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid">
                        <div className="scroll-xmin-height-500">
                            {mnu.component}
                        </div>
                    </div>
                </main>
                <FooterComponent></FooterComponent>
            </div>
        </div>

        return <>
            {(location.pathname === mnu.path || (mnu.path !== "/" && location.pathname.indexOf(mnu.path) > 0)) && <TopNavComponent title={mnu.title}></TopNavComponent>}
            {mnu.path && <Route exact path={mnu.path}>
                {topTicker}
                {routeBody}
            </Route>}
            {mnu.route && <Route path={mnu.route}>
                {topTicker}
                {routeBody}
            </Route>}
        </>
    }

    useEffect(() => {
        const permissions = getPermissions()
        const extractedNav = {}
        const size = getStorage(STORAGE_SIZE)
        let menus = []

        getExtractNav(extractedNav, SIDE_NAV_MENUS, [])
        menus = Object.keys(extractedNav)
            .filter(path => permissions.indexOf(path) > -1)
            .map(path => { return { "path": path, "component": extractedNav[path].component, "title": extractedNav[path].title, "route": extractedNav[path].route } })

        if (menus && menus.length > 0) {
            setbasket({ "menus": menus })
        } else {
            window.location = '/login'
        }

        document.getElementById("root").className = "body" + (size ? size : SIZE_L)

    }, [getExtractNav])

    return (<React.Fragment>
        {basket.menus.map(mnu => (<React.Fragment key={mnu.path}>
            {getRoute(mnu)}
        </React.Fragment>))}
        <Route exact path="/report/rescueSummary/:org/:statusCase/:days">
            <div id="layoutSidenav">
                <SideNavComponent></SideNavComponent>
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid">
                            <RescueSummaryReportDetail></RescueSummaryReportDetail>
                        </div>
                    </main>
                    <FooterComponent></FooterComponent>
                </div>
            </div>
        </Route>
    </React.Fragment>)
}
