import React, { useEffect, useState } from 'react'
import TableComponent from '../../../component/TableComponent/TableComponent'
import { callGetFindDoPostback, callGetFindDoStatusDetail } from '../../../services/reports/RescueSummaryReportService'
import { getNumberFixed } from '../../../services/UtilService'
import { DS_RESCUE_DETAIL } from './RescueSummaryReportConfig'

const RescueSummaryReportPopup = (props) => {
    const _query = { keyword: undefined }
    const [ds, setDs] = useState([])
    const [query, setQuery] = useState(_query)

    const getFilteredDs = (arr, condi) => {
        const rslt = arr.filter(row => {
            let isValid = true
            let val
            const kw = condi.keyword
            const flds = ["doId", "trackingCode", "ffm", "lm", "package_name", "amount"]

            if (kw !== undefined && kw.length > 0) {
                isValid = false
                flds.forEach(fld => {
                    val = isNaN(row[fld]) ? row[fld] : (row[fld] === undefined || row[fld] === null ? "" : (row[fld]).toString())

                    if (val) {
                        isValid = isValid || val.toLocaleLowerCase().indexOf(kw.toLocaleLowerCase()) > -1
                    }
                })
            }

            return isValid
        })

        return rslt ? rslt : []
    }

    const getPbAll = (pbAll) => {
        let rslt = pbAll
        let jo

        try {
            jo = JSON.parse(rslt)
            if (jo.length && jo.length > 0) {
                jo = jo.map(ele => {
                    const status = ele.status

                    return status && status.status_code &&
                        status.status_desc &&
                        status.status_date &&
                        status.location ? {
                        status: {
                            status_code: status.status_code,
                            status_desc: status.status_desc,
                            status_date: status.status_date,
                            location: status.location,
                        }
                    } : ele
                })


                rslt = JSON.stringify(jo)
            } else {
                jo = jo.Status &&
                    jo.StatusText &&
                    jo.TransactionDate &&
                    jo.Category ? {
                    Status: jo.Status,
                    StatusText: jo.StatusText,
                    TransactionDate: jo.TransactionDate,
                    Category: jo.Category
                } : jo

                jo = jo.code &&
                    jo.status_name &&
                    jo.datetime ? {
                    code: jo.code,
                    status_name: jo.status_name,
                    datetime: jo.datetime
                } : jo

                jo = jo.status_code &&
                    jo.status_desc &&
                    jo.status_date ? {
                    code: jo.status_code,
                    status_name: jo.status_desc,
                    datetime: jo.status_date
                } : jo

                rslt = JSON.stringify(jo)
            }
        } catch (err) { console.log(err) }

        return rslt
    }

    const onTraingSelected = (row) => {
        const tracking = row.trackingCode

        if (row.expand) {
            row.expand = false

            ds.forEach(row => {
                if (row.trackingCode === tracking) {
                    row.expand = false
                }
            })
            setDs([...ds])
        } else {
            Promise.all([callGetFindDoPostback(tracking)]).then(resp => {
                ds.forEach(row => {
                    if (row.trackingCode === tracking) {
                        const postback = resp[0]
                        row.expand = true
                        row.expandContent = <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <a href="/#" className="float-right mr-3" onClick={e => {
                                        e.preventDefault()
                                        onTraingSelected(row)
                                    }}>hide</a>
                                </div>
                            </div>
                            {postback && postback.length > 0 ? postback.map(pb => {
                                let jsonPbAll = {}

                                try {
                                    jsonPbAll = JSON.stringify(JSON.parse(getPbAll(pb.pbAll)), null, 2)
                                } catch (ex) {
                                    console.log(ex)
                                }

                                return (<React.Fragment key={pb.postbackId}>

                                    <div className="form-group row">
                                        <label htmlFor="txtSource" className="col-md-4 col-form-label">Source</label>
                                        <div id="txtSource" className="col-md-8">{pb.source}</div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="txtTrackingCode" className="col-md-4 col-form-label">Ticket Code</label>
                                        <div id="txtTrackingCode" className="col-md-8">{pb.trackingCode}</div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="txtCreatedate" className="col-md-4 col-form-label">Create Date</label>
                                        <div id="txtCreatedate" className="col-md-8">{new Date(pb.createdate).toString()}</div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="txtPayload" className="col-md-4 col-form-label">Payload</label>
                                        <div className="col-md-8 max-width-500"><pre>{jsonPbAll}</pre></div>
                                    </div>
                                </React.Fragment>)
                            }) :

                                <div className="row"><div className="col-12 mb-3"><b>No data</b></div></div>}
                        </React.Fragment>
                    }
                })

                setDs([...ds])
            })

        }
    }

    const getTdClass = (col, row, idx) => {
        return (idx % 2) === 0 ? "white" : "gray"
    }

    useEffect(() => {
        const query = props.query
        if (query.statusCase) {
            Promise.all([callGetFindDoStatusDetail(query.statusCase, query.days)]).then(resp => {
                const rslt = resp[0]

                setDs(rslt.map((row, idx) => {
                    return {
                        "_no": { __html: idx + 1 },
                        "_doId": { __html: row["doId"] },
                        "_trackingCode": { __html: row["trackingCode"] },
                        "_ffm": { __html: row["ffm"] },
                        "_lm": { __html: row["lm"] },
                        "_packageName": { __html: row["packageName"] },
                        "_amount": { __html: getNumberFixed(row["amount"], 0) },

                        "_customerName": { __html: row["customerName"] },
                        "_customerPhone": { __html: row["customerPhone"] },
                        "_province": { __html: row["province"] },
                        "_createdate": { __html: new Date(row["createdate"]).toISOString().substring(0, 19).replaceAll("T", " ") },

                        ...row
                    }
                }))
            })
        } else {
            setDs([])
        }
    }, [props.query])

    return <React.Fragment>
        {props.query.statusCase !== undefined && <div className="static-modal">
            <div className="static-modal-content">
                <div className="container">
                    <button className="btn btn-danger float-right"
                        onClick={e => props.clear()}>
                        <i className="fa fa-window-close"></i>
                    </button>
                    <h4>{props.query.statusCase} - {props.query.days}[{props.query.count} row(s)]</h4>
                    <div className="row my-3">
                        <div className="col-12">
                            <input type="text" className="form-control"
                                value={query.keyword}
                                onChange={(e) => { setQuery({ keyword: e.target.value }) }}
                            ></input>
                        </div>
                    </div>
                    <div className="pre-scrollable">
                        <TableComponent
                            className="col-12"
                            dataset={DS_RESCUE_DETAIL}
                            dataSource={getFilteredDs(ds, query)}
                            onTrClick={onTraingSelected}
                            getTdClass={getTdClass}>
                        </TableComponent>
                    </div>
                </div>
            </div>
        </div>}

    </React.Fragment>
}

export default RescueSummaryReportPopup