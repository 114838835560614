import React, { useEffect, useState } from 'react'
import { ORG_ALL, SIZE_L, SIZE_M, SIZE_X } from '../config/CommonConfig'
import { STORAGE_SIZE, STORAGE_USERNAME } from '../config/StorageConfig'
import { getPermissions } from '../services/AuthenticateService'
// import { resetJwtToken } from '../services/AuthenticateService'
import { getStorage, setStorage } from '../services/StorageService'
import { callApi, resetWindow } from '../services/UtilService'
import { DOMAIN_API } from '../config/DomainConfig'

export default (props) => {
    const { title } = props
    const username = getStorage(STORAGE_USERNAME)
    const permissions = getPermissions()
    const validOrg = ORG_ALL
    const [actOrg] = useState(permissions.filter(org => validOrg.indexOf(org) > -1))
    const sizes = [SIZE_L, SIZE_M, SIZE_X]
    const _size = getStorage(STORAGE_SIZE)
    const [global, setGlobal] = useState({ size: _size ? _size : SIZE_L })
    // const onLogout = () => {
    //     resetJwtToken()
    //     window.location.href = '/login'
    // }

    const onUserPop = (e) => {
        // e.preventDefault()

        const userCtrl = document.getElementById("userCtrl")
        const activeCls = " active"

        if (userCtrl.className.indexOf(activeCls) === -1) {
            userCtrl.className = userCtrl.className + activeCls
        } else {
            userCtrl.className = userCtrl.className.replaceAll(activeCls, "")
        }
    }

    const onRefreshCacheClick = (org) => {
        callApi(DOMAIN_API("/api/admin/redis/clear/" + org), "Refresh cache " + org.toUpperCase())
    }

    const toggleSideMenu = (e) => {
        e.preventDefault()

        let bodyClassName = document.getElementsByTagName('body')[0].className
        if (bodyClassName.indexOf('sb-sidenav-toggled') === -1) {
            bodyClassName += ' sb-sidenav-toggled'
        } else {
            bodyClassName = bodyClassName.replaceAll(' sb-sidenav-toggled', '')
        }
        document.getElementsByTagName('body')[0].className = bodyClassName

        if (document.getElementById('layoutSidenav_content').className.indexOf('attached_event_fadeout') === -1) {
            document.getElementById('layoutSidenav_content').className += ' attached_event_fadeout'
            document.getElementById('layoutSidenav_content').addEventListener('click', function () {
                resetWindow()
            })
        }
    }

    const onSizeChange = (e, s) => {
        e.preventDefault()
        setGlobal({ ...global, size: s })
        setStorage(STORAGE_SIZE, s)
        
        document.getElementById("root").className = "body" + s
    }

    useEffect(() => {
        document.addEventListener("click", (e) => {
            const userCtrl = document.getElementById("userCtrl")
            const activeCls = " active"

            if (userCtrl) {
                userCtrl.className = userCtrl.className.replaceAll(activeCls, "")
            }
        }, true)
    }, [])

    return (<nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <a className="navbar-brand" href="#toggleSideMenu">Insight</a>
        <button className="btn btn-link btn-sm order-0" id="sidebarToggle" onClick={toggleSideMenu}><i className="fas fa-bars"></i></button>
        <a id="siteTitle" className="navbar-brand" href="#siteTitle">{title && title.length && title.filter(_title => _title).join(' / ')}</a>
        {/* href="#logout" onClick={onLogout} */}
        <div id="userCtrl" className="ml-auto mr-0 mr-md-3 my-2 my-md-0" onClick={onUserPop}>
            <i className="fa fa-user text-info"></i>
            <span className="ml-2 text-info">{username}</span>
            {actOrg && actOrg.length > 0 && <div className="popup-user sb-sidenav-dark p-3">
                <ul className="sb-sidenav-menu-nested nav">
                    <li className="sb-sidenav-menu">
                        <span className="mr-3">Size : </span>{sizes.map(s => (<a href="/#" className={"mnu-size mr-3 " + (global.size === s ? "active" : "")} onClick={(e) => { onSizeChange(e, s) }}>
                            <span>{s}</span>
                        </a>)
                        )}
                    </li>
                    {actOrg.map(org => (<li key={org} className="sb-sidenav-menu">
                        <a href="/#" className="nav-link" onClick={() => { onRefreshCacheClick(org) }}>
                            <i className="fa fa-trash mr-3"></i>
                            <span>Refresh Cache {org.toUpperCase()}</span>
                        </a>
                    </li>))}
                </ul>
            </div>}
        </div>
    </nav>)
}