import React from 'react'
export default (props) => {
    return (<React.Fragment>
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">{/*Copyright &copy; Your Website 2020*/}</div>
                    <div>
                        <a href="#PrivacyPolicy">{/*Privacy Policy*/}</a>
                                &middot;
                                <a href="#TermsConditions">{/*Terms &amp; Conditions*/}</a>
                    </div>
                </div>
            </div>
        </footer>
    </React.Fragment>)
}
