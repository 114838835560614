import React, { useEffect } from 'react'
import { callTeamTodayReport, extractTeamCampaign, calcHierarchyEle, buildHierarchy, sumHierarchy, calcHierarchy } from '../../../services/reports/TeamCampaignTodayReportService'
import { resetWindow } from '../../../services/UtilService'

import TeamTodayReportComponent from '../TeamTodayReport/TeamTodayReportComponent'

const TeamCampaignTodayReport = (props) => {

    useEffect(() => {
        resetWindow()
    }, [])

    return <React.Fragment>
        <TeamTodayReportComponent
            buildHierarchy={buildHierarchy}
            sumHierarchy={sumHierarchy}
            calcHierarchy={calcHierarchy}
            extractTeam={extractTeamCampaign}
            calcHierarchyEle={calcHierarchyEle}
            callTeamReport={callTeamTodayReport}>
        </TeamTodayReportComponent>
    </React.Fragment>
}

export default TeamCampaignTodayReport